import React, { useEffect, useState } from "react";
import Hoc from "../../component/Hoc";
import action from "../../action";
import { useDispatch, useSelector } from "react-redux";
import TermListMobile from "./Hoc/TermListMobile";

const InpublishTerms = () => {
  const dispatch = useDispatch();
  const act_term = action.term;
  const root = useSelector(({ user: R }) => R.login.data);
  const { data, error, loading } = useSelector(({ term: R }) => R._search);
  const [searchTxt, setSearchTxt] = useState("");

  const CKR = (v) => v === root?.role;
  const isAdmin = !!(
    CKR("Manager") || !!(CKR("Admin") && !!root?.admin_status)
  );

  const para = !isAdmin ? `&UserId=${root?.id}` : "";

  useEffect(() => {
    dispatch(act_term._search(searchTxt, false, para));
  }, [dispatch, act_term, searchTxt, para]);

  const refresh = () => dispatch(act_term._search(searchTxt, false, para));

  const actions = [
    { name: "Create New Term", to: "/add-term" },
    { name: "My Reports", to: "/created-report-items" },
    { name: "Back", goBack: true },
  ];

  const nav_01 = {
    title: "Unpublished Terms",
    actions,
  };
  const mobProps = {
    searchTxt,
    setSearchTxt,
    refresh,
    data,
  };

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Hoc.UseData error={error} loading={loading}>
        <TermListMobile {...mobProps} notActive />
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

export default InpublishTerms;
