import React from "react";
import { InputLabel } from "@material-ui/core";
import Button from "./Button";
import action from "../../../action";
import Resizer from "react-image-file-resizer";
import { useDispatch } from "react-redux";

const Input = (p01) => {
  const dispatch = useDispatch();
  const { id: fid, name, label, accept, ...props } = p01;
  const { color, onChange, ...rest } = props;

  const id = fid || "file_id";
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  const onFileChange = async ({ target: v }) => {
    try {
      const file = v.files[0];
      if (`${file.type}`.startsWith("image")) {
        const value = await resizeFile(file);
        return onChange({ target: { name, value } });
      } else {
        // 1024 * 1024Bytes = 1048576Bytes = 1MB
        const _1MB = 1024 * 1024;
        const ckTP = (v) => `${file?.type}`.includes(v);
        const sz01 = parseFloat(file.size / _1MB).toFixed(2);
        const fsMsg = (sz) =>
          `Your selected file size (${sz01}MB) is more than ${sz}MB. Please select medium size file.`;

        if (ckTP("audio") || ckTP("video")) {
          if (file.size > 4 * _1MB && ckTP("audio"))
            return dispatch(action.OOP(fsMsg(4), true));
          else if (file.size > 15 * _1MB && ckTP("video"))
            return dispatch(action.OOP(fsMsg(15), true));
          else return onChange({ target: { name, value: file } });
        } else {
          dispatch(action.OOP("Please Select a valid file.", true));
        }
      }
    } catch (err) {
      console.log(`${err}`);
    }
  };

  const smi = { type: "file", accept, id, hidden: true, name };

  return (
    <>
      <Button style={{ padding: "10px 15px" }}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <input {...smi} onChange={onFileChange} {...rest} />
      </Button>
    </>
  );
};

export default Input;
