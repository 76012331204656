// importing packages
import axios from "axios";
import Helper from "../helper";
import { Type } from "../../redux";
const types = Type.user_types;

// creating common URL for all request in the file
const URL = `${Helper.URL}/user`;

// creating an array to find user login page link
const usrs = [
  { role: "User", url: "" },
  { role: "Subscriber", url: "subscriber-" },
  { role: "Guest Editor", url: "guest-" },
  { role: "Admin", url: "admin-" },
  { role: "Manager", url: "supreme-" },
];

// initializing a function which sends a request to the server.
// initData - body for the request
// dispatch - functions which executes the redux reducers
// state - redux state which contains entire redux state data
export const add_subscription = (initData) => async (dispatch, state) => {
  try {
    // Informing to the redux that we are sending request to the server
    dispatch({ type: types.ADD_SUBSCRIPTION_REQUEST });
    // setting uset auth token to the axios headers
    Helper.setJwtFromState(state());
    // Sends request to the server and extracts the data
    const { data } = await axios.post(`${URL}/subscribe`, initData);
    // telling to redux that the request status is success and passing response data to the redux state
    dispatch({ type: types.ADD_SUBSCRIPTION_SUCCESS, payload: data?.result });
  } catch (err) {
    // Passing error to the errResponse function which extracts the error and shows as the message
    Helper.errResponse(err, dispatch, types.ADD_SUBSCRIPTION_FAIL);
  }
};

export const logout = () => (dispatch, state) => {
  try {
    const role = state()?.user?.login?.data?.role;
    const url = usrs?.find((v) => v?.role === role)?.url;
    sessionStorage.removeItem("root");

    dispatch({ type: types.USER_LOGOUT_REQUEST });
    dispatch({ type: types.USER_LOGIN_REQUEST });
    dispatch({ type: types.USER_LOGIN_SUCCESS, payload: null });
    dispatch({ type: types.USER_LOGOUT_SUCCESS, payload: null });
    // returning the login url
    return `/${url}login`;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.USER_LOGOUT_FAIL);
  }
};

export const login = (initData) => async (dispatch) => {
  try {
    dispatch({ type: types.USER_LOGIN_REQUEST });

    const { data } = await axios.post(`${URL}/user`, initData);

    dispatch({ type: types.USER_LOGIN_SUCCESS, payload: data?.result });

    if (!!data.status && data?.result) {
      const CKD = (DT) => (!DT ? new Date().getTime() : new Date(DT).getTime());
      const subscription = CKD() < CKD(data?.result?.subscription_date);
      const finalData = { ...data?.result, subscription };

      sessionStorage.removeItem("root");
      sessionStorage.setItem("root", JSON.stringify(finalData));
    }

    return data.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.USER_LOGIN_FAIL);
  }
};

export const check_jwt = (role) => async (dispatch) => {
  try {
    dispatch({ type: types.USER_CHECK_JWT_REQUEST });
    Helper.setJwt(sessionStorage.getItem("token"));
    const { data } = await axios.get(`${URL}/session?${role}`);
    dispatch({ type: types.USER_CHECK_JWT_SUCCESS, payload: data?.result });

    !data?.status && sessionStorage.removeItem("token");

    return data;
  } catch (err) {
    sessionStorage.removeItem("token");
    Helper.errResponse(err, dispatch, types.USER_CHECK_JWT_FAIL);
  }
};

export const register = (initData) => async (dispatch) => {
  try {
    dispatch({ type: types.USER_REGISTER_REQUEST });
    const { data } = await axios.post(URL, initData);
    dispatch({ type: types.USER_REGISTER_SUCCESS, payload: data?.result });

    return data.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.USER_REGISTER_FAIL);
  }
};

const _list = async (dispatch, qri = "") => {
  try {
    dispatch({ type: types.USER_LIST_REQUEST });
    const { data } = await axios.get(`${URL}/root?${qri}`);
    dispatch({ type: types.USER_LIST_SUCCESS, payload: data?.result });

    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.USER_LIST_FAIL);
  }
};

export const list = (qri) => async (dispatch) => await _list(dispatch, qri);

export const verify_email = (token) => async (dispatch) => {
  try {
    dispatch({ type: types.USER_VERIFY_MAIL_REQUEST });
    const { data } = await axios.put(URL, { token });
    dispatch({ type: types.USER_VERIFY_MAIL_SUCCESS, payload: data?.result });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.USER_VERIFY_MAIL_FAIL);
  }
};

export const view_all =
  (qri = "") =>
  async (dispatch) =>
    await _list(dispatch, qri);

export const forgot_password = (initData) => async (dispatch) => {
  try {
    dispatch({ type: types.FORGOT_PSW_REQUEST });
    const { data } = await axios.post(`${URL}/password`, initData);
    dispatch({ type: types.FORGOT_PSW_SUCCESS, payload: data?.result });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.FORGOT_PSW_FAIL);
  }
};

export const reset_password = (initData) => async (dispatch) => {
  try {
    dispatch({ type: types.RESET_PSW_REQUEST });
    const { data } = await axios.put(`${URL}/password`, initData);
    dispatch({ type: types.RESET_PSW_SUCCESS, payload: data?.result });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.RESET_PSW_FAIL);
  }
};

export const edit_profile_psw = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.EDIT_PROFILE_PSW_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.put(`${URL}/profile`, initData);
    dispatch({ type: types.EDIT_PROFILE_PSW_SUCCESS, payload: data?.result });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.EDIT_PROFILE_PSW_FAIL);
  }
};

export const edit_profile = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.EDIT_PROFILE_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.post(`${URL}/profile`, initData);
    dispatch({ type: types.EDIT_PROFILE_SUCCESS, payload: data?.result });
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.EDIT_PROFILE_FAIL);
  }
};

export const view_profile = () => async (dispatch, state) => {
  try {
    dispatch({ type: types.VIEW_PROFILE_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.get(`${URL}/profile`);
    dispatch({ type: types.VIEW_PROFILE_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.VIEW_PROFILE_FAIL);
  }
};

export const edit_user = (id, initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.USER_EDIT_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.put(`${URL}/root/${id}`, initData);
    dispatch({ type: types.USER_EDIT_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.USER_EDIT_FAIL);
  }
};
