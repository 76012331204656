import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Review from "./Review";
import Report from "./Report";
import { useSelector } from "react-redux";

function TabPanel(props) {
  const {
    children,
    value,
    index,

    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const CommentsAndReviewTab = ({
  TranslationId = null,
  TermId = null,
  UserId = null,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const root = useSelector(({ user: P }) => P.login.data);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="mt-3">
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Review" {...a11yProps(0)} />
            <Tab label="Report Inappropriate Content" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <Review root={root} TranslationId={TranslationId} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Report
            root={root}
            UserId={UserId}
            TermId={TermId}
            TranslationId={TranslationId}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default CommentsAndReviewTab;
