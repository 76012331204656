import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, AppBar, Toolbar, IconButton } from "@material-ui/core";
import Hoc from "../Hoc";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import action from "../../action";

export default function ButtonAppBar({ children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const H = useHistory();
  const css = useCSS();
  const root = useSelector(({ user: U }) => U.login.data);

  const [openToggle, setOpenToggle] = useState(false);

  const act_logout = action.user.logout;

  const onLogout = () => {
    const url = dispatch(act_logout());
    if (!!url) H.push(url);
    setOpenToggle(!openToggle);
  };

  const onPush = (to) => {
    H.push(to);
    setOpenToggle(false);
  };

  const LinkBtn = ({ content, to, onClick }) => (
    <Box
      className={css.navLink}
      {...(!to ? { onClick } : { onClick: () => onPush(to) })}
    >
      {content}
    </Box>
  );

  return (
    <div className={css.root}>
      <AppBar position="static" color="white">
        <Toolbar>
          <Box className={css.navRoot}>
            <Box
              className={css.logo}
              onClick={() => onPush("/glossary")}
              component="img"
              src="/logo.png"
            />
            <Box className={!openToggle ? css.navClose : css.navOpen}>
              <a className={css.navLink} {...donProps}>
                Donation
              </a>
              {!root?.token ? (
                <LinkBtn content="Sign In" to="/login" />
              ) : (
                <>
                  {root?.role !== "User" && (
                    <LinkBtn content="Dashboard" to="/dashboard" />
                  )}
                  {!!(root.role !== "Admin" || !!root?.admin_status) && (
                    <LinkBtn
                      content="Unpublished Terms"
                      to="/unpublish-terms"
                    />
                  )}
                  <LinkBtn content="Profile" to="/profile-view" />
                  <LinkBtn onClick={onLogout} content="Sign Out" />
                </>
              )}
            </Box>
            {isMobile && (
              <IconButton
                style={{ zIndex: 101 }}
                onClick={() => setOpenToggle(!openToggle)}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {children}
    </div>
  );
}

const donProps = {
  target: "_blank",
  rel: "noreferrer",
  href: "https://www.paypal.com/donate/?hosted_button_id=3MP6JGA34VSLQ",
};

const useNav = (theme, rest) => ({
  zIndex: 100,
  ...Hoc.flex("space-evenly"),
  color: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    position: "fixed",
    opacity: 0.95,
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    background: theme.palette.primary.main,
    flexDirection: "column",
    height: "100vh",
    width: "100%",
    padding: theme.spacing(10, 2),
    color: theme.palette.common.white,
    justifyContent: "space-evenly",
    transition: "all 1s ease-out",
    ...rest,
  },
  [theme.breakpoints.up("sm")]: {
    width: "60%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "40%",
  },
  marginRight: theme.spacing(2),
});

const useCSS = makeStyles((theme) => ({
  root: { position: "relative" },
  appbar: { color: theme.palette.common.white },
  navRoot: {
    ...Hoc.flex("space-between"),
    width: "100%",
    padding: theme.spacing(0.5, 1),
  },
  navLink: {
    color: "currentColor",
    cursor: "pointer",
    padding: theme.spacing(0.3, 1),
    borderRadius: 5,
    "&:hover": {
      color: "currentColor",
      backgroundColor: theme.palette.secondary.light,
    },
  },
  navOpen: useNav(theme, {
    clipPath: "circle(1500px at -20% 90%)",
    WebkitClipPath: "circle(1500px at -20% 90%)",
    pointerEvents: "all",
  }),
  navClose: useNav(theme, {
    clipPath: "circle(0px at -20% 90%)",
    WebkitClipPath: "circle(0px at -20% 90%)",
    pointerEvents: "none",
  }),

  logo: {
    height: theme.spacing(8),
    zIndex: 101,
    cursor: "pointer",
  },
}));
