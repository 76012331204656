import React from "react";
import { Paper, Box, ButtonBase, Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const Page = (props) => {
  const { children, title, actions } = props;
  const { maxWidth = 1290, minWidth = 300, smSize } = props;
  const CSS = useCSS();
  const H = useHistory();

  return (
    <Paper className={CSS.root} style={{ minWidth, maxWidth }}>
      {title && (
        <Paper className={smSize ? CSS.paper_sm : CSS.paper}>
          <Typography variant="h6">{title}</Typography>
          {actions && Array.isArray(actions) && !!actions.length && (
            <Box>
              {actions.map(({ name, onClick, to, goBack, noBorder }) => (
                <React.Fragment key={name}>
                  {!!noBorder ? (
                    name
                  ) : (
                    <ButtonBase
                      component={Button}
                      {...(!noBorder && { variant: "outlined" })}
                      color="inherit"
                      size="small"
                      onClick={
                        to
                          ? () => H.push(to)
                          : goBack
                          ? () => H.goBack()
                          : onClick
                      }
                    >
                      <span className={CSS.link_name}>{name}</span>
                    </ButtonBase>
                  )}
                </React.Fragment>
              ))}
            </Box>
          )}
        </Paper>
      )}
      {children}
    </Paper>
  );
};

const paper_common = (theme) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,

  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& .MuiSvgIcon-root": {
    marginRight: theme.spacing(2),
  },
  "& .MuiButton-root": {
    marginRight: theme.spacing(1),
    fontWeight: 300,
  },
});

const useCSS = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: theme.spacing(8, "auto"),
  },
  link_name: {
    textTransform: "None",
  },
  paper_sm: {
    ...paper_common(theme),
    padding: theme.spacing(0.5, 1),
    marginTop: theme.spacing(-5),
    marginBottom: theme.spacing(4),
  },
  paper: {
    ...paper_common(theme),
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(-6),
    marginBottom: theme.spacing(4),
  },
}));

export default Page;
