import SecureRoute from "../SecureRoute";
import { AddPlan, EditPlan } from "./PlanForm";
import list from "./list";

const Expo = {
  add: <SecureRoute grant={["Admin"]} path="/plan-add" component={AddPlan} />,
  edit: (
    <SecureRoute grant={["Admin"]} path="/plan-edit/:id" component={EditPlan} />
  ),
  list: <SecureRoute grant={["EmpSub"]} path="/plan-list" component={list} />,
};

export default Expo;
