import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Index = ({ children }) => {
  return (
    <Header>
      <main style={{ minHeight: "70vh" }}>{children}</main>
      <Footer />
    </Header>
  );
};

export default Index;
