const types = {
  //
  FOOTER_LIST_REQUEST: "FOOTER_LIST_REQUEST",
  FOOTER_LIST_SUCCESS: "FOOTER_LIST_SUCCESS",
  FOOTER_LIST_FAIL: "FOOTER_LIST_FAIL",
  //
  FOOTER_VIEW_REQUEST: "FOOTER_VIEW_REQUEST",
  FOOTER_VIEW_SUCCESS: "FOOTER_VIEW_SUCCESS",
  FOOTER_VIEW_FAIL: "FOOTER_VIEW_FAIL",
  //
  FOOTER_ADD_REQUEST: "FOOTER_ADD_REQUEST",
  FOOTER_ADD_SUCCESS: "FOOTER_ADD_SUCCESS",
  FOOTER_ADD_FAIL: "FOOTER_ADD_FAIL",
  //
  FOOTER_EDIT_REQUEST: "FOOTER_EDIT_REQUEST",
  FOOTER_EDIT_SUCCESS: "FOOTER_EDIT_SUCCESS",
  FOOTER_EDIT_FAIL: "FOOTER_EDIT_FAIL",
  //
  FOOTER_DELETE_REQUEST: "FOOTER_DELETE_REQUEST",
  FOOTER_DELETE_SUCCESS: "FOOTER_DELETE_SUCCESS",
  FOOTER_DELETE_FAIL: "FOOTER_DELETE_FAIL",
  //
  EMST_VIEW_REQUEST: "EMST_VIEW_REQUEST",
  EMST_VIEW_SUCCESS: "EMST_VIEW_SUCCESS",
  EMST_VIEW_FAIL: "EMST_VIEW_FAIL",
};

export default types;
