import React, { useEffect } from "react";
import Hoc from "../../component/Hoc";
import ControlsInput from "../../component/Hoc/Controls/Input";
import Custom from "../../component/Custom";
import action from "../../action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Box } from "@material-ui/core";

const UnPublished = () => {
  const dispatch = useDispatch();

  const reviews = useSelector(({ review: P }) => P.view_all.data);

  useEffect(() => {
    dispatch(action.review.view_all(para));
    // eslint-disable-next-line
  }, [dispatch]);

  const nav_01 = {
    title: `Unpublished Reviews`.toUpperCase(),
    actions: [{ name: "Back", goBack: true }],
  };
  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <ReviewTable data={reviews} />
    </Hoc.Dashboard.Container>
  );
};
const para = "enabled=false";

const ReviewTable = ({ data }) => {
  const H = useHistory();
  const dispatch = useDispatch();

  const [filterFn, setFilterFn] = React.useState(Hoc.onSearch.action);
  const onSearch = ({ target: T }) =>
    Hoc.onSearch.search(T?.value, setFilterFn);

  const TBL = Custom.useTable(data, reviewCells, filterFn);
  const { TblContainer, finalRecords, TableRow, TableCell } = TBL;

  const onPublish = (id) => {
    if (window.confirm("Are you sure, You want to Update it.") === true) {
      dispatch(action.review.view_edit(id, { enabled: true }, para));
      dispatch(action.OOP("Review Published Successfully."));
    }
  };

  const withUrl = (id, text) => (
    <span onClick={() => H.push(`/report-list/${id}`)}>{text}</span>
  );

  return (
    <>
      <Box style={{ width: "100%", margin: "20px 0" }} {...Hoc.flex()}>
        <Box style={{ width: 200 }}>
          <ControlsInput label="Search" size="small" onChange={onSearch} />
        </Box>
      </Box>

      {!finalRecords().length && <Hoc.EmptyResource />}
      {!!finalRecords().length && (
        <TblContainer paginate>
          {finalRecords().map((v) => (
            <TableRow key={v.id}>
              <TableCell content={withUrl(v?.id, v?.text)} />
              <TableCell content={withUrl(v?.id, v?.url)} />
              <TableCell content={withUrl(v?.id, v?.rating)} />
              <TableCell
                content={<PublishBtn onClick={() => onPublish(v?.id)} />}
              />
            </TableRow>
          ))}
        </TblContainer>
      )}
    </>
  );
};

const TOC = (id, label, disableSorting = false) => ({
  id,
  label,
  disableSorting,
});

const reviewCells = [
  TOC("text", "Text"),
  TOC("url", "Link"),
  TOC("rating", "Rating"),
  TOC("07", "Actions", true),
];

const PublishBtn = ({ ...rest }) => (
  <Button variant="contained" size="small" color="primary" {...rest}>
    Publish
  </Button>
);

export default UnPublished;
