import React from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@material-ui/core";

const Checkbox = (props) => {
  const { name, label, value, color, onChange } = props;

  const toDefEventPara = (name, value) => ({ target: { name, value } });

  return (
    <FormControl>
      <FormControlLabel
        label={label}
        control={
          <MuiCheckbox
            name={name}
            color={color || "primary"}
            checked={value}
            onChange={({ target: { checked } }) =>
              onChange(toDefEventPara(name, checked))
            }
          />
        }
      />
    </FormControl>
  );
};

export default Checkbox;
