import React from "react";
import PageBox from "./PageBox";
import { Box, Grid } from "@material-ui/core";

const Container = (props) => {
  const { children, ...rest } = props;
  return (
    <Box>
      <PageBox {...rest}>
        <Grid container spacing={2} justifyContent="center">
          {children}
        </Grid>
      </PageBox>
    </Box>
  );
};

const Item = ({ children, title, ...rest }) => (
  <Grid item component={PageBox} maxWidth={350} title={title} smSize {...rest}>
    {children}
  </Grid>
);

const Expo = {
  Container,
  Item,
};

export default Expo;
