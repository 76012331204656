import types from "./types";

const defState = {
  loading: false,
  data: null,
  error: null,
};

const defListState = {
  loading: false,
  data: [],
  error: null,
};

const defLogin = {
  loading: false,
  data: sessionStorage.getItem("root")
    ? JSON.parse(sessionStorage.getItem("root"))
    : null,
  error: null,
};

export const list = {
  name: "list",
  state: defListState,
  request: types.USER_LIST_REQUEST,
  success: types.USER_LIST_SUCCESS,
  fail: types.USER_LIST_FAIL,
};

export const login = {
  name: "login",
  state: defLogin,
  request: types.USER_LOGIN_REQUEST,
  success: types.USER_LOGIN_SUCCESS,
  fail: types.USER_LOGIN_FAIL,
};

export const logout = {
  name: "logout",
  state: defState,
  request: types.USER_LOGOUT_REQUEST,
  success: types.USER_LOGOUT_SUCCESS,
  fail: types.USER_LOGOUT_FAIL,
};

export const register = {
  name: "register",
  state: defState,
  request: types.USER_REGISTER_REQUEST,
  success: types.USER_REGISTER_SUCCESS,
  fail: types.USER_REGISTER_FAIL,
};

export const verify_email = {
  name: "verify_email",
  state: defState,
  request: types.USER_VERIFY_MAIL_REQUEST,
  success: types.USER_VERIFY_MAIL_SUCCESS,
  fail: types.USER_VERIFY_MAIL_FAIL,
};

export const check_jwt = {
  name: "check_jwt",
  state: defState,
  request: types.USER_CHECK_JWT_REQUEST,
  success: types.USER_CHECK_JWT_SUCCESS,
  fail: types.USER_CHECK_JWT_FAIL,
};

export const add_subscription = {
  name: "add_subscription",
  state: defState,
  request: types.ADD_SUBSCRIPTION_REQUEST,
  success: types.ADD_SUBSCRIPTION_SUCCESS,
  fail: types.ADD_SUBSCRIPTION_FAIL,
};

export const forgot_password = {
  name: "forgot_password",
  state: defState,
  request: types.FORGOT_PSW_REQUEST,
  success: types.FORGOT_PSW_SUCCESS,
  fail: types.FORGOT_PSW_FAIL,
};

export const reset_password = {
  name: "reset_password",
  state: defState,
  request: types.RESET_PSW_REQUEST,
  success: types.RESET_PSW_SUCCESS,
  fail: types.RESET_PSW_FAIL,
};

export const edit_profile = {
  name: "edit_profile",
  state: defState,
  request: types.EDIT_PROFILE_REQUEST,
  success: types.EDIT_PROFILE_SUCCESS,
  fail: types.EDIT_PROFILE_FAIL,
};

export const edit_profile_psw = {
  name: "edit_profile_psw",
  state: defState,
  request: types.EDIT_PROFILE_PSW_REQUEST,
  success: types.EDIT_PROFILE_PSW_SUCCESS,
  fail: types.EDIT_PROFILE_PSW_FAIL,
};

export const view_profile = {
  name: "view_profile",
  state: defState,
  request: types.VIEW_PROFILE_REQUEST,
  success: types.VIEW_PROFILE_SUCCESS,
  fail: types.VIEW_PROFILE_FAIL,
};

export const edit_user = {
  name: "edit_user",
  state: defState,
  request: types.USER_EDIT_REQUEST,
  success: types.USER_EDIT_SUCCESS,
  fail: types.USER_EDIT_FAIL,
};
