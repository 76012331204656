import { Route, Redirect } from "react-router-dom";
import SecureRoute from "../SecureRoute";
import TermList from "./list";
import TermView from "./view";
import review_reports from "./review_reports";
import unpublish_terms from "./unpublish_terms";

import { AddTerm, EditTerm } from "./TermForm";
import { AddTrans, EditTrans } from "./TransForm.jsx";

const Expo = {
  unpublish_terms: (
    <SecureRoute
      grant={["User"]}
      path="/unpublish-terms"
      component={unpublish_terms}
    />
  ),
  review_reports: (
    <SecureRoute
      grant={["User"]}
      path="/report-list/:id"
      component={review_reports}
    />
  ),
  home: (
    <Route exact path="/">
      <Redirect to="/glossary" />
    </Route>
  ),
  view_list: <Route exact path="/glossary" component={TermList} />,
  view: (
    <SecureRoute
      grant={["User"]}
      exact
      path="/term-view/:id"
      component={TermView}
    />
  ),
  add: <SecureRoute grant={["User"]} path="/add-term" component={AddTerm} />,
  edit: (
    <SecureRoute grant={["User"]} path="/edit-term/:id" component={EditTerm} />
  ),
  add_trans: (
    <SecureRoute
      grant={["User"]}
      exact
      path="/add-translation/:TermId"
      component={AddTrans}
    />
  ),
  edit_trans: (
    <SecureRoute
      grant={["User"]}
      exact
      path="/edit-translation/:id"
      component={EditTrans}
    />
  ),
};

export default Expo;
