import { Route } from "react-router-dom";
// import HomePage from "./home-page";
import NotFound from "./NotFound";
import ReviewEdit from "./ReviewEdit";
import SecureRoute from "../SecureRoute";
import { AddReport, EditReport } from "./ReportForm";

const Expo = {
  // home_page: <Route path="/" component={HomePage} />,
  not_found_404: <Route path="/*" component={NotFound} />,
  review_edit: (
    <SecureRoute
      grant={["User"]}
      path="/review-edit/:id"
      component={ReviewEdit}
    />
  ),
  report_add: (
    <SecureRoute grant={["User"]} path="/report-add" component={AddReport} />
  ),
  report_edit: (
    <SecureRoute
      grant={["User"]}
      path="/report-edit/:id"
      component={EditReport}
    />
  ),
};

export default Expo;
