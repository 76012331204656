const types = {
  //
  TERM_SEARCH_REQUEST: "TERM_SEARCH_REQUEST",
  TERM_SEARCH_SUCCESS: "TERM_SEARCH_SUCCESS",
  TERM_SEARCH_FAIL: "TERM_SEARCH_FAIL",
  //
  TERM_LIST_REQUEST: "TERM_LIST_REQUEST",
  TERM_LIST_SUCCESS: "TERM_LIST_SUCCESS",
  TERM_LIST_FAIL: "TERM_LIST_FAIL",
  //
  TERM_VIEW_REQUEST: "TERM_VIEW_REQUEST",
  TERM_VIEW_SUCCESS: "TERM_VIEW_SUCCESS",
  TERM_VIEW_FAIL: "TERM_VIEW_FAIL",
  //
  TERM_ADD_REQUEST: "TERM_ADD_REQUEST",
  TERM_ADD_SUCCESS: "TERM_ADD_SUCCESS",
  TERM_ADD_FAIL: "TERM_ADD_FAIL",
  //
  TERM_EDIT_REQUEST: "TERM_EDIT_REQUEST",
  TERM_EDIT_SUCCESS: "TERM_EDIT_SUCCESS",
  TERM_EDIT_FAIL: "TERM_EDIT_FAIL",
  //
  TERM_DELETE_REQUEST: "TERM_DELETE_REQUEST",
  TERM_DELETE_SUCCESS: "TERM_DELETE_SUCCESS",
  TERM_DELETE_FAIL: "TERM_DELETE_FAIL",
  //
  MEDIA_DELETE_REQUEST: "MEDIA_DELETE_REQUEST",
  MEDIA_DELETE_SUCCESS: "MEDIA_DELETE_SUCCESS",
  MEDIA_DELETE_FAIL: "MEDIA_DELETE_FAIL",
};

export default types;
