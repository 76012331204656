import list_view from "./list-view";
import { AddLink, EditLink } from "./add-link";
import SecureRoute from "../SecureRoute";

const Expo = {
  list_view: (
    <SecureRoute
      grant={["Manager"]}
      exact
      path="/footer-link-list"
      component={list_view}
    />
  ),
  add_link: (
    <SecureRoute
      grant={["Manager"]}
      exact
      path="/add-footer-link"
      component={AddLink}
    />
  ),
  edit_link: (
    <SecureRoute
      grant={["Manager"]}
      exact
      path="/edit-footer-link/:id"
      component={EditLink}
    />
  ),
};

export default Expo;
