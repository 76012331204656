const types = {
  //
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "USER_LOGIN_FAIL",
  //
  USER_LOGOUT_REQUEST: "USER_LOGOUT_REQUEST",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAIL: "USER_LOGOUT_FAIL",
  //
  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAIL: "USER_REGISTER_FAIL",
  //
  USER_EDIT_REQUEST: "USER_EDIT_REQUEST",
  USER_EDIT_SUCCESS: "USER_EDIT_SUCCESS",
  USER_EDIT_FAIL: "USER_EDIT_FAIL",
  //
  USER_VERIFY_MAIL_REQUEST: "USER_VERIFY_MAIL_REQUEST",
  USER_VERIFY_MAIL_SUCCESS: "USER_VERIFY_MAIL_SUCCESS",
  USER_VERIFY_MAIL_FAIL: "USER_VERIFY_MAIL_FAIL",
  //
  USER_CHECK_JWT_REQUEST: "USER_CHECK_JWT_REQUEST",
  USER_CHECK_JWT_SUCCESS: "USER_CHECK_JWT_SUCCESS",
  USER_CHECK_JWT_FAIL: "USER_CHECK_JWT_FAIL",
  //
  USER_LIST_REQUEST: "USER_LIST_REQUEST",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_FAIL: "USER_LIST_FAIL",
  //
  ADD_SUBSCRIPTION_REQUEST: "ADD_SUBSCRIPTION_REQUEST",
  ADD_SUBSCRIPTION_SUCCESS: "ADD_SUBSCRIPTION_SUCCESS",
  ADD_SUBSCRIPTION_FAIL: "ADD_SUBSCRIPTION_FAIL",
  //
  FORGOT_PSW_REQUEST: "FORGOT_PSW_REQUEST",
  FORGOT_PSW_SUCCESS: "FORGOT_PSW_SUCCESS",
  FORGOT_PSW_FAIL: "FORGOT_PSW_FAIL",
  //
  RESET_PSW_REQUEST: "RESET_PSW_REQUEST",
  RESET_PSW_SUCCESS: "RESET_PSW_SUCCESS",
  RESET_PSW_FAIL: "RESET_PSW_FAIL",
  //
  EDIT_PROFILE_REQUEST: "EDIT_PROFILE_REQUEST",
  EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
  EDIT_PROFILE_FAIL: "EDIT_PROFILE_FAIL",
  //
  EDIT_PROFILE_PSW_REQUEST: "EDIT_PROFILE_PSW_REQUEST",
  EDIT_PROFILE_PSW_SUCCESS: "EDIT_PROFILE_PSW_SUCCESS",
  EDIT_PROFILE_PSW_FAIL: "EDIT_PROFILE_PSW_FAIL",
  //
  VIEW_PROFILE_REQUEST: "VIEW_PROFILE_REQUEST",
  VIEW_PROFILE_SUCCESS: "VIEW_PROFILE_SUCCESS",
  VIEW_PROFILE_FAIL: "VIEW_PROFILE_FAIL",
};

export default types;
