import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BaseTerm from "./BaseTerm";

import Hoc from "../../../component/Hoc";
import { CKRTL } from "../../../assets/layouts";
import { HorizantalTab, VerticalTab } from "./TabItem";
import { Box, unstable_createMuiStrictModeTheme } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import queryStr from "query-string";
import { useLocation } from "react-router-dom";

const TransList = ({ term, refresh }) => {
  const css = useStyles();
  const [value, setValue] = React.useState(0);
  const theme = unstable_createMuiStrictModeTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const qri = queryStr.parse(useLocation().search);
  const QriTran = qri?.TranslationId;

  const handleChange = (e, vl) => setValue(vl);

  useEffect(() => {
    if (QriTran && !!term?.Translations) {
      for (let Idx = 0; Idx < Hoc.isArr(term?.Translations)?.length; Idx++) {
        if (term?.Translations[Idx]?.id === parseInt(QriTran))
          setValue(Idx + 1);
      }
    }
    // eslint-disable-next-line
  }, [QriTran]);

  const labels = ((T) => ({
    word: !T?.word
      ? null
      : CKRTL(T?.tongue)
      ? `${T?.word} (${T?.tongue})`
      : `(${T?.tongue}) ${T?.word}`,
    items: Hoc.isArr(T?.Translations)?.map((v) =>
      !v?.word
        ? null
        : CKRTL(v?.tongue)
        ? `${v?.word} (${v?.tongue})`
        : `(${v?.tongue}) ${v?.word}`
    ),
  }))(term);

  const tabPara = { labels, value, handleChange };

  return (
    <div style={{ marginBottom: 40 }}>
      <div className={!!isMobile ? css.rootOnH : css.rootOnV}>
        {!!isMobile ? (
          <HorizantalTab {...tabPara} />
        ) : (
          <VerticalTab {...tabPara} />
        )}

        {!!term?.word && (
          <TabPanel value={value} index={0}>
            <BaseTerm refresh={refresh} term={term} />
          </TabPanel>
        )}

        {Hoc.isArr(term?.Translations).map((T, idx) => (
          <TabPanel key={`${idx}-${T?.word}`} value={value} index={idx + 1}>
            <BaseTerm refresh={refresh} term={T} />
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  rootOnV: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
  },
  rootOnH: {
    flexGrow: 1,
    width: "98%",
    backgroundColor: theme.palette.background.paper,
    margin: "0 10px",
  },
}));

export default TransList;
