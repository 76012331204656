import React, { useState } from "react";
import action from "../../../action";
import Hoc from "../../../component/Hoc";
import ControlsInput from "../../../component/Hoc/Controls/Input";
import { Box, Card, Grid, Button } from "@material-ui/core";
import { CardActionArea, CardMedia } from "@material-ui/core";
import { CardActions, CardContent } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/EditRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import queryStr from "query-string";

import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CKRTL } from "../../../assets/layouts";

const TermListMobile = (props) => {
  const { searchTxt, setSearchTxt, data: DT, notActive, refresh } = props;

  const [showMedia, setShowMedia] = useState([]);
  const act_term = action.term;
  const H = useHistory();
  const dispatch = useDispatch();
  const css = useCSS();
  const { loading, error } = useSelector(({ term: T }) => T.view_all);
  const root = useSelector(({ user: T }) => T.login.data);

  const invalidAdmin = root?.role !== "Admin" || !!root?.admin_status;

  const isAdmin = ["Admin", "Manager"].includes(root?.role);

  const canEdit = (UserId) => (isAdmin ? true : root?.id === UserId);

  let data = DT;
  let lenUnp = Hoc.isArr(data)?.length;
  const PIL = parseInt(lenUnp / 4);

  lenUnp = PIL * 4 < lenUnp ? PIL + 1 : PIL;

  const pageNum =
    Number(queryStr.parse(useLocation().search)?.page || 0) * 4 || 0;

  data = data.slice(pageNum, pageNum + 4);

  const toPage = (page) =>
    H.push({ search: `${!page ? "" : "?page="}${page - 1 || ""}` });

  const actions = [];

  if (!!root?.token && invalidAdmin)
    actions.push({ name: "Create New Term", to: "/add-term" });

  const onEnable = (id, enabled) => {
    let view = new FormData();
    view.set("enabled", enabled);
    if (window.confirm("Are you sure, You want to Update it.") === true) {
      dispatch(act_term.view_edit(id, view, `enabled=${!notActive}`));

      dispatch(action.OOP("Successfully Enabled the Term"));
      refresh();
    }
  };

  const onSearch = ({ target: T }) => setSearchTxt(T.value);
  const onDelete = (id) => {
    if (window.confirm("Are you sure, You want to delete the Item.") === true) {
      dispatch(act_term.view_delete(id, `enabled=${!notActive}`));
      refresh();
    }
  };

  return (
    <Hoc.UseData loading={loading} error={error}>
      <Box width="100%">
        {!notActive && <Hoc.ActionButtons actions={actions} />}
        <Box {...Hoc.flex()} width="100%" style={{ margin: "20px 0" }}>
          <Box maxWidth={200}>
            <ControlsInput
              size="small"
              value={searchTxt}
              label="search"
              onChange={onSearch}
            />
          </Box>
        </Box>

        {!data?.length && (
          <Hoc.Typo
            content="Empty items found here."
            align="center"
            variant="h3"
            color="textSecondary"
          />
        )}

        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {data?.map((T) => (
            <Grid item key={T.id} xs={12} sm={6}>
              <Card className={css.card}>
                <CardActionArea>
                  {T?.photo && (
                    <CardMedia
                      onClick={() =>
                        !!(root?.token && invalidAdmin)
                          ? H.push(`/term-view/${T?.id}`)
                          : H.push(`/login`)
                      }
                      image={T.photo}
                      className={css.cardImg}
                      title={T.word}
                    />
                  )}
                  <CardContent>
                    <Hoc.Typo
                      align="center"
                      variant="h5"
                      color="primary"
                      content={T.word}
                      onClick={() =>
                        !!(root?.token && invalidAdmin)
                          ? H.push(`/term-view/${T?.id}`)
                          : H.push(`/login`)
                      }
                    />
                    <Hoc.Typo02 title="Category" content={T.category} />
                    <Hoc.Typo02 title="Country" content={T.country} />
                    <Hoc.Typo02 title="Tongue" content={T.tongue} />

                    <Hoc.Typo02
                      title="Description"
                      content={T.text}
                      rtl={!CKRTL(T?.tongue)}
                    />
                    {!!(!!T?.audio || !!T?.video) && (
                      <Box {...Hoc.flex("flex-end")}>
                        <CustomBtn
                          onClick={() =>
                            setShowMedia((M) =>
                              !M.includes(T.id)
                                ? [T.id, ...M]
                                : M.filter((M1) => M1 !== T.id)
                            )
                          }
                          variant="outlined"
                          content={`${
                            !showMedia.includes(T.id) ? "Show" : "Hide"
                          } Media`}
                        />
                      </Box>
                    )}
                    {T?.audio && showMedia.includes(T.id) && (
                      <Hoc.Typo02
                        content={
                          <audio controls src={GetUrl(T?.audio, true)} />
                        }
                      />
                    )}
                    {T?.video && showMedia.includes(T.id) && (
                      <video {...video_setup(T?.video)} />
                    )}
                  </CardContent>
                </CardActionArea>
                {canEdit(T?.UserId) && invalidAdmin && (
                  <Box {...Hoc.flex("flex-end")} component={CardActions}>
                    <CustomBtn
                      onClick={() => H.push(`/edit-term/${T?.id}`)}
                      icon={<EditIcon />}
                      content="Edit"
                    />
                    <CustomBtn
                      onClick={() => onDelete(T?.id)}
                      icon={<DeleteIcon />}
                      content="Delete"
                      secondary
                    />
                    {isAdmin && (
                      <CustomBtn
                        onClick={() => onEnable(T?.id, !T?.enabled)}
                        icon={<EditIcon />}
                        content={`${!T?.enabled ? "P" : "Unp"}ublish`}
                      />
                    )}
                  </Box>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box className={css.paginate}>
          <Pagination
            hideNextButton
            hidePrevButton
            onChange={({ target: T }) => toPage(T?.textContent)}
            count={lenUnp}
            shape="rounded"
            color="primary"
          />
        </Box>
      </Box>
    </Hoc.UseData>
  );
};

const GetUrl = (v, ad) =>
  `${process.env.REACT_APP_API}/${!ad ? "video" : "audio"}/${v}`;

const video_setup = (src) => ({
  controls: true,
  className: "rounded mx-auto d-block",
  style: { width: "100%", maxHeight: 200 },
  src: GetUrl(src),
});

const CustomBtn = (props) => {
  const { content, icon, secondary = false, ...rest } = props;
  const { variant, mx, ...rest02 } = rest;
  return (
    <Button
      {...(mx && { style: { margin: 10 } })}
      {...(icon && { startIcon: icon })}
      variant={variant || "contained"}
      color={!secondary ? "primary" : "secondary"}
      size="small"
      {...rest02}
    >
      {content}
    </Button>
  );
};

const useCSS = makeStyles((theme) => ({
  paginate: {
    margin: theme.spacing(4, 0),
    ...Hoc.flex(),
  },
  topActions: {
    ...Hoc.flex(),
    margin: theme.spacing(2, 0),
  },
  card: {
    width: "100%",
    maxWidth: 400,
    margin: "0 auto",
  },
  cardImg: {
    height: 175,
  },
}));

export default TermListMobile;
