import React, { useEffect } from "react";
import Hoc from "../../component/Hoc";
import action from "../../action";
import Subscription from "./Subscription-Item";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";

const Subscriptions = () => {
  const act_plan = action.plan.view_all;
  const dispatch = useDispatch();

  const plans = useSelector(({ plan: P }) => P.view_all.data);

  useEffect(() => {
    dispatch(act_plan());
  }, [dispatch, act_plan]);

  const nav_01 = {
    title: "Subscriptions",
    actions: [{ name: "Back", goBack: true }],
  };

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Grid {...stls}>
        {plans?.map((v) => (
          <Subscription key={v.id} data={v} />
        ))}
      </Grid>
    </Hoc.Dashboard.Container>
  );
};

const stls = {
  container: true,
  spacing: 2,
  justifyContent: "center",
};

export default Subscriptions;
