import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="text-center">
      <h4>No examples found, consider adding one please.</h4>
      <div>
        <button>
          <Link to="/add-term">Add </Link>{" "}
        </button>
      </div>
    </div>
  );
}
