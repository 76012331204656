import React, { useEffect } from "react";
import TemplateForm from "./TemplateForm";
import Action from "../../action";
import Hoc from "../../component/Hoc";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ReactRTE from "react-rte";

const Template = () => {
  const P = useParams();
  const act_tem = Action.template;
  const formData = Hoc.useForm.useForm(initData());
  const { vals, setVals } = formData;
  const dispatch = useDispatch();
  const H = useHistory();

  useEffect(() => {
    const setVV = async () => {
      const dt = await dispatch(act_tem.view(P?.id));
      setVals(initData(dt));
    };

    P?.id && setVV();
  }, [dispatch, setVals, act_tem, P]);

  const onSubmit = async () => {
    const finalData = (({ editorState, ...rest }) => rest)(vals);

    const status = await dispatch(act_tem.view_edit(P.id, finalData));

    if (!!status) {
      H.goBack();
      dispatch(Action.OOP("Template has been Updated!!."));
    }
  };

  return (
    <TemplateForm
      id={P?.id}
      title="Create Email Template"
      onSubmit={onSubmit}
      formData={formData}
    />
  );
};

const initData = (v) => ({
  editorState: ReactRTE.createValueFromString(v?.html, "html"),
  name: v?.name ? v?.name : null,
  subject: v?.subject ? v?.subject : null,
  html: v?.html ? v?.html : null,
  action: v?.action ? v?.action : null,
});

export default Template;
