import React, { useEffect } from "react";
import { Card, CardContent, CardActions } from "@material-ui/core";
import { Box, Grid, Button } from "@material-ui/core";
import Hoc from "../../component/Hoc";
import action from "../../action";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@material-ui/core/styles";

const ReviewReports = () => {
  const act_review = action.review;
  const act_report = action.report;
  const dispatch = useDispatch();
  const P = useParams();
  const { data, loading, error } = useSelector(({ review: R }) => R.view);
  const reports = useSelector(({ report: R }) => R.view_all.data);
  const root = useSelector(({ user: R }) => R.login.data);

  useEffect(() => {
    dispatch(act_review.view(P?.id));
    dispatch(act_report.view_all(`ReviewId=${P?.id}`));
  }, [dispatch, act_review, act_report, P]);

  const onReportPublish = (id, enabled) =>
    dispatch(act_report.view_edit(id, { enabled }, `ReviewId=${P?.id}`));

  const onReportDelete = (id) =>
    dispatch(act_report.view_delete(id, `ReviewId=${P?.id}`));

  const CKR = (v) => v === root?.role;
  const isAdmin = !!(
    CKR("Manager") || !!(CKR("Admin") && !!root?.admin_status)
  );
  const actions = [{ name: "Back", goBack: true }];

  if (isAdmin) {
    const onPublishToggle = () => {
      if (window.confirm("Are you sure, You want to Update it.") === true) {
        dispatch(act_review.view_edit(data?.id, { enabled: !data?.enabled }));
        dispatch(act_review.view(P?.id));
      }
    };
    const onDelete = () => {
      if (window.confirm("Are you sure, You want to delete.") === true) {
        dispatch(act_review.view_delete(data?.id));
        dispatch(act_review.view(P?.id));
      }
    };

    const TOC = (name, onClick) => ({ name, onClick });
    const pname = `${!data?.enabled ? "P" : "Unp"}ublish`;
    actions.push(TOC(pname, () => onPublishToggle()));
    actions.push({ name: "My Reports", to: "/created-report-items" });

    actions.push(TOC("Delete", () => onDelete()));
  }

  const nav_01 = {
    title: "Reports for Review",
    actions,
  };
  const css = useCSS();
  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Hoc.UseData data loading={loading} error={error}>
        <Box>
          <Box className={css.review}>
            <Hoc.Typo align="center" content={data?.text} />
            <Hoc.Typo
              component="a"
              content={data?.url}
              href={data?.url}
              rel="noreferrer"
              target="_blank"
            />
          </Box>
          {!!Hoc.isArr(reports).length && (
            <Box className={css.reports}>
              <Hoc.Typo content="Reports" variant="h5" align="center" />
              <Grid container spacing={2} justifyContent="center">
                {reports.map((rp, idx) => (
                  <Grid key={`${idx}-${rp?.id}`} item xs={12} sm={6}>
                    <Card>
                      <CardContent>
                        <Typo02 title="Reason" content={rp.reason} />
                        <Typo02 title="Email" content={rp.email} />
                        <Typo02 title="Contact" content={rp.contact} />
                      </CardContent>
                      {isAdmin && (
                        <CardActions>
                          <Box width="100%" {...Hoc.flex("flex-end")}>
                            <CustomButton
                              onClick={() =>
                                onReportPublish(rp?.id, !rp?.enabled)
                              }
                              content={`${!rp?.enabled ? "P" : "Unp"}ublish`}
                            />
                            <CustomButton
                              onClick={() => onReportDelete(rp?.id)}
                              color="secondary"
                              content="Delete"
                            />
                          </Box>
                        </CardActions>
                      )}
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const CustomButton = ({ content, color = "primary", ...rest }) => (
  <Button
    style={{ marginLeft: 5 }}
    variant="outlined"
    size="small"
    color={color}
    {...rest}
  >
    {content}
  </Button>
);

const Typo02 = (dt) => dt?.content && <Hoc.Typo02 {...dt} />;

const useCSS = makeStyles((theme) => ({
  review: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(0.5, 1),
  },
  reports: {
    marginTop: theme.spacing(4),
  },
}));

export default ReviewReports;
