import types from "./types.jsx";

const defState = {
  loading: false,
  data: null,
  error: null,
};
const defStateList = {
  loading: false,
  data: [],
  error: null,
};

export const list = {
  name: "list",
  state: defStateList,
  request: types.FOOTER_LIST_REQUEST,
  success: types.FOOTER_LIST_SUCCESS,
  fail: types.FOOTER_LIST_FAIL,
};

export const view = {
  name: "view",
  state: defState,
  request: types.FOOTER_VIEW_REQUEST,
  success: types.FOOTER_VIEW_SUCCESS,
  fail: types.FOOTER_VIEW_FAIL,
};

export const email_s = {
  name: "email",
  state: defState,
  request: types.EMST_VIEW_REQUEST,
  success: types.EMST_VIEW_SUCCESS,
  fail: types.EMST_VIEW_FAIL,
};

export const view_add = {
  name: "view_add",
  state: defState,
  request: types.FOOTER_ADD_REQUEST,
  success: types.FOOTER_ADD_SUCCESS,
  fail: types.FOOTER_ADD_FAIL,
};

export const view_edit = {
  name: "view_edit",
  state: defState,
  request: types.FOOTER_EDIT_REQUEST,
  success: types.FOOTER_EDIT_SUCCESS,
  fail: types.FOOTER_EDIT_FAIL,
};

export const view_delete = {
  name: "view_delete",
  state: defState,
  request: types.FOOTER_DELETE_REQUEST,
  success: types.FOOTER_DELETE_SUCCESS,
  fail: types.FOOTER_DELETE_FAIL,
};
