import { Card, CardContent } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import Hoc from "../../../component/Hoc";
import moment from "moment";

const UserProfile = () => {
  const root = useSelector(({ user: P }) => P.login.data);

  const TOT = (name, to) => ({ name, ...(!to ? { goBack: true } : { to }) });

  const nav_01 = {
    title: "My Profile",
    actions: [
      TOT("Update Password", "/edit-profile-password"),
      TOT("Update Profile", "/edit-profile-details"),
      TOT("Back"),
    ],
  };

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Card style={{ minWidth: "80%" }}>
        <CardContent>
          <h4>Personal Details</h4>

          <Hoc.Typo02
            title="Full Name"
            content={`${root?.title || ""} ${root?.first_name || ""} ${
              root?.last_name || ""
            }`}
          />
          <Hoc.Typo02 title="Email" content={root?.email} />
          <Hoc.Typo02 title="Contact" content={root?.contact} />
          <Hoc.Typo02 title="Country" content={root?.country} />

          {root?.role === "Admin" ? (
            <Hoc.Typo02
              title="Admin Status"
              content={`${!root?.admin_status ? "Not" : ""} Verified`}
            />
          ) : root?.role === "Admin" ? (
            <Hoc.Typo02
              title="Subscription Date"
              content={toDate(root?.subscription_date)}
            />
          ) : null}
          <Hoc.Typo02 title="Source Language" content={root?.source_language} />
          <Hoc.Typo02 title="Target Language" content={root?.target_language} />
          {root?.role !== "User" && (
            <Hoc.Typo02 title="Designation" content={root?.role} />
          )}
          <Hoc.Typo02 title="Created At" content={toDate(root?.createdAt)} />
          <Hoc.Typo02 title="Updated At" content={toDate(root?.updatedAt)} />
        </CardContent>
      </Card>
    </Hoc.Dashboard.Container>
  );
};

const toDate = (v) => (!v ? null : moment(v).format("LLL"));

export default UserProfile;
