import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

export default function Logo({ sx, size }) {
  return (
    <Box
      component="img"
      src="/logo.png"
      style={{ height: size || 40, ...sx }}
    />
  );
}

Logo.propTypes = {
  sx: PropTypes.object,
};
