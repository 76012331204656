import { Route } from "react-router-dom";
import SecureRoute from "../../SecureRoute";
import user_profile from "./user_profile";
import email_confirmation from "./email_confirmation";
import forgot_password from "./forgot_password";
import reset_password from "./reset_password";
import edit_password from "./edit_password";
import edit_profile from "./edit_profile";

const Expo = {
  user_profile: (
    <SecureRoute
      grant={["User"]}
      path="/profile-view"
      component={user_profile}
    />
  ),
  edit_password: (
    <SecureRoute
      grant={["User"]}
      path="/edit-profile-password"
      component={edit_password}
    />
  ),
  edit_profile: (
    <SecureRoute
      grant={["User"]}
      path="/edit-profile-details"
      component={edit_profile}
    />
  ),
  email_confirmation: (
    <Route
      exact
      path="/email-confirmation/:token"
      component={email_confirmation}
    />
  ),
  forgot_password: (
    <Route exact path="/forgot-password/:role" component={forgot_password} />
  ),
  reset_password: (
    <Route
      exact
      path="/reset-password/:resetToken"
      component={reset_password}
    />
  ),
};

export default Expo;
