import React, { useState, useEffect } from "react";
import Hoc from "../../component/Hoc";
import Controls from "../../component/Hoc/Controls";
import Action from "../../action";
import ReactRTE from "react-rte";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Template = (props) => {
  const Act = Action.template.action_name_view_all;
  const CSS = useCSS();
  const { title, onSubmit, formData } = props;
  const { Form } = Hoc.useForm;
  const { vals, onChange, resetForm, setVals } = formData;
  const [actions, setActions] = useState([]);

  useEffect(() => {
    const SetVV = async () => setActions(await Act());
    SetVV();
  }, [Act]);

  let template_variables = actions?.filter((v) =>
    vals?.action?.includes(v?.name)
  );
  template_variables = template_variables?.map((v) => [...v?.values]);
  template_variables = [...new Set(template_variables?.flat(1))];

  const onRteChange = (v) => {
    setVals({ ...vals, editorState: v, html: v.toString("html") });
  };

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{ disabled: setup_status(vals) }}
      >
        <Controls.Input {...setup.name(vals.name, onChange)} />
        <Controls.Input {...setup.subject(vals.subject, onChange)} />
        <Controls.SearchSelect
          {...setup.actions(vals.action, actions, onChange)}
        />
        <ReactRTE value={vals.editorState} onChange={onRteChange} />
      </Form>

      <Hoc.Dashboard.Container title="Template Variables">
        <Chip label="%url-(url name)%" className={CSS.chip} />
        {!!template_variables?.length &&
          template_variables.map((v) => (
            <Chip key={v} label={v} className={CSS.chip} />
          ))}
      </Hoc.Dashboard.Container>
    </Hoc.FormContainer>
  );
};

const setup_status = (v) =>
  !v?.name || !v?.subject || !v?.html || v?.html === "<p><br></p>";

const setup = {
  name: (v, onChange) => Hoc.setupText(v, onChange, "name", "Template Name"),
  subject: (v, onChange) =>
    Hoc.setupText(v, onChange, "subject", "Email Subject"),
  html: (v, onChange) => Hoc.setupText(v, onChange, "html", "html"),
  actions: (v, opt, onChange) =>
    Hoc.setupSelect(
      v,
      onChange,
      "action",
      "Select Actions",
      Hoc.isArr(opt).map(({ name: id }) => ({
        id,
        title: `${id}`.split("_").join(" "),
      }))
    ),
};

const useCSS = makeStyles((theme) => ({
  chip: {
    margin: 2,
  },
}));

export default Template;
