import { AppBar, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Hoc from "../../../component/Hoc";

function a11yProps01(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export const HorizantalTab = ({ value, handleChange, labels }) => {
  return (
    <AppBar position="static" color="default">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {!!labels?.word && <Tab label={labels?.word} {...a11yProps01(0)} />}
        {Hoc.isArr(labels?.items).map((word, idx) => (
          <Tab key={`${word}-${idx}`} label={word} {...a11yProps01(idx + 1)} />
        ))}
      </Tabs>
    </AppBar>
  );
};

function a11yProps02(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export const VerticalTab = ({ value, handleChange, labels }) => {
  const css = useCSS();
  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      indicatorColor="primary"
      textColor="primary"
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      className={css.tabs}
    >
      {!!labels?.word && <Tab label={labels?.word} {...a11yProps02(0)} />}
      {Hoc.isArr(labels?.items).map((word, idx) => (
        <Tab key={`${word}-${idx}`} label={word} {...a11yProps02(idx + 1)} />
      ))}
    </Tabs>
  );
};

const useCSS = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));
