import { view_add as OOP } from "./base01/opp";
import * as template from "./base01/template";
import * as review from "./base01/review";
import * as report from "./base01/report";
import * as user from "./base01/user";
import * as plan from "./base01/plan";

import * as term from "./base02/term";
import * as translation from "./base02/translation";
import * as footer from "./base02/footer";
import * as bank from "./base02/bank";

// Action is function which request a service from the server (Bakend)
// This page contains all actions seperated by topics.
// For Instance if you want to create a report than you should call Action.report.view_add() function.

const Expo = {
  term,
  plan,
  translation,
  review,
  report,
  user,
  template,
  footer,
  bank,
  OOP,
};

export default Expo;

// export const update_review = (id, initData) => async (dispatch) => {
//   try {
//     dispatch({ type: 'UPDATE_REQUEST_TYPE' });
//     const data  = await axios.put(`www.url.com/review/${id}`, initData);
//     dispatch({ type: 'UPDATE_SUCCESS_TYPE', payload: data });
//     return data;
//   } catch (err) {
//     Helper.errResponse(err, dispatch, 'UPDATE_SUCCESS_TYPE');
//   }
// };

// This function updates review data from server and i am going explain this clearly bellow.
// This completed function do only onething. Send request to the URL to update the data its just a one single line of code.
// But everytime we call a request we update the appropriate Redux State and we can update redux by calling dispatch function.
// Redux identify the action by the Type we pass on the dispatch fuction. In the dispatch fuction we also pass payload variable which will take the data to be update.
// We have also used trycatch block to catch errors and show it different way. In the catch block we do not call dispatch directly instead we use a
// custom fuction to take the error and show it in the model.
