import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../Controls";
import Hoc from "../../Hoc";
import { useMediaQuery, useTheme, Box } from "@material-ui/core";

const useCss = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  side_view: {
    backgroundColor: "red",
  },
}));

const Form = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { endContent, children, name, resetForm } = props;
  const { onSubmit, noUndo = false, submitButton } = props;

  const submitHandler = () => {
    onSubmit();
  };

  const css = useCss();

  return (
    <form
      className={css.root}
      autoComplete="off"
      onSubmit={(e) => e.preventDefault()}
    >
      <div style={{ margin: "20px 0" }}>{children}</div>
      <Box {...(!isMobile && Hoc.flex("space-evenly"))}>
        {!noUndo && resetForm && (
          <Controls.Button
            {...(isMobile && { style: { width: "100%" } })}
            text="Undo / Back to Original"
            color="secondary"
            onClick={resetForm}
          />
        )}
        <Controls.Button
          {...(isMobile && { style: { width: "100%" } })}
          onClick={submitHandler}
          text={name || "Submit"}
          {...submitButton}
        />
      </Box>
      {endContent}
    </form>
  );
};

const useForm = (initValues, validateOnChange = false, validate) => {
  const [vals, setVals] = useState(initValues);
  const [errors, setErrors] = useState({});

  const onChange = ({ target: { name, value } }) => {
    setVals({ ...vals, [name]: value });
    if (validateOnChange) validate({ [name]: value });
  };

  const onFileChange = ({ target: { name, value, files } }) => {
    setVals({ ...vals, [name]: files[0] });
  };

  const resetForm = () => {
    setVals(initValues);
    setErrors(null);
  };

  return {
    vals,
    setVals,
    errors,
    setErrors,
    onChange,
    onFileChange,
    resetForm,
  };
};

const useFormSetup = {
  Form,
  useForm,
};

export default useFormSetup;
