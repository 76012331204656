import React from "react";
import Hoc from "../../../component/Hoc";
import Controls from "../../../component/Hoc/Controls";
import action from "../../../action";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Login = () => {
  const P = useParams();
  const act_psw = action.user.forgot_password;
  const dispatch = useDispatch();
  const H = useHistory();
  const { Form, useForm } = Hoc.useForm;
  const fmData = useForm({ email: "", role: P?.role });
  const { vals, setVals, onChange, resetForm } = fmData;

  const onSubmit = async () => {
    const status = await dispatch(act_psw(vals));

    if (!!status) {
      dispatch(action.OOP("Password Submitted."));
      setVals({ email: "", role: P?.role });
    }
    H.goBack();
  };

  const fmApply = {
    onSubmit,
    resetForm,
    submitButton: {
      disabled: !vals?.email,
    },
  };

  return (
    <Hoc.FormContainer title="Forgot Password">
      <Form {...fmApply}>
        <Controls.Input {...setup.email(vals?.email, onChange)} />
      </Form>
    </Hoc.FormContainer>
  );
};

const setup = {
  email: (v, onChange) =>
    Hoc.setupText(v, onChange, "email", "Email Address", "email"),
};

export default Login;
