const types = {
  //
  TEMPLATE_LIST_REQUEST: "TEMPLATE_LIST_REQUEST",
  TEMPLATE_LIST_SUCCESS: "TEMPLATE_LIST_SUCCESS",
  TEMPLATE_LIST_FAIL: "TEMPLATE_LIST_FAIL",
  //
  TEMPLATE_VIEW_REQUEST: "TEMPLATE_VIEW_REQUEST",
  TEMPLATE_VIEW_SUCCESS: "TEMPLATE_VIEW_SUCCESS",
  TEMPLATE_VIEW_FAIL: "TEMPLATE_VIEW_FAIL",
  //
  TEMPLATE_ADD_REQUEST: "TEMPLATE_ADD_REQUEST",
  TEMPLATE_ADD_SUCCESS: "TEMPLATE_ADD_SUCCESS",
  TEMPLATE_ADD_FAIL: "TEMPLATE_ADD_FAIL",
  //
  TEMPLATE_EDIT_REQUEST: "TEMPLATE_EDIT_REQUEST",
  TEMPLATE_EDIT_SUCCESS: "TEMPLATE_EDIT_SUCCESS",
  TEMPLATE_EDIT_FAIL: "TEMPLATE_EDIT_FAIL",
  //
  TEMPLATE_DELETE_REQUEST: "TEMPLATE_DELETE_REQUEST",
  TEMPLATE_DELETE_SUCCESS: "TEMPLATE_DELETE_SUCCESS",
  TEMPLATE_DELETE_FAIL: "TEMPLATE_DELETE_FAIL",
};

export default types;
