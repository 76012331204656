const types = {
  //
  REVIEW_LIST_REQUEST: "REVIEW_LIST_REQUEST",
  REVIEW_LIST_SUCCESS: "REVIEW_LIST_SUCCESS",
  REVIEW_LIST_FAIL: "REVIEW_LIST_FAIL",
  //
  REVIEW_VIEW_REQUEST: "REVIEW_VIEW_REQUEST",
  REVIEW_VIEW_SUCCESS: "REVIEW_VIEW_SUCCESS",
  REVIEW_VIEW_FAIL: "REVIEW_VIEW_FAIL",
  //
  REVIEW_ADD_REQUEST: "REVIEW_ADD_REQUEST",
  REVIEW_ADD_SUCCESS: "REVIEW_ADD_SUCCESS",
  REVIEW_ADD_FAIL: "REVIEW_ADD_FAIL",
  //
  REVIEW_EDIT_REQUEST: "REVIEW_EDIT_REQUEST",
  REVIEW_EDIT_SUCCESS: "REVIEW_EDIT_SUCCESS",
  REVIEW_EDIT_FAIL: "REVIEW_EDIT_FAIL",
  //
  REVIEW_DELETE_REQUEST: "REVIEW_DELETE_REQUEST",
  REVIEW_DELETE_SUCCESS: "REVIEW_DELETE_SUCCESS",
  REVIEW_DELETE_FAIL: "REVIEW_DELETE_FAIL",
};

export default types;
