import { combineReducers } from "redux";
import { toReducer } from "../utils";
import * as reducer from "./reducer";

const cmp_admin = combineReducers({
  ...toReducer(reducer.register),
  ...toReducer(reducer.login),
  ...toReducer(reducer.logout),
  ...toReducer(reducer.verify_email),
  ...toReducer(reducer.check_jwt),
  ...toReducer(reducer.list),
  ...toReducer(reducer.add_subscription),
  ...toReducer(reducer.forgot_password),
  ...toReducer(reducer.reset_password),
  ...toReducer(reducer.view_profile),
  ...toReducer(reducer.edit_profile),
  ...toReducer(reducer.edit_profile_psw),
  ...toReducer(reducer.edit_user),
});

export default cmp_admin;
