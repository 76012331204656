import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import action from "../../../action";
import Hoc from "../../../component/Hoc";

const EmailConfirmation = () => {
  const P = useParams();
  const dispatch = useDispatch();
  const verify_email = action.user.verify_email;
  const [token, role] = `${P?.token}`.split("--");

  useEffect(() => {
    if (!!token) {
      dispatch(verify_email(token));
    }
  }, [dispatch, verify_email, token]);

  const usr = usrs.find((v) => v.role === role);
  const nav_01 = {
    title: `${!usr?.url ? "" : role} Email Confirmation`,
    actions: [
      { name: "Login", to: `/${usr?.url}login` },
      { name: "Home", to: `/glossary` },
    ],
  };
  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Hoc.Typo
        variant="h4"
        color="primary"
        content="Thank you for registering in our site."
      />
    </Hoc.Dashboard.Container>
  );
};

const usrs = [
  { role: "User", url: "" },
  { role: "Subscriber", url: "subscriber-" },
  { role: "Guest Editor", url: "guest-" },
  { role: "Admin", url: "admin-" },
  { role: "Manager", url: "supreme-" },
];

export default EmailConfirmation;
