import React, { useRef, useState } from "react";
import Hoc from "../../component/Hoc";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Controls from "../../component/Hoc/Controls";
import action from "../../action";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Badge, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import SignaturePad from "react-signature-canvas";
import TermMedia from "./TermMedia";

import "react-simple-keyboard/build/css/index.css";
import Keyboard from "react-simple-keyboard";
import Layouts from "simple-keyboard-layouts";
import layouts, { RTLs } from "../../assets/layouts";

const CreateTerm = ({ id }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [hide, setHide] = useState(true);
  const [hide02, setHide02] = useState(true);
  const { useForm, Form } = Hoc.useForm;
  const H = useHistory();
  const useSignature = useRef({});
  const act_term = action.term;
  const dispatch = useDispatch();
  const { vals, setVals, onChange, resetForm } = useForm(initData());

  const onSubmit = () => {
    const canvas = !!useSignature?.current?.getTrimmedCanvas
      ? useSignature?.current?.getTrimmedCanvas()?.toDataURL("image/png")
      : false;

    let view = new FormData();
    Object.entries(vals).forEach((v) => !!v[1] && view.set(v[0], v[1]));
    !!canvas && view.set("symbol", canvas);

    if (!id) dispatch(act_term.view_add(view, "enabled=true"));
    else dispatch(act_term.view_edit(id, view, "enabled=true"));

    const msg = `"Resource ${!id ? "Created" : "Updated"} Successfully"`;
    dispatch(action.OOP(msg));
    H.push("/glossary");
  };

  useEffect(() => {
    const setT = async () => {
      const dt = await dispatch(act_term.view(id));
      setVals(initData(dt));
    };
    id && setT();
  }, [dispatch, act_term, id, setVals]);

  const title = !id ? "Create New Resource" : "Updating Resource";

  const css = useCSS();

  const layoutData = new Layouts();
  const [layout, setLayout] = useState(null);

  const onLangChange = ({ target: T }) => {
    setVals({ ...vals, tongue: T.value });
    const ly = layouts.find((v) => v.langs.includes(T.value));
    setLayout(!ly?.key ? null : layoutData.get(ly?.key));
  };

  const [openKeyword, setOpenKeyword] = useState("");

  const CKK = (v) => openKeyword === v;

  const onKeyToggle = (name) =>
    setOpenKeyword(!!(!openKeyword || openKeyword !== name) ? name : "");
  const onKeyChange = (N, V) => setVals({ ...vals, [N]: V });

  const FromKeyInput = (name) => (
    <KeyInput
      layout={layout}
      enabled={CKK(name)}
      onChange={(v) => onKeyChange(name, v)}
      onToggle={() => onKeyToggle(name)}
    />
  );
  const isRTL = RTLs.includes(vals?.tongue);

  return (
    <Hoc.FormContainer
      title={title}
      subtitle="Multi Language Keyboard is available only for limited number of languages"
    >
      <Form
        resetForm={resetForm}
        onSubmit={onSubmit}
        submitButton={{ disabled: !!CKF01(vals) }}
      >
        <Controls.AutoSelect {...setup.tongue(vals?.tongue, onLangChange)} />

        <Controls.AutoSelect {...setup.country(vals?.country, onChange)} />

        <Controls.Input dir_rtl={isRTL} {...setup.word(vals?.word, onChange)} />
        {FromKeyInput("word")}

        <Controls.Input dir_rtl={isRTL} {...setup.text(vals?.text, onChange)} />
        {FromKeyInput("text")}

        <Controls.Input
          dir_rtl={isRTL}
          {...setup.category(vals?.category, onChange)}
        />
        {FromKeyInput("category")}

        <Controls.Input dir_rtl={isRTL} {...setup.note(vals?.note, onChange)} />
        {FromKeyInput("note")}

        <Box style={{ width: "80%", margin: "0 auto" }}>
          <Box
            style={{ margin: "15px 0" }}
            {...(!isMobile && Hoc.flex("space-between"))}
          >
            <Controls.FileUpload {...setup.photo(vals?.photo, onChange)} />
            <Controls.FileUpload {...setup.audio(vals?.audio, onChange)} />
            <Controls.FileUpload {...setup.video(vals?.video, onChange)} />
          </Box>
        </Box>
        <TermMedia id={id} vals={vals} setVals={setVals} term />
        <Box className={css.pad_top}>
          <Box {...Hoc.flex("space-around")} style={{ marginBottom: 10 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                !vals?.symbol ? setHide02(!hide02) : setHide(!hide)
              }
            >
              {!vals?.symbol
                ? `${!hide02 ? "Cancel" : "Create Signature"}`
                : `${!hide ? "Hide" : "Show"} Signature`}
            </Button>
          </Box>

          {!hide && vals?.symbol && (
            <Box {...Hoc.flex("center")}>
              <Badge
                badgeContent={!hide02 ? "Cancel" : "Update"}
                color="secondary"
                style={{ cursor: "pointer" }}
                onClick={() => setHide02(!hide02)}
              >
                <img src={vals?.symbol} alt="aaa" className={css.pad_root_03} />
              </Badge>
            </Box>
          )}
          {!hide02 && (
            <Box>
              <Box className={css.pad_root_02}>
                <SignaturePad
                  ref={useSignature}
                  canvasProps={{ className: css.pad }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Form>
    </Hoc.FormContainer>
  );
};

const CKF01 = (v) => !v?.word || !v?.tongue || !v?.category || !v?.country;

const KeyBtn = ({ enabled, onClick }) => (
  <Box width="100%" style={{ marginBottom: 10 }} {...Hoc.flex("flex-end")}>
    <Button
      onClick={onClick}
      size="small"
      variant="outlined"
      color={enabled ? "primary" : "secondary"}
    >{`${enabled ? "Hide" : "Show"}  Keyboard`}</Button>
  </Box>
);

const KeyInput = ({ enabled, layout, onChange, onToggle }) => {
  const [layoutName, setLayoutName] = useState("default");

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}")
      setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  if (!layout) {
    return <span />;
  }

  return (
    <React.Fragment>
      {!!enabled && (
        <Keyboard
          layoutName={layoutName}
          onKeyPress={onKeyPress}
          onChange={onChange}
          {...layout}
        />
      )}
      <KeyBtn onClick={onToggle} enabled={enabled} />
    </React.Fragment>
  );
};

const useCSS = makeStyles((theme) => ({
  pad_top: {
    padding: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: 5,
  },
  pad: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    margin: theme.spacing(0, "auto"),
    width: "100%",
    height: "100%",
  },
  pad_root_02: {
    width: 400,
    maxWidth: "100%",
    height: 200,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
  },
  pad_root_03: {
    border: `2px solid ${theme.palette.primary.light}`,
    width: 200,
    height: 100,
  },
}));

const initData = (v) => ({
  word: v?.word ? v?.word : null,
  text: v?.text ? v?.text : null,
  //
  photo: v?.photo ? v?.photo : null,
  audio: v?.audio ? v?.audio : null,
  video: v?.video ? v?.video : null,
  //
  tongue: v?.tongue ? v?.tongue : null,
  //
  category: v?.category ? v?.category : null,
  note: v?.note ? v?.note : null,
  symbol: v?.symbol ? v?.symbol : null,
  country: v?.country ? v?.country : null,
});

const setup = {
  word: (N, OC) => Hoc.setupText(N, OC, "word", "Word"),
  text: (N, OC) => Hoc.setupLongText(N, OC, "text", "Text"),
  //
  photo: (N, OC) => Hoc.setupFile(N, OC, "photo", "Photo", "image/*"),
  audio: (N, OC) => Hoc.setupFile(N, OC, "audio", "Audio", "audio/*"),
  video: (N, OC) => Hoc.setupFile(N, OC, "video", "Video", "video/*"),
  //
  tongue: (N, OC) => Hoc.setupAutoSelect(N, OC, "tongue", "Tongue", "L"),
  country: (N, OC) => Hoc.setupAutoSelect(N, OC, "country", "Country", "C"),
  //
  category: (N, OC) => Hoc.setupText(N, OC, "category", "Category"),
  note: (N, OC) => Hoc.setupLongText(N, OC, "note", "Note"),
  symbol: (N, OC) => Hoc.setupText(N, OC, "symbol", "Symbol"),
};

export const AddTerm = () => {
  return <CreateTerm />;
};

export const EditTerm = () => {
  const P = useParams();

  return <CreateTerm id={P.id} />;
};
