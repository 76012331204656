import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Hoc from "../../component/Hoc";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { Grid, Box } from "@material-ui/core";
import { useState } from "react";
import Payment from "../../component/Custom/Payment";
import Controls from "../../component/Hoc/Controls";
import { useSelector } from "react-redux";

const Subscription = ({ data }) => {
  const [payToggle, setPayToggle] = useState(false);
  const [method, setMethod] = useState("PayPal");
  const root = useSelector(({ user: U }) => U.login.data);
  const CKM = (v) => method === v;
  const css = useCSS();
  const Typo01 = ({ content }) => (
    <Hoc.Typo content={content} className={css.hdr} />
  );

  const subBtnProps = {
    size: "small",
    onClick: () => setPayToggle(!payToggle),
    color: !payToggle ? "primary" : "secondary",
    variant: "outlined",
  };

  const payDetails = {
    amount: data?.price,
    PlanId: data?.id,
    refresh: () => console.log("Payments"),
  };

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Card className={css.cardRoot}>
        <CardContent>
          <Typo01 content={data?.name} />
          <Hoc.Typo02
            title="Amount"
            content={`${Hoc.toCurrency("GBP", data?.price)}`}
          />

          {data?.days && (
            <Hoc.Typo02 title="Days" content={`${data?.days} Days`} />
          )}
          {data?.months && (
            <Hoc.Typo02 title="Months" content={`${data?.months} Months`} />
          )}

          {`${data.text}`?.split(",,")?.map((txt, idx) => (
            <Hoc.Typo02 key={idx} content={txt} />
          ))}
          {root.role === "Subscriber" && (
            <Box
              style={{ width: "100%", margin: "10px 0" }}
              {...Hoc.flex("flex-end")}
            >
              <Button {...subBtnProps}>
                {!payToggle ? "Subscribe" : "Cancel"}
              </Button>
            </Box>
          )}
          {payToggle && (
            <Controls.SearchSelect {...setup.method(method, setMethod)} />
          )}
        </CardContent>
        <CardActions>
          {payToggle && (
            <Box style={{ width: "100%" }}>
              {CKM("PayPal") && <Payment.PayPal {...payDetails} />}
              {CKM("Stripe") && <Payment.Stripe {...payDetails} />}
            </Box>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

const useCSS = makeStyles((theme) => ({
  cardRoot: {
    width: "100%",
    padding: theme.spacing(2, 4),
  },
  hdr: {
    color: theme.palette.primary.main,
    fontSize: 21,
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
}));

const setup = {
  method: (v, setV) =>
    Hoc.setupSelect(
      v,
      ({ target: T }) => setV(T.value),
      "method",
      "Select Payment Method",
      Mlist
    ),
};

const Mlist = [
  { id: "PayPal", title: "PayPal" },
  { id: "Stripe", title: "Stripe" },
];

export default Subscription;
