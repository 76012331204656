import React, { useEffect } from "react";

import action from "../../action";
import Hoc from "../../component/Hoc";
import Controls from "../../component/Hoc/Controls";
import { useDispatch } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router";
import qryStr from "query-string";

const ReportForm = ({ id }) => {
  const report_act = action.report;
  const dispatch = useDispatch();
  const H = useHistory();

  const qrs = qryStr.parse(useLocation().search);
  const { useForm, Form } = Hoc.useForm;
  const { vals, onChange, setVals, resetForm } = useForm(initData());

  useEffect(() => {
    const SetVV = async () =>
      setVals(initData(await dispatch(report_act.view(id))));
    !!id && SetVV();
  }, [dispatch, setVals, report_act, id]);

  const onSubmit = async () => {
    const CKT = (v) => (!!v ? v : null);

    const TermId = CKT(qrs?.TermId);
    const TranslationId = CKT(qrs?.TranslationId);
    const ReviewId = CKT(qrs?.ReviewId);

    const finalData = {
      ...vals,
      ...(TermId && { TermId }),
      ...(TranslationId && { TranslationId }),
      ...(ReviewId && { ReviewId }),
    };

    const Qrr = !!TermId
      ? `TermId=${TermId}`
      : !!TranslationId
      ? `TranslationId=${TranslationId}`
      : !!ReviewId
      ? `ReviewId=${ReviewId}`
      : "";

    let status;

    if (!!id) {
      status = await dispatch(report_act.view_edit(id, finalData, Qrr));
    } else {
      status = await dispatch(report_act.view_add(finalData, Qrr));
    }

    if (!!status) {
      dispatch(action.OOP(`Report ${!id ? "Created" : "Updated"}`));
      H.push("/glossary");
    }
  };

  return (
    <Hoc.FormContainer title={`${!!id ? "Update Report" : "Report It"} `}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{ disabled: !!CKF01(vals) }}
      >
        <Controls.Input {...setup.full_name(vals?.full_name, onChange)} />
        <Controls.Input {...setup.email(vals?.email, onChange)} />
        <Controls.PhoneInput {...setup.contact(vals?.contact, onChange)} />
        <Controls.Input {...setup.reason(vals?.reason, onChange)} />
      </Form>
    </Hoc.FormContainer>
  );
};
const CKF01 = (v) => !v?.full_name || !v?.email || !v?.reason;

export const AddReport = () => {
  return <ReportForm />;
};

export const EditReport = () => {
  const P = useParams();
  return <ReportForm id={P?.id} />;
};

const setup = {
  full_name: (v, OC) => Hoc.setupText(v, OC, "full_name", "Full name"),
  email: (v, OC) => Hoc.setupText(v, OC, "email", "Email", "email"),
  contact: (v, OC) => Hoc.setupText(v, OC, "contact", "Contact Number"),
  reason: (v, OC) => Hoc.setupLongText(v, OC, "reason", "Reason"),
};

const initData = (v) => ({
  full_name: v?.full_name ? v?.full_name : null,
  email: v?.email ? v?.email : null,
  contact: v?.contact ? v?.contact : null,
  reason: v?.reason ? v?.reason : null,
  TermId: v?.TermId ? v?.TermId : null,
  TranslationId: v?.TranslationId ? v?.TranslationId : null,
  ReviewId: v?.ReviewId ? v?.ReviewId : null,
  UserI: v?.UserI ? v?.UserI : null,
});
