import types from "./types";

const defState = {
  loading: false,
  data: null,
  error: null,
};

const defListState = {
  loading: false,
  data: [],
  error: null,
};

export const view_all = {
  name: "view_all",
  state: defListState,
  request: types.BANK_LIST_REQUEST,
  success: types.BANK_LIST_SUCCESS,
  fail: types.BANK_LIST_FAIL,
};

export const view = {
  name: "view",
  state: defState,
  request: types.BANK_VIEW_REQUEST,
  success: types.BANK_VIEW_SUCCESS,
  fail: types.BANK_VIEW_FAIL,
};

export const method = {
  name: "method",
  state: defState,
  request: types.BANK_MTD_REQUEST,
  success: types.BANK_MTD_SUCCESS,
  fail: types.BANK_MTD_FAIL,
};

export const view_add = {
  name: "add",
  state: defState,
  request: types.BANK_ADD_REQUEST,
  success: types.BANK_ADD_SUCCESS,
  fail: types.BANK_ADD_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.BANK_EDIT_REQUEST,
  success: types.BANK_EDIT_SUCCESS,
  fail: types.BANK_EDIT_FAIL,
};

export const view_delete = {
  name: "delete",
  state: defState,
  request: types.BANK_DELETE_REQUEST,
  success: types.BANK_DELETE_SUCCESS,
  fail: types.BANK_DELETE_FAIL,
};
