import React from "react";
import { Button as MuiButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Button = (props) => {
  const css = useCss();
  const { children, size, text, color, variant, onClick, fullWidth, ...other } =
    props;
  return (
    <MuiButton
      fullWidth={!!fullWidth}
      size={size || "large"}
      color={color || "primary"}
      variant={variant || "outlined"}
      onClick={onClick}
      classes={{ root: css.root, label: css.label }}
      {...other}
    >
      {children || text || "Click"}
    </MuiButton>
  );
};

const useCss = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
  label: {
    textTransform: "none",
  },
}));

export default Button;
