import React, { useEffect } from "react";
import Hoc from "../../component/Hoc";
import ControlsInput from "../../component/Hoc/Controls/Input";
import Custom from "../../component/Custom";
import action from "../../action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Box } from "@material-ui/core";

const UnPublished = () => {
  const dispatch = useDispatch();

  const data = useSelector(({ report: P }) => P.view_all.data);
  const root = useSelector(({ user: P }) => P.login.data);

  useEffect(() => {
    dispatch(action.report.view_all(`UserId=${root?.id || ""}`));
    // eslint-disable-next-line
  }, [dispatch]);

  const nav_01 = {
    title: `My Reports`.toUpperCase(),
    actions: [{ name: "Back", goBack: true }],
  };
  const H = useHistory();

  const [filterFn, setFilterFn] = React.useState(Hoc.onSearch.action);
  const onSearch = ({ target: T }) =>
    Hoc.onSearch.search(T?.value, setFilterFn);

  const TBL = Custom.useTable(data, reportCells, filterFn);
  const { TblContainer, finalRecords, TableRow, TableCell } = TBL;

  const toUrl = (v) =>
    v?.ReviewId
      ? `/report-list/${v?.ReviewId}`
      : v?.TranslationId
      ? `/term-view/${v?.Translation?.TermId}?TranslationId=${v?.TranslationId}`
      : v?.TermId
      ? `/term-view/${v?.TermId}`
      : "";

  const withUrl = (v, text) => (
    <span onClick={() => H.push(toUrl(v))}>{text}</span>
  );

  const acts = (id) => [
    { name: "Edit", to: `/report-edit/${id}` },
    {
      name: "Delete",
      onClick: () =>
        dispatch(action.report.view_delete(id, `UserId=${root?.id || ""}`)),
    },
  ];

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Box style={{ width: "100%", margin: "20px 0" }} {...Hoc.flex()}>
        <Box style={{ width: 200 }}>
          <ControlsInput label="Search" size="small" onChange={onSearch} />
        </Box>
      </Box>

      {!finalRecords().length && <Hoc.EmptyResource />}
      {!!finalRecords().length && (
        <TblContainer paginate>
          {finalRecords().map((v) => (
            <TableRow key={v.id}>
              <TableCell content={withUrl(v, v?.full_name)} />
              <TableCell content={withUrl(v, v?.email)} />
              <TableCell content={withUrl(v, v?.contact)} />
              <TableCell content={withUrl(v, v?.reason)} />
              <TableCell
                content={withUrl(v, `${!v?.enabled ? "Uns" : "S"}olved`)}
              />
              <TableCell content={withUrl(v, toDate(v?.createdAt))} />
              <TableCell content={<Hoc.MenuButton actions={acts(v?.id)} />} />
            </TableRow>
          ))}
        </TblContainer>
      )}
    </Hoc.Dashboard.Container>
  );
};

const toDate = (v) => moment(v).format("LLL");

const TOC = (id, label, disableSorting = false) => ({
  id,
  label,
  disableSorting,
});
const reportCells = [
  TOC("full_name", "Full Name"),
  TOC("email", "Email"),
  TOC("contact", "Contact Number"),
  TOC("reason", "Reason"),
  TOC("enabled", "Status"),
  TOC("createdAt", "Created At"),
  TOC("07", "Actions", true),
];

export default UnPublished;
