import SecureRoute from "../SecureRoute";
import dashboard from "./dashboard";
import people from "./people";
import subscription from "./Subscription";
import un_published from "./un_published";
import un_published_reports from "./un_published_reports";
import created_reports from "./created_reports";
import un_published_reviews from "./un_published_reviews";

const Expo = {
  dashboard: (
    <SecureRoute grant={["User"]} path="/dashboard" component={dashboard} />
  ),
  un_published: (
    <SecureRoute
      grant={["Admin"]}
      path="/unpublished-items"
      component={un_published}
    />
  ),
  un_published_reports: (
    <SecureRoute
      grant={["Admin"]}
      path="/unpublished-report-items"
      component={un_published_reports}
    />
  ),
  created_reports: (
    <SecureRoute
      grant={["User"]}
      path="/created-report-items"
      component={created_reports}
    />
  ),
  un_published_reviews: (
    <SecureRoute
      grant={["Admin"]}
      path="/unpublished-review-items"
      component={un_published_reviews}
    />
  ),
  people: (
    <SecureRoute grant={["User"]} path="/people-view" component={people} />
  ),
  subscription: (
    <SecureRoute
      grant={["EmpSub"]}
      path="/subscription-view"
      component={subscription}
    />
  ),
};

export default Expo;
