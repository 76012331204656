import user from "./user";
import subscriber from "./subscriber";
import guest_editor from "./Guest Editor";
import admin from "./admin";
import manager from "./manager";
import profile from "./profile";

const Expo = {
  user,
  subscriber,
  guest_editor,
  admin,
  manager,
  profile,
};

export default Expo;
