import SecureRoute from "../SecureRoute";
import settings from "./settings";
import { AddBank, EditBank } from "./BankForm";

const Expo = {
  settings: (
    <SecureRoute
      grant={["Manager"]}
      path="/payment-settings"
      component={settings}
    />
  ),
  add: (
    <SecureRoute
      grant={["Manager"]}
      path="/create-payment-detail/:method"
      component={AddBank}
    />
  ),
  edit: (
    <SecureRoute
      grant={["Manager"]}
      path="/update-payment-detail/:id"
      component={EditBank}
    />
  ),
};

export default Expo;
