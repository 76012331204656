import React, { useEffect } from "react";
import action from "../../../action";
import Hoc from "../../Hoc";
import { useDispatch, useSelector } from "react-redux";
import AddReport from "./Forms/AddReport";
import { Box, Grid, Typography } from "@material-ui/core";

const Report = ({ TermId, TranslationId, root, UserId }) => {
  // Defined report file
  const act_report = action.report;

  const dispatch = useDispatch();
  let data = useSelector(({ report: T }) => T.view_all.data);

  const CKR = (v) => v === root?.role;
  const isAdmin = !!(
    CKR("Manager") || !!(CKR("Admin") && !!root?.admin_status)
  );

  data = Hoc.isArr(data).filter(
    (v) =>
      !!v?.enabled || isAdmin || root?.id === v?.UserId || UserId === root?.id
  );
  const TM = `TermId=${TermId}`;
  const TS = `TranslationId=${TranslationId}`;
  const qri = !!TermId ? TM : !!TranslationId ? TS : "";

  useEffect(() => {
    qri && dispatch(act_report.view_all(qri));
  }, [dispatch, qri, act_report, TermId, TranslationId]);
  const refresh = () => dispatch(act_report.view_all(qri));

  const deleteReport = async (id) => {
    const status = await dispatch(act_report.view_delete(id, qri));

    if (status) {
      refresh();
      dispatch(action.OOP("Successfully deleted Report"));
    }
  };

  if (!TermId && !TranslationId) {
    return (
      <Typography align="center" variant="h5" color="textSecondary">
        Empty Reports Found.
      </Typography>
    );
  }

  const acts = (vl) => {
    const TOC = (name, onClick) => ({ name, onClick });
    const TOT = (name, to) => ({ name, to });
    const isOwner = root?.id === vl?.UserId;
    // on Publish
    const onEnableToggler = () => {
      dispatch(act_report.view_edit(vl?.id, { enabled: !vl?.enabled }, qri));

      const msg = `Report ${!vl?.enabled ? "Dis" : "En"}abled Successfully.`;
      dispatch(action.OOP(msg));
    };

    // on delete
    const deleteReview = async () => {
      const status = await deleteReport(vl.id);
      refresh();

      if (status) dispatch(action.OOP("Successfully deleted review"));
    };

    const publishItem = TOC(`${!vl?.enabled ? "S" : "Uns"}olved`, () =>
      onEnableToggler()
    );
    const editItem = TOT("Edit", `/report-edit/${vl?.id}`);
    const deleteItem = TOC("Delete", () => deleteReview());

    const items = [];

    if (isAdmin) items.push(publishItem);
    if (isOwner) items.push(editItem);
    if (isOwner || isAdmin) items.push(deleteItem);

    return items;
  };

  return (
    <Box>
      {Hoc.isArr(data)
        .filter((vl) =>
          !!TermId
            ? TermId === vl?.TermId
            : !!TranslationId
            ? TranslationId === vl?.TranslationId
            : false
        )
        .map((vl) => (
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={9}>
              <Hoc.Typo02
                title={`${!vl?.enabled ? "(Unsolved) " : ""}${vl.full_name}`}
                content={vl.reason}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box {...Hoc.flex()}>
                <Hoc.MenuButton actions={acts(vl)} />
              </Box>
            </Grid>
          </Grid>
        ))}

      {/* add new review */}
      {!Hoc.isArr(data).some((vl) => vl?.UserId === root.id) && (
        <AddReport
          refresh={refresh}
          TermId={TermId}
          TranslationId={TranslationId}
        />
      )}
    </Box>
  );
};

// ["User", "Subscriber", "Guest Editor", "Admin", "Manager"]

export default Report;
