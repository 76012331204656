// importing neccessory redux packages
import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// importing redux reducer
import report from "./report";
// importing redux types
import report_types from "./report/types";

import bank from "./bank";
import bank_types from "./bank/types";

import review from "./review";
import review_types from "./review/types";

import template from "./template";
import template_types from "./template/types";

import term from "./term";
import term_types from "./term/types";

import translation from "./translation";
import translation_types from "./translation/types";

import user from "./user";
import user_types from "./user/types";

import plan from "./plans";
import plan_types from "./plans/types";

import footer from "./footer";
import footer_types from "./footer/types";

import OOP from "./OOP";
import { toOopMsg } from "./utils";

// Merging all reducers into one single root reducers
const reducers = combineReducers({
  // merging OOP reducer with rootReducer alongside generating a reducers by toOopMsg.
  ...toOopMsg(OOP.OOP),
  // merging report reducer with rootReducer
  report,
  bank,
  review,
  template,
  term,
  plan,
  footer,
  translation,
  user,
});

// integrating redux-thunk middleware
const middleware = [thunk];

// exporting redux types
export const Type = {
  OOP_types: OOP.OOP_types,
  bank_types,
  report_types,
  review_types,
  template_types,
  term_types,
  translation_types,
  plan_types,
  user_types,
  footer_types,
};

export default createStore(
  reducers,
  {},
  composeWithDevTools(applyMiddleware(...middleware))
);

// This file is container for Redux state manager. Here i have created a special custom function for Redux reducers which will take name, Data , and three types for Request, Success & Falure types.
// First you will create a Types file in the you will mention types for the redcers and than create a reducer file there create function which looks like below data

// export const view = {
//   name: "view",
//   state: defState,
//   request: types.TEMPLATE_VIEW_REQUEST,
//   success: types.TEMPLATE_VIEW_SUCCESS,
//   fail: types.TEMPLATE_VIEW_FAIL,
// };

// After that create an index file where you will combine the all reducers to toReducer function from utils.
// In this file import types file for backend request and index files for Redux state management.
