// Layout will have both Header & Footer which will be added to all files.
import Layout from "../src/component/Layout";

// Importing neccessory components for Routing
import { BrowserRouter as Router, Switch } from "react-router-dom";
// CssBaseline will solve some default CSS styling issue
import { CssBaseline } from "@material-ui/core";

// Screens folder cotains all pages and their sub-pages as well.
import screens from "./screens/base";

// Pages inside Screens folder
// Every file which is entered bellow are just behaves like a components which will load the complete file.
// For Instance, if put {profile.dashboard} item in the APP then it will load the dashboard page with all revilages.
import people from "./screens/people";
import term from "./screens/term";
import profile from "./screens/profile";
import footer from "./screens/footer";
import plan from "./screens/plan";
import template from "./screens/template";
import payment from "./screens/payment";

// Loading Higher-Order-Component
import Hoc from "./component/Hoc";

function App() {
  return (
    <Router>
      {/* Putting Layout component as parent for all registered routes */}
      <Layout>
        {/* CssBaseline will solve some style issues */}
        <CssBaseline />

        {/* It is the main componet which will show Success messages and Error Messages */}
        <Hoc.OOPMessage />

        {/* Switch will show only one page at once according to the URL. */}
        <Switch>
          {/* Home page for the Glossary */}
          {term.home}
          {profile.dashboard} {/* User Dashboard Page */}
          {profile.un_published}
          {profile.un_published_reports}
          {profile.created_reports}
          {profile.un_published_reviews}
          {/* Page contains all unpublished Terms, Translations, Reviews, Reports */}
          {profile.people} {/* Page containes People list  */}
          {profile.subscription}
          {/* Contains Subscription plans for subscribers */}
          {plan.list} {/* Contains Subscription plans with CRUD ations */}
          {plan.add} {/* Create Plan page */}
          {plan.edit} {/* Update Plan page */}
          {/* ******************************   Template   ****************************** */}
          {term.view_list} {/* Home page which will show all terms */}
          {term.view}
          {/* Page contains Term Details with Transaltion list in tab view */}
          {term.add} {/* Create Term page */}
          {term.edit} {/* Update Term page */}
          {term.add_trans} {/* Create Translation page */}
          {term.edit_trans} {/* Update Translation page */}
          {term.review_reports}{" "}
          {/* Page shoes Review details with list of reports which belongs to the Review */}
          {term.unpublish_terms} {/* Page contains only Unpublished Terms */}
          {/* ******************************   Translation   ****************************** */}
          {template.list} {/* Show list of Templetes */}
          {template.add} {/* Create Template page */}
          {template.edit} {/* Update Template page */}
          {template.email_setup.add} {/* Create Email settings page */}
          {template.email_setup.view} {/* Email settings details page */}
          {/* ******************************   Payments   ****************************** */}
          {payment.settings} {/* Pament settings page */}
          {payment.add} {/* Create Payment settings page */}
          {payment.edit} {/* Update Payment settings page */}
          {/* ******************************   Authentication   ****************************** */}
          {people.profile.email_confirmation}{" "}
          {/* Email Confirmation page after Registration */}
          {people.profile.forgot_password} {/* Forgot password page */}
          {people.profile.reset_password} {/* Reset Password page */}
          {people.profile.user_profile} {/* Profile Page */}
          {people.profile.edit_password} {/* Update Password page */}
          {people.profile.edit_profile} {/* Update profile Page */}
          {people.user.SignIn} {/* Login Page for User */}
          {people.user.SignUp} {/* Register Page for User */}
          {people.subscriber.SignIn} {/* Login Page for Subscriber */}
          {people.subscriber.SignUp} {/* Register Page for Subscriber */}
          {people.guest_editor.SignIn} {/* Login Page for Guest User */}
          {people.guest_editor.SignUp} {/* Register Page for Guest User */}
          {people.admin.SignIn} {/* Login Page for Admin */}
          {people.admin.SignUp} {/* Register Page for Admin */}
          {people.manager.SignIn} {/* Login Page for Manager */}
          {people.manager.SignUp} {/* Register Page for Manager */}
          {screens.review_edit} {/* Update Review Page */}
          {screens.report_add} {/* Create Review Page */}
          {screens.report_edit} {/* Update Report Page */}
          {footer.list_view} {/* Contains links for Footer data */}
          {footer.add_link} {/* Create Footer Link page */}
          {footer.edit_link} {/* Update Footer Link page */}
          {screens.not_found_404} {/* Page for `404 Page not found` */}
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
