import React, { useEffect } from "react";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Hoc from "../../component/Hoc";

import action from "../../action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Settings = () => {
  const dispatch = useDispatch();
  const act = action.bank;
  const { data, loading, error } = useSelector(({ bank: B }) => B.view_all);

  useEffect(() => {
    dispatch(act.view_all());
  }, [dispatch, act]);

  const PayPal = data?.find((v) => v?.category === "PayPal");
  const Stripe = data?.find((v) => v?.category === "Stripe");

  const P01L = { name: "Create PayPal", to: "/create-payment-detail/PayPal" };
  const P02L = { name: "Create Stripe", to: "/create-payment-detail/Stripe" };
  const P03L = { name: "Back", goBack: true };

  let actions = [P03L];
  if (!PayPal) {
    actions = [P01L, ...actions];
  }
  if (!Stripe) {
    actions = [P02L, ...actions];
  }
  const nav_01 = {
    title: "Payment Settings",
    actions,
  };
  const css = useCSS();

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Hoc.UseData loading={loading} error={error}>
        {!!PayPal && (
          <Card className={css.cardRoot}>
            <DummyCardContent data={PayPal} />
            <DummyCardActions id={PayPal?.id} />
          </Card>
        )}

        {!!Stripe && (
          <Card className={css.cardRoot}>
            <DummyCardContent data={Stripe} />
            <DummyCardActions id={Stripe?.id} />
          </Card>
        )}

        {!Stripe && !PayPal && (
          <Typography variant="h5">Please provide Payment Details</Typography>
        )}
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const DummyCardContent = ({ data }) => {
  const TextShow = ({ content, title }) =>
    !!content && <Hoc.Typo02 title={title} content={content} />;

  return (
    <CardContent>
      <Typography variant="h5">{data?.category} Details</Typography>
      <TextShow
        title="Status"
        content={data?.enabled ? "Publish" : "Unpublish"}
      />
      <TextShow title="Email" content={data?.email} />
      <TextShow title="Client ID" content={data?.client_id} />
      <TextShow title="API" content={data?.api} />
      <TextShow title="Publish Key" content={data?.publish_key} />
      <TextShow title="Secret Key" content={data?.secret_key} />
      <TextShow title="Currency" content={data?.currency} />
      <TextShow title="Secret" content={data?.secret} />
    </CardContent>
  );
};

const DummyCardActions = ({ id = null }) => {
  const dispatch = useDispatch();
  const act = action.bank;

  const H = useHistory();
  const onEdit = () => H.push(`/update-payment-detail/${id}`);
  const onDelete = () => {
    if (window.confirm("Are you sure, You want to delete the Item.") === true) {
      dispatch(act.view_delete(id));
      dispatch(act.view_all());
    }
  };

  return (
    <CardActions>
      <Button size="small" color="primary" onClick={onEdit}>
        Edit
      </Button>
      <Button size="small" color="secondary" onClick={onDelete}>
        Delete
      </Button>
    </CardActions>
  );
};

const useCSS = makeStyles((theme) => ({
  cardRoot: {
    margin: 10,
    minWidth: 500,
  },
  text: {
    overflowWrap: "break-word",
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
    borderRadius: 5,
  },
}));

export default Settings;
