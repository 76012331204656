import axios from "axios";
import Helper from "../helper";
import { Type } from "../../redux";
import { _load_trans } from "./translation";
const types = Type.term_types;

const URL = `${Helper.URL}/terms`;

const _list = async (dispatch, qri = "") => {
  try {
    dispatch({ type: types.TERM_LIST_REQUEST });
    const { data } = await axios.get(`${URL}?${qri}`);
    dispatch({
      type: types.TERM_LIST_SUCCESS,
      payload: Helper.isArr(data?.result),
    });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TERM_LIST_FAIL);
  }
};

export const view_all = (qri) => async (dispatch) => await _list(dispatch, qri);

export const _search =
  (qri = "", enabled = true, rest = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: types.TERM_SEARCH_REQUEST });
      const qry = `${URL}/search?text=${qri}&enabled=${enabled}${rest}`;
      const { data } = await axios.get(qry);

      dispatch({
        type: types.TERM_SEARCH_SUCCESS,
        payload: Helper.isArr(data?.result),
      });
      return data?.result;
    } catch (err) {
      Helper.errResponse(err, dispatch, types.TERM_SEARCH_FAIL);
    }
  };

export const view_add = (initData, qri) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TERM_ADD_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.post(URL, initData, Helper.multiForm);
    dispatch({ type: types.TERM_ADD_SUCCESS, payload: data?.result });
    await _list(dispatch, qri);
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TERM_ADD_FAIL);
  }
};

export const view = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.TERM_VIEW_REQUEST });
    const { data } = await axios.get(`${URL}/${id}`);
    dispatch({ type: types.TERM_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TERM_VIEW_FAIL);
  }
};

export const view_edit = (id, initData, qri) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TERM_EDIT_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.put(
      `${URL}/${id}`,
      initData,
      Helper.multiForm
    );
    dispatch({ type: types.TERM_EDIT_SUCCESS, payload: data?.result });
    await _list(dispatch, qri);
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TERM_EDIT_FAIL);
  }
};

export const view_delete = (id, qri) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TERM_DELETE_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.delete(`${URL}/${id}`);
    dispatch({ type: types.TERM_DELETE_SUCCESS, payload: data?.result });
    await _list(dispatch, qri);
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TERM_DELETE_FAIL);
  }
};

export const media_delete = (initData) => async (dispatch, state) => {
  try {
    dispatch({ type: types.MEDIA_DELETE_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.put(`${URL}/media`, initData);
    dispatch({ type: types.MEDIA_DELETE_SUCCESS, payload: data?.result });

    if (initData?.model_type === "Term") await _list(dispatch);
    else if (initData?.model_type === "Trans") await _load_trans(dispatch);

    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.MEDIA_DELETE_FAIL);
  }
};
