const Expo = [
  { key: "russianOld", langs: [] },
  { key: "uyghur", langs: ["Uighur / Uyghur Chinese -197"] },
  { key: "arabic", langs: ["Sudanese Arabic -298"] },
  { key: "assamese", langs: ["Assamese -174"] },
  { key: "belarusian", langs: ["Belarusian -038"] },
  { key: "bengali", langs: ["Bengali / Bangla -065"] },
  { key: "burmese", langs: ["Burmese -063"] },
  { key: "czech", langs: ["Czech -044"] },
  { key: "farsi", langs: ["Farsi -074"] },
  { key: "french", langs: ["French -007"] },
  { key: "georgian", langs: ["Georgian (R) -049"] },
  { key: "german", langs: ["German (R) -013", "Swiss German -122"] },
  { key: "gilaki", langs: [] },
  { key: "greek", langs: ["Greek , Modern -050"] },
  { key: "hebrew", langs: ["Hebrew (modern) -089"] },
  { key: "hindi", langs: ["Hindi -083"] },
  { key: "hungarian", langs: ["Hungarian -051"] },
  { key: "italian", langs: ["Italian -014"] },
  { key: "japanese", langs: ["Japanese -095"] },
  { key: "kannada", langs: ["Kannada -177"] },
  { key: "korean", langs: ["Korean -101"] },
  { key: "nigerian", langs: ["Nigerian Pidgin -142"] },
  { key: "polish", langs: ["Polish -022"] },
  { key: "russian", langs: ["Russian -039"] },
  { key: "sindhi", langs: ["Sindhi -170"] },
  { key: "swedish", langs: ["Swedish (R) -020", "Swedish (R) -020"] },
  { key: "thai", langs: ["Thai -118"] },
  { key: "turkish", langs: ["Turkish -037"] },
  { key: "ukrainian", langs: ["Ukrainian -062"] },
  { key: "urdu", langs: ["Urdu -094"] },
  {
    key: "spanish",
    langs: ["Spanish -003", "Mexican Spanish - 299", "Spanish Castilian -275"],
  },
  {
    key: "norwegian",
    langs: [
      "Norwegian Bokmål -253",
      "Norwegian Nynorsk -256",
      "Norwegian -017",
    ],
  },
  {
    key: "chinese",
    langs: [
      "Cantonese / Canthonese Chinese -128",
      "Hakha / Hakka Chinese -064",
      "Mandarin Chinese -123",
    ],
  },
];

export const RTLs = [
  "Sudanese Arabic -298",
  "Urdu -094",
  "Farsi -074",
  "Hebrew (modern) -089",
  "Sindhi -170",
  "Uighur / Uyghur Chinese -197",
];

export const CKRTL = (v) => !RTLs.includes(v);

export default Expo;

// {
//   key: "english",
//   langs: ["English -006", "Lip-speaker / Lipspeaker (English) -195"],
// },

// ****************RTL***************
// arabic="Sudanese Arabic -298",
// farsi="Farsi -074",
// gilaki="Urdu -094",
// hebrew="Hebrew (modern) -089",
// sindhi="Sindhi -170",
// urdu="Urdu -094",
// uyghur=  "Uighur / Uyghur Chinese -197",

// ****************** Available ******************
// "Sudanese Arabic -298"
// "Assamese -174"
// "Belarusian -038"
// "Bengali / Bangla -065"
// "Burmese -063"
// "Czech -044"
// "Farsi -074"
// "French -007"
// "Georgian (R) -049"
// "German (R) -013"
// "Swiss German -122"
// "Greek , Modern -050"
// "Hebrew (modern) -089"
// "Hindi -083"
// "Hungarian -051"
// "Italian -014"
// "Japanese -095"
// "Kannada -177"
// "Korean -101"
// "Nigerian Pidgin -142"
// "Polish -022"
// "Russian -039"
// "Sindhi -170"
// "Swedish (R) -020"
// "Swedish (R) -020"
// "Thai -118"
// "Turkish -037"
// "Ukrainian -062"
// "Urdu -094"
// "Spanish -003"
// "Mexican Spanish - 299"
// "Spanish Castilian -275"
// "Norwegian Bokmål -253"
// "Norwegian Nynorsk -256"
// "Norwegian -017"
// "English -006"
// "Lip-speaker / Lipspeaker (English) -195"
// "Cantonese / Canthonese Chinese -128"
// "Hakha / Hakka Chinese -064"
// "Mandarin Chinese -123"
// "Uyghur / Uyghur Chinese -197"

// ****************** Not Available ******************
// "Abkhaz -201",
// "Afar -202",
// "Afrikaans -134",
// "Akan -156",
// "Agalog -113",
// "Albanian -036",
// "Algerian (R) -161",
// "Amharic -138",
// "Aragonese -203",
// "Armenian -071",
// "Avaric -204",
// "Avestan -205",
// "Aymara -206",
// "Assyrian -047",
// "Azerbaijani (R) -207",
// "Azeri -073",
// "Bambara -208",
// "Balinese -130",
// "Balochi -179",
// "Bantu -150",
// "Bashkir -209",
// "Basque (R) -001",
// "Bajuni -032",
// "Bari -026",
// "Berber -136",
// "Bihari -210",
// "Bilen -152",
// "Bislama -211",
// "Bosnian (R) -040",
// "Bodo -173",
// "Breton -212",
// "Bravanese -162",
// "Brahui -182",
// "Bulgarian -041",
// "Catalan Valencian-002",
// "Chamorro -213",
// "Chao-chow - 119",
// "Chavacano -108",
// "Chechen -214",
// "Chichewa Chewa Nyanja -215",
// "Chin -066",
// "Chuvash -216",
// "Cornish -217",
// "Corsican -218",
// "Cree -219",
// "Croatian -042",
// "Danish -004",
// "Dari -076",
// "Dioula -233",
// "Divehi Maldivian -220",
// "Dinka -024",
// "Dogri -176",
// "Dutch -005",
// "Edo -140",
// "Esperanto -221",
// "Estonian -045",
// "Ewe -155",
// "Faroese -292",
// "Fante -157",
// "Fijian -222",
// "Finnish -019",
// "Flemish -249",
// "Fula / Fulah -096",
// "Fuzhou -120",
// "Pulaar / Pular - -097",
// "Ga -159",
// "Garre -163",
// "Galician -223",
// "Gaddang -109",
// "Guaraní -224",
// "Gurmukhi -082",
// "Gujari -181",
// "Gujarati -087",
// "Haitian Creole -023",
// "Hassaniyya -137",
// "Hausa -008",
// "Herero -225",
// "Hindko -072",
// "Hiri Motu -226",
// "Hokkien - 099",
// "Hmong -105",
// "Hmong–Mien -121",
// "Ibanag -110",
// "Ilocano -111",
// "Interlingua -227",
// "Indonesian / Bahasa Indo.-129",
// "Interlingue -228",
// "Irish - Gaelic -011",
// "Igbo (R) -141",
// "Inupiaq -229",
// "Ido -230",
// "Icelandic -053",
// "Inuktitut -231",
// "Jamaican Patois - 297",
// "Javanese / Jakartanese -131",
// "Kalaallisut , Greenlandic -232",
// "Kanuri -234",
// "Kashmiri -167",
// "Kazakh -235",
// "Khmer -100",
// "Kibajuni (R) -295",
// "Kikuyu , Gikuyu -293",
// "Kikongo -147",
// "Kinyarwanda (R) -144",
// "Kirghiz , Kyrgyz -236",
// "Kirundi -151",
// "Kisii -031",
// "Komi -237",
// "Kongo -238",
// "Konkani -088",
// "Kotokoli (Tem) -009",
// "Kurdish Gorani -079",
// "Kurdish Kurmanji -080",
// "Kurdish Sorani -081",
// "Kurdish Bahdini / Badini -102",
// "Kurdish Feyli - 103",
// "Kwanyama , Kuanyama -239",
// "Latin -240",
// "Luxembourgish , Letzeburgesch -241",
// "Luganda (R) -242",
// "Luo (Dholuo) -032",
// "Lutora -166",
// "Limburgish , Limburgan , Limburger -243",
// "Lingala (R) -146",
// "Lao -106",
// "Lithuanian -055",
// "Luba-Katanga -244",
// "Latvian -054",
// "Manx -245",
// "Macedonian -056",
// "Maithili -168",
// "Malagasy -246",
// "Malay -198",
// "Malayalam (R) -247",
// "Maltese -057",
// "Malinke/ Maninka/ Mandinka/ Manding -028",
// "Māori -248",
// "Marathi (Marāṭhī) -169",
// "Marshallese -250",
// "Mauritian-Creole -164",
// "Mongolian -104",
// "Monipuri -068",
// "Moldovan -058",
// "Moroccan (R) -160",
// "Namlish -135",
// "Nauru -251",
// "Navajo , Navaho -252",
// "North Ndebele -254",
// "Ndebele -148",
// "Nepali -091",
// "Neapolitan -015",
// "Neo-Aramaic -048",
// "Ndonga -255",
// "Northern Sami -257",
// "Nuer -025",
// "Nuosu -258",
// "South Ndebele -259",
// "Occitan -260",
// "Odia -178",
// "Ojibwe , Ojibwa -261",
// "Old Slavonic , Church Slavic , Old Bulgarian -262",
// "Oromo (R) -034",
// "Oriya -263",
// "Ossetian , Ossetic -264",
// "Pahari -098",
// "Pangasinan -112",
// "Papiamento -165",
// "Panjabi / Punjabi (eastern) India -084",
// "Pashto / Pushto -075",
// "Punjabi (western) Pakistan -265",
// "Punjabi Mirpuri - 093",
// "Pāli -266",
// "Persian -267",
// "Portuguese -018",
// "Pothwari -175",
// "Putian -124",
// "Quechua -268",
// "Romansh -269",
// "Romanian -059",
// "Roma / Romani (Traveler Community) -296",
// "Rohingya (R) , Myanmar -069",
// "Sanskrit (Saṁskṛta) -171",
// "Santali -172",
// "Sardinian -270",
// "Saraiki -180",
// "Samoan -271",
// "Sango -272",
// "Serbian -061",
// "Serbo-Croatian -043",
// "Seychelles-Creole -046",
// "Krio / Sierra Leonean Creole -294",
// "Scottish Gaelic / Gaelic -184",
// "Shona -149",
// "Shina -183",
// "Shanghainese -125",
// "Sinhala , Sinhalese (R) -107",
// "Sicilian -016",
// "Sichuan -126",
// "Slovak -052",
// "Slovene -273",
// "Somali -139",
// "Soninke -029",
// "Southern Sotho -274",
// "Sundanese -276",
// "Susu -132",
// "Sylheti / Sylheti Nagri / Syloti (R) -067",
// "Swahili -035",
// "Swati -278",
// "Tamil -085",
// "Temne -277",
// "Telugu (R) -086",
// "Tetum -133",
// "Tajik -116",
// "Tigre -153",
// "Tigrinya -154",
// "Tibetan Standard , Tibetan -092",
// "Turkmen -279",
// "Tagalog  -280",
// "Tagalok - 199",
// "Tswana -281",
// "Tonga (Tonga Islands) -282",
// "Toishanese -127",
// "Tsonga -283",
// "Tshiluba -145",
// "Tatar -284",
// "Twi (R) -158",
// "Tahitian -285",
// "Uzbek -115",
// "Venda -286",
// "Vietnamese -117",
// "Visayan -114",
// "Volapük -287",
// "Walloon -288",
// "Welsh -021",
// "Wolof (R) -030",
// "Western Frisian -289",
// "Xhosa -290",
// "Yiddish -090",
// "Yoruba (R) -010",
// "Zaghawa (R) -143",
// "Zande -027",
// "Zhuang / Chuang -291",
// "BSL (British Sigh Language) -185",
// "ASL (American Sign Language) -186",
// "SSE (Special Needs Interpreting) -187",
// "DBI (Deaf Blind Interpreters / communicator) -188",
// "ISL (international Sign Language Interpreter) -189",
// "CSW (Communication Support Worker) -190",
// "Note-Takers Manual -191",
// "Note-Takers Electronic -192",
// "DRI (Deaf Relay Interpreter) -193",
// "Palantypists / STTR -194",
// "TSLI (Trainee Sign Language Interpreter (of BSL/?)) -196",
// "Other -200"
