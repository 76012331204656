import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export default function SwitchLabels(props) {
  const { name = "", label = "", color } = props;
  const { checked, onChange = (f) => f, disabled } = props;

  const handleChange = (name, value) => ({ target: { name, value } });

  return (
    <FormControlLabel
      control={
        <Switch
          disabled={disabled ? disabled : false}
          checked={!!checked}
          onChange={({ target: v }) =>
            onChange(handleChange(v.name, v.checked))
          }
          name={name}
          color={color || "primary"}
        />
      }
      label={label}
    />
  );
}
