import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Container, Grid } from "@material-ui/core";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Hoc from "../../../component/Hoc";
import { Box } from "@material-ui/core";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Amina from "../../../component/Hoc/Amina";
import ControlsSwitch from "../../../component/Hoc/Controls/Switch";
import action from "../../../action";
import { useDispatch, useSelector } from "react-redux";
import { CKRTL } from "../../../assets/layouts";

const BaseTerm = ({ term, refresh }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const root = useSelector(({ user: T }) => T.login.data);

  const [showMedia, setShowMedia] = useState(false);
  const H = useHistory();
  const TermId = term?.TermId;
  const editUrl = `/edit-${!TermId ? "term" : "translation"}/${term?.id}`;
  const dispatch = useDispatch();
  const act_term = action.term;

  const act_trans = action.translation;

  function voteIncrement() {
    let view = new FormData();
    view.set("vote", term?.vote + 1);

    if (!TermId) dispatch(act_term.view_edit(term?.id, view));
    else dispatch(act_trans.view_edit(term?.id, view, `TermId=${TermId}`));

    refresh();
  }

  function voteDecrement() {
    let view = new FormData();
    view.set("vote", 1 > term?.vote ? 0 : term?.vote - 1);
    if (!TermId) dispatch(act_term.view_edit(term?.id, view));
    else dispatch(act_trans.view_edit(term?.id, view, `TermId=${TermId}`));
    refresh();
  }

  const CKR = (v) => v === root?.role;
  const isAdmin = !!(
    CKR("Manager") || !!(CKR("Admin") && !!root?.admin_status)
  );

  const canEdit = isAdmin ? true : root?.id === term?.UserId;

  const enableToggle = () => {
    if (
      window.confirm("Are you sure, You want to change the Status.") === true
    ) {
      let view = new FormData();
      view.set("enabled", !term?.enabled);

      if (!TermId) dispatch(act_term.view_edit(term?.id, view));
      else dispatch(act_trans.view_edit(term?.id, view, `TermId=${TermId}`));

      refresh();

      dispatch(action.OOP(`Status Changed Successfully.`));
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure, You want to delete.") === true) {
      if (!TermId) dispatch(act_term.view_delete(term?.id));
      else dispatch(act_trans.view_delete(term?.id, `TermId=${TermId}`));
      refresh();
    }
  };

  const CustomBtn = ({ content = "Update", color = "primary", ...rest }) => (
    <Button variant="contained" size="small" color={color} {...rest}>
      {content}
    </Button>
  );

  return (
    <Container maxWidth="lg" style={{ marginTop: 10 }}>
      {canEdit && (
        <Box {...Hoc.flex("flex-end")}>
          {!!isAdmin && (
            <ControlsSwitch
              checked={!!term?.enabled}
              label="Publushed"
              onChange={enableToggle}
            />
          )}

          {root?.id === term?.UserId && (
            <>
              <Hoc.Typo02
                title="Status"
                content={`${!term?.enabled ? "Dis" : "En"}abled`}
              />
              <div style={{ marginRight: 10 }} />
            </>
          )}

          {canEdit && (
            <CustomBtn
              onClick={onDelete}
              content="Delete"
              color={"secondary"}
              style={{ marginRight: 10 }}
            />
          )}

          <CustomBtn onClick={() => H.push(editUrl)} />
        </Box>
      )}

      <Box width="100%" {...Hoc.flex("flex-start")}>
        <Box {...Hoc.flex()} flexDirection="column">
          <VoteIcon Icon={ArrowDropUp} onClick={voteIncrement} />
          <Hoc.Typo content={term?.vote || "0"} variant="h5" align="center" />
          <VoteIcon Icon={ArrowDropDown} onClick={voteDecrement} />
        </Box>
      </Box>

      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Hoc.Typo content={term?.word} variant="h3" align="center" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Hoc.Typo02 title="Language" content={term?.tongue} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Hoc.Typo02 title="Country" content={term?.country} />
        </Grid>
        <Grid item xs={12}>
          <Hoc.Typo02
            title="Description"
            content={term?.text}
            rtl={!CKRTL(term?.tongue)}
          />
        </Grid>

        {!!(showMedia || !isMobile) && (
          <>
            <FileContent audio file={term?.audio} show={showMedia}>
              <audio controls src={GetUrl(term?.audio, true)} width="100%" />
            </FileContent>
            <FileContent file={term?.photo} show={showMedia}>
              <Box {...TRIM} src={term?.photo} />
            </FileContent>
            <FileContent file={term?.video} show={showMedia}>
              <video {...video_setup(term?.video)} />
            </FileContent>
          </>
        )}
        {isMobile && !!(term?.photo || term?.audio || term?.video) && (
          <Grid item xs={12}>
            <Box {...Hoc.flex("flex-end")}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setShowMedia(!showMedia)}
                color={!showMedia ? "primary" : "secondary"}
              >
                {!showMedia ? "Show" : "Hide"} Media
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      <Amina.CommentsAndReviewTab
        UserId={term?.UserId}
        {...(!TermId ? { TermId: term?.id } : { TranslationId: term?.id })}
      />
    </Container>
  );
};

const VoteIcon = ({ Icon, onClick = (f) => f }) => (
  <Icon fontSize="large" style={{ cursor: "pointer" }} onClick={onClick} />
);

const FileContent = ({ children, file, audio = false }) =>
  !!file && (
    <Grid
      item
      xs={12}
      {...(!audio && { md: 6 })}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Box {...Hoc.flex()}>{children}</Box>
    </Grid>
  );

const GetUrl = (v, ad) =>
  `${process.env.REACT_APP_API}/${!ad ? "video" : "audio"}/${v}`;

const video_setup = (src) => ({
  controls: true,
  className: "rounded mx-auto d-block",
  style: { width: "100%", maxHeight: 250 },
  src: GetUrl(src),
});

const TRIM = {
  component: "img",
  className: "img-fluid rounded",
  alt: "doctor img",
};

export default BaseTerm;
