import React from "react";
import { Grid } from "@material-ui/core";

import OneCard from "./OneCard";

const ThreeCards = ({ data }) => {
  return (
    <Grid container justifyContent="center">
      {data?.map((v, idx) => (
        <OneCard key={idx} {...v} />
      ))}
    </Grid>
  );
};

export default ThreeCards;
