import React from "react";
import Hoc from "../../Hoc";
import { Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const DashboardItem = (props) => {
  const H = useHistory();

  const { title, content, actions } = props;

  return (
    <Hoc.Dashboard.Item title={title}>
      <Typography variant="body2" paragraph>
        {content}
      </Typography>

      <Box display="flex">
        {Hoc.isArr(actions)?.map((v, idx) => (
          <Hoc.BtnContained
            key={idx}
            content={v?.name}
            fullWidth
            style={{ margin: "0 2px" }}
            onClick={() => H.push(v?.to)}
          />
        ))}
      </Box>
    </Hoc.Dashboard.Item>
  );
};

const TOT = (name, to, status = false) => ({ name, to, status });

const People = () => (
  <DashboardItem
    title="People"
    content="Able to view People."
    actions={[TOT("View", "/people-view")]}
  />
);

const Subscriber = () => (
  <DashboardItem
    title="Subscriptions"
    content="Subscription details."
    actions={[TOT("View", "/subscription-view")]}
  />
);

const Subscriptions = () => (
  <DashboardItem
    title="Subscription Plans"
    content="Subscription plan details."
    actions={[TOT("View", "/plan-list")]}
  />
);

const PayMethods = () => (
  <DashboardItem
    title="Payment Options"
    content="Manage Paymentdetails here."
    actions={[TOT("View", "/payment-settings")]}
  />
);

// TOT("Settings", "/paypal-view"),
const EmailTemplate = () => (
  <DashboardItem
    title="Email Setup"
    content="Manage Email setup here."
    actions={[
      TOT("Email Settings", "/email-setup"),
      TOT("Template", "/template"),
    ]}
  />
);

const UnPublished = () => (
  <DashboardItem
    title="Unpublished Items"
    content="Manage Unpublished Items here."
    actions={[TOT("View", "/unpublished-items")]}
  />
);

const UnPublishedReports = () => (
  <DashboardItem
    title="Unsolved Reports"
    content="Manage Unsolved Reports here."
    actions={[TOT("View", "/unpublished-report-items")]}
  />
);

const CreatedReports = () => (
  <DashboardItem
    title="My Reports"
    content="Manage My Reports here."
    actions={[TOT("View", "/created-report-items")]}
  />
);

const UnPublishedReviews = () => (
  <DashboardItem
    title="Unpublished Reviews"
    content="Manage Unpublished Reviews here."
    actions={[TOT("View", "/unpublished-review-items")]}
  />
);

const FooterLink = () => (
  <DashboardItem
    title="Footer Links"
    content="Manage Footer Links here."
    actions={[TOT("View", "/footer-link-list")]}
  />
);

const Expo = {
  UnPublished,
  UnPublishedReports,
  UnPublishedReviews,
  CreatedReports,
  PayMethods,
  People,
  Subscriber,
  Subscriptions,
  EmailTemplate,
  FooterLink,
};

export default Expo;
