import React from "react";
import Hoc from "../component/Hoc";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

// This component provides security to the page by verifying the previlages before loading the page.

const SecureRoute = ({ grant, exact = true, ...rest }) => {
  // Loading currently logged in user data
  const root = useSelector(({ user: P }) => P.login.data);
  // loading user role
  const role = root?.role;
  // loading whether the user has subscribed
  const subscription = root?.subscription;

  // checking whether current user role matches the requested one
  const RRT = (RL) => Hoc.isArr(RL).includes(role);

  // lists of roles with previlages
  const rules = [
    { id: "Manager", rule: RRT(P1) }, // "Manager"
    { id: "Admin", rule: RRT(P2) }, // "Manager", "Admin"
    { id: "Emp", rule: RRT(P3) }, // "Manager", "Admin", "Subscriber", "Guest Editor"
    { id: "User", rule: RRT(P4) }, // "Manager", "Admin", "Subscriber", "Guest Editor", "User"
    { id: "Guest", rule: RRT(P3GS) }, // "Manager", "Admin","Guest Editor"
    { id: "EmpSub", rule: RRT(P3SB) }, // "Manager", "Admin","Subscriber"
    { id: "EmpSub+", rule: !!(RRT(P3SB) && !!subscription) }, // "Manager", "Admin","Subscriber" With Subscription
  ];

  // checking whether given role is allowed or not
  const CKR = (RL) => {
    const rule = rules.find((i) => i.id === RL);
    return !!rule?.rule;
  };

  // Loading success route
  const RT = <Route exact={exact} {...rest} />;
  // loading loding route for failure
  const RDCT = <Redirect to="/login" />;

  // logic for if user matches the role load success route otherwise login route.
  return Hoc.isArr(grant).some((v) => !!CKR(v)) ? RT : RDCT;
};

export default SecureRoute;

const P1 = ["Manager"];
const P2 = [...P1, "Admin"];
const P3 = [...P2, "Subscriber", "Guest Editor"];
const P4 = [...P3, "User"];

const P3SB = [...P2, "Subscriber"];
const P3GS = [...P2, "Guest Editor"];
