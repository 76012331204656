import React, { useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import TermListDesktop from "./Hoc/TermListDesktop";
import TermListMobile from "./Hoc/TermListMobile";
import { useDispatch, useSelector } from "react-redux";
import action from "../../action";

const TermList = () => {
  const act_search = action.term._search;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const data = useSelector(({ term: T }) => T._search.data);
  const [searchTxt, setSearchTxt] = useState("");

  useEffect(() => {
    dispatch(act_search(searchTxt, true));
  }, [dispatch, act_search, searchTxt]);

  const refresh = () => dispatch(act_search(searchTxt, true));

  const mobProps = {
    searchTxt,
    setSearchTxt,
    refresh,
    data,
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <TermListMobile {...mobProps} />
      ) : (
        <TermListDesktop refresh={refresh} data={data} />
      )}
    </React.Fragment>
  );
};

export default TermList;
