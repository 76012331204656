import React, { useEffect } from "react";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "../../Hoc";
import action from "../../../action";
import { Box, Button } from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const StripeForm = (props) => {
  const H = useHistory();
  const dispatch = useDispatch();
  const act_add_subscription = action.user.add_subscription;
  const { amount = 0, PlanId } = props;

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const initData = { type: "card", card: elements.getElement(CardElement) };
    const { error, paymentMethod } = await stripe.createPaymentMethod(initData);

    if (!error && !!paymentMethod?.id) {
      try {
        dispatch(act_add_subscription({ PlanId }));
        H.push("/dashboard");
        const msg =
          "Congrats, We have successfully extended your subscription data.";
        dispatch(action.OOP(msg));
      } catch (err) {
        console.log(`${err}`);
      }
    } else {
      console.log(`${error?.message || error}`);
    }
    setLoading(false);
  };

  return (
    <Box>
      <Box style={{ border: "1px solid #c4f0ff", padding: 10, width: "100%" }}>
        <CardElement options={options} />
      </Box>
      <Box style={{ margin: "15px 0" }} {...Hoc.flex("flex-end")}>
        <Button
          onClick={onSubmit}
          disabled={!!loading}
          variant="contained"
          color="primary"
        >
          {!!loading ? "Loading..." : `Pay ${amount}`}
        </Button>
      </Box>
    </Box>
  );
};

const StripePayment = (props) => {
  const dispatch = useDispatch();

  const act_pay = action.bank.method;
  const PData = useSelector(({ bank: b }) => b.method);

  useEffect(() => {
    dispatch(act_pay("Stripe"));
  }, [dispatch, act_pay]);

  const { data, loading, error } = PData;
  const stripePromise = loadStripe(`${data?.publish_key}`);

  return (
    <Hoc.UseData loading={loading} error={error}>
      {!data?.publish_key ? (
        <Hoc.Typo
          align="center"
          color="secondary"
          variant="h5"
          content="Stripe not configured yet!"
        />
      ) : (
        <Elements stripe={stripePromise}>
          <StripeForm {...props} />
        </Elements>
      )}
    </Hoc.UseData>
  );
};

const options = {
  hidePostalCode: true,
};

export default StripePayment;
