import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import action from "../../../../action";
import Hoc from "../../../Hoc";
import Controls from "../../Controls";
import { Box, Button } from "@material-ui/core";

const AddReport = ({ TermId, TranslationId, refresh }) => {
  const [rvToggle, setRvToggle] = useState(false);

  const H = useHistory();
  const dispatch = useDispatch();
  const act_report = action.report;
  const { Form, useForm } = Hoc.useForm;
  const { vals, setVals, onChange, resetForm } = useForm(initData());

  const onSubmit = async () => {
    const finalData = { ...vals, TermId, TranslationId };
    const status = await dispatch(act_report.view_add(finalData));

    if (status) {
      refresh();
      dispatch(action.OOP("Report submitted successfully."));
      setVals(initData());
      H.push("/glossary");
    }
  };

  return (
    <Box>
      <Box {...Hoc.flex("flex-end")}>
        <Button
          variant="contained"
          onClick={() => setRvToggle(!rvToggle)}
          color={!rvToggle ? "primary" : "secondary"}
        >
          {!rvToggle ? "Add Report" : "cancel"}
        </Button>
      </Box>
      {rvToggle && (
        <Form
          onSubmit={onSubmit}
          resetForm={resetForm}
          submitButton={{ disabled: !!CKF01(vals) }}
        >
          <Controls.Input {...setup.full_name(vals?.full_name, onChange)} />
          <Controls.Input {...setup.email(vals?.email, onChange)} />
          <Controls.Input {...setup.reason(vals?.reason, onChange)} />
          <Controls.PhoneInput {...setup.contact(vals?.contact, onChange)} />
        </Form>
      )}
    </Box>
  );
};
const CKF01 = (v) => !v?.full_name || !v?.email || !v?.reason;

const setup = {
  full_name: (N, OC) => Hoc.setupText(N, OC, "full_name", "Full Name"),
  email: (v, ON) => Hoc.setupText(v, ON, "email", "Email", "email"),
  reason: (N, OC) => Hoc.setupLongText(N, OC, "reason", "Reason"),
  contact: (v, ON) =>
    Hoc.setupText(v, ON, "contact", "Contact Number", "phone"),
};

const initData = (v) => ({
  full_name: v?.full_name ? v?.full_name : null,
  email: v?.email ? v?.email : null,
  reason: v?.reason ? v?.reason : null,
  contact: v?.contact ? v?.contact : null,
});

export default AddReport;
