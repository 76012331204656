import { Box, Grid } from "@material-ui/core";
import { Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import React, { useEffect } from "react";
import moment from "moment";
import action from "../../action";
import Hoc from "../../component/Hoc";
import { useDispatch, useSelector } from "react-redux";
import ThreeCards from "./ThreeCards";

const Footer = () => {
  const CSS = useCSS();
  const Act_footer = action.footer;

  const dispatch = useDispatch();
  let data = useSelector(({ footer: T }) => T.list.data);

  data = Hoc.isArr(data).map(({ url, ...rest }) => ({
    url: `${url}`.includes("://") ? url : `https://${url}`,
    ...rest,
  }));
  const sclinks = data?.filter((v) => v?.category === "Social Link");
  const nmlinks = data?.filter((v) => v?.category === "Normal Link");

  useEffect(() => {
    dispatch(Act_footer.view_all());
  }, [dispatch, Act_footer]);

  return (
    <React.Fragment>
      <Box className={clsx(CSS.footer, "primary-gradient")}>
        <ThreeCards data={nmlinks} />
        <Grid className={CSS.grid_root} container spacing={3}>
          <Grid {...gridItem}>
            <RouterLink to="/glossary">
              <Box>
                <Box component="img" src="/logo.png" height={70} />
              </Box>

              <Typography
                variant="h6"
                color="textPrimary"
                style={{ cursor: "pointer" }}
              >
                Linguist Glossary
              </Typography>
            </RouterLink>
          </Grid>
          {!!sclinks?.length && (
            <Grid {...gridItem}>
              <FooterHead text="Social Media">
                {sclinks?.map((v) => (
                  <LinkTarget key={v.id} href={v.url} content={v.name} />
                ))}
              </FooterHead>
            </Grid>
          )}

          <Grid {...gridItem}>
            <FooterHead text="Company">
              <LinkTo text="Home" to="/" />
              <LinkTo text="My Reports" to="/created-report-items" />
              <LinkTo text="Guest Login" to="/guest-login" />
            </FooterHead>
          </Grid>
          <Grid {...gridItem}>
            <FooterHead text="Explore">
              <LinkTarget
                content="About US"
                href="https://linguistscollective.com/landing-page/about"
              />
              <LinkTarget
                content="Contact US"
                href="https://linguistscollective.com/landing-page/contact"
              />
              <LinkTarget
                content="Terms and Conditions"
                href="https://linguistscollective.com/landing-page/privacy-policy"
              />
            </FooterHead>
          </Grid>
        </Grid>
      </Box>
      <Box className={CSS.signature}>
        <Typography variant="body2" color="textSecondary">
          &copy; Linguists Collective (LC) {moment().format("YYYY")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Builder : {"{"}
          <LinkTarget
            content="CITC"
            href="https://cambridgeitconsultancy.co.uk"
            noColor
          />
          {"}"}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

const LinkTarget = ({ href, content, noColor = false }) => (
  <Link target="_blank" href={href}>
    <span {...(!noColor && { style: { color: "white" } })}>{content}</span>
  </Link>
);

const gridItem = {
  item: true,
  xs: 12,
  sm: 6,
  md: 3,
  component: Box,
  display: "flex",
  flexDirection: "column",
  align: "center",
};

const FooterHead = ({ text, children, Mtop }) => (
  <Box {...(Mtop && { style: { marginTop: 10 } })}>
    <Typography
      variant="h6"
      color="textPrimary"
      style={{
        fontSize: 20,
        textDecoration: "underline",
        textUnderlineOffset: 5,
        marginBottom: 5,
      }}
    >
      {text}
    </Typography>
    <Box display="flex" flexDirection="column">
      {children}
    </Box>
  </Box>
);

const LinkTo = ({ text, to, onClick }) => (
  <Link
    {...(!onClick
      ? { component: RouterLink, to: to }
      : { onClick, style: { cursor: "pointer" } })}
    className={CSS.to_link}
  >
    <span style={{ color: "white" }}>{text}</span>
  </Link>
);

const useCSS = makeStyles((theme) => ({
  gapOnMd: {
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 0),
    },
  },
  grid_root: {
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(5, 0),
  },
  to_link: {
    fontSize: 14,
    marginTop: theme.spacing(0.3),
  },
  footer: {
    padding: theme.spacing(2, 1),
  },
  footer_main: {
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  footer_links: {
    "& .MuiLink-root": {
      fontSize: 10,
      marginLeft: 5,
      marginRight: 5,
      cursor: "pointer",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  signature: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontWeight: 400,
    padding: theme.spacing(1.5),
  },
}));

export default Footer;
