import React from "react";
import { TextField } from "@material-ui/core";
import moment from "moment";

const DatePicker = (props) => {
  const { name, label, type = "date" } = props;
  const { value, onChange } = props;

  return (
    <TextField
      type={type}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      inputProps={inputProps}
    />
  );
};

export default DatePicker;

const inputProps = {
  min: moment().format("YYYY-MM-DD"),
};
