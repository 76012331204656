// Importing all the Higher-Order-Components from other resourses
import { Term404 } from "./base/base";
import { Typography, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuButton from "./base/MenuButton";
import OOPMessage from "./base/OOPMessage";
import PageBox from "./base/PageBox";
import UseData from "./base/UseData";
import country from "../../assets/country.json";
import language from "../../assets/language.json";
import FormContainer from "./base/FormContainer";
import Dashboard from "./Sub/Dashboard";
import ShowItem from "./Sub/ShowItem";
import useForm from "./base/useForm";
import ActionButtons from "./base02/ActionButtons";
import React from "react";

// creating flex style (<Box {...Hoc.flex('space-between')} >content</Box>)
const flex = (jc = "center", ai = "center", fd) => ({
  display: "flex",
  justifyContent: jc,
  alignItems: ai,
  ...(!!fd && { flexDirection: fd }),
});

// Styles for Typography version 2
const useCSSTypo02 = makeStyles((theme) => ({
  text: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(0.5, 1.5),
    margin: theme.spacing(0.3, 0.2),
    borderRadius: 5,
  },
  textTitle: {
    display: "inline-block",
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(0.5, 2),
    borderRadius: 5,
    marginRight: theme.spacing(2),
  },
  textContent: {
    display: "inline",
    overflowWrap: "break-word",
  },
}));

// Custom Typography which simplifies the code
const Typo = ({ content, children, ...rest }) => (
  <Typography style={{ overflowWrap: "break-word" }} {...rest}>
    {content}
    {children}
  </Typography>
);

// Advanced Custom Typography version. For this Typ02 you can also provide title and content
// If content is empty then it will not show the component in the page
const Typo02 = ({ content, children, title, rtl }) => {
  const css = useCSSTypo02();
  // Logic: if content is empty then it gives simple <span /> otherwise it returns the remaining element.
  return !content ? (
    <span />
  ) : (
    <Box className={css.text}>
      {/* rtl (Right to Left): If `rtl` is true then it will show the text from right side to Left side (Arabic language style) otherwise 
        it will show as normal style
      */}
      {!rtl ? (
        <React.Fragment>
          {/* If title exists then it will show the title othewise it hides the title. */}
          {title && <Typo className={css.textTitle} content={title} />}
          <Typo content={content} className={css.textContent} />
          {/* Placing the children content here */}
          {children}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* If title exists then it will show the title othewise it hides the title. */}
          {title && (
            <Box width="100%" {...flex("flex-start")}>
              <Typo content={title} className={css.textTitle} />
            </Box>
          )}
          {/* Putting the text from Right to Left as Arabic text style. */}
          <Box width="100%" {...flex("flex-end")}>
            <Typo
              content={content}
              style={{ direction: "rtl" }} // This style makes text from right to left side
              align="right" // Alligning the text on right side
            />
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

// When we got empty data we will use this component which simply returns an Empty message.
const EmptyResource = () => (
  <Box width="100%" {...flex()}>
    <Typo
      variant="h5"
      color="textSecondary"
      align="center"
      content="Empty Resource Found Here."
    />
  </Box>
);

// If the value is an array then it will return the value otherwise it will returns an empty array
const isArr = (v) => (Array.isArray(v) ? v : []);

// It take currency name & amount as inputs and returns the string in the currency format
const toCurrency = (currency, amount) =>
  amount
    ? amount?.toLocaleString(currency, {
        style: "currency",
        currency,
      })
    : 0;

// It takes string & and an array and after that it extracts all string values and searches for the string vlue and returns the matched items
// Simply it is a filter function for array
const onSearchText = (Value, VL) =>
  isArr(VL)?.filter((x0) =>
    Object.entries(x0)
      .filter((x1) => typeof x1[1] === "string")
      .some((x2) => `${x2[1]}`.toLowerCase()?.includes(Value))
  );

const Expo = {
  // Message to show when data is not available
  EmptyResource,
  // It will return two compoenets Form  & useForm
  // Form - It is <form /> element with some custom styles
  // useForm - Takes initial data and returns values & other funsctionalities to manage that data & form
  useForm,
  // It has two components which almost do the same thing with slight changes
  // It is a container which takes title, actions & children
  // title - It will be shown in the conatiner header
  // actions - Here you will have to provide an array with some values like name, to, goBack & onClick
  //          name - action name
  //          to - If you put link then the action will work like a navigation and place the link in the to parameter
  //          goBack - If you put `goBack:true` value then it will returns to the previous page when you click on the action link
  //          onClick - It works like a normal onClick handler
  //          children - Everything you put inside element is children
  Dashboard,
  // It is a simple message container which takes both show the content with the title. It is used like alert message with purple colo
  ShowItem,
  // This component will be used as the wrapper for the Form and it contains logo, title with GoBack button as well.
  FormContainer,
  // This component used to render multiple actions (like, multiple onClick handlers).
  ActionButtons,
  // When Terms has xero record then this will be shown
  Term404,
  // This is mainly used to show multiple actions in a menu like structure. Suppose in the table when you will click on the
  // action button then you will see multiple actions like Create, Update, Delete something like that.
  MenuButton,
  // This component placed in the main Root page above the react-router-dom's Switch component and whenever a success messager or
  // an error has raised then it will show that Error/Success message as Popup (in model style view).
  OOPMessage,
  // It will take two items Error & Loading. When both are falsy values then it will return the children content otherwise
  // it will returns the Error/Loading message.
  UseData,
  // This component is created for title of the page. when you use this PageBox then it will render title in the title tag which may helpful for SEO
  PageBox,
  // check that whether the value is an array or not. If not then it will returns the empty array otherwise it will returns the value
  isArr,
  // converts the number as currency format string
  toCurrency,
  // it returns default flex styles
  flex,
  // Every items which prefixed with setup (setupSwitch, setupText, ...etc) is a helper component
  // which will take some common values and generates an entire elements values. For example
  //  const emailSetup = (email, onChange) setupText(email, onChange, 'email', 'Enter Email', 'email')
  // <input {...emailSetup(emailValue, onEmailChange)} />
  // if you notice the above two lines of code you will see i have put input element configuartion with one single function
  // Advantages of this function is you want to make any changes for multiple items you don't need to go for eahh and
  // every one. Less code.
  setupSwitch: (checked, onChange, name, label) => ({
    checked,
    onChange,
    name,
    label,
  }),
  setupText: (v, onChange, name, label, type = "text") => ({
    onChange,
    name,
    label,
    value: !!v ? v : "",
    type,
  }),
  setupSelect: (v, onChange, name, label = "Select Country", opt = "C") => ({
    onChange,
    name,
    label,
    value: !!v ? v : "",
    options: opt === "C" ? country : opt === "L" ? language : opt,
    ...(["C", "L"].includes(opt) && { noLabel: true }),
  }),
  setupAutoSelect: (
    v,
    onChange,
    name,
    label = "Select Country",
    opt = "C"
  ) => ({
    onChange,
    name,
    label,
    value: !!v ? v : "",
    options: opt === "C" ? country : opt === "L" ? language : opt,
  }),
  setupLongText: (v, onChange, name, label, minRows = 2, maxRows = 4) => ({
    type: "text",
    name,
    label,
    multiline: true,
    minRows,
    maxRows,
    value: v ? v : "",
    onChange,
    style: { borderRadius: 5 },
  }),
  setupFile: (v, onChange, name, LB, accept) => ({
    type: "file",
    id: `photo_${name}`,
    name,
    label: LB ? `${!!v ? "Edit" : "Select"} ${LB}` : "File",
    accept: !accept ? "*" : accept,
    onChange,
  }),
  // declaring both version 1 and 2 of Typography compoenets here
  Typo,
  Typo02,
  // creating custom button with default styles
  BtnContained: ({ content, children, ...rest }) => (
    <Button variant="contained" size="small" {...rest}>
      {content}
      {children}
    </Button>
  ),
  // Componets filters ietms from an array by given string value
  onSearchText,
  // This componets contains two functions which will be used for searching items from table data (useTable)
  onSearch: {
    // it will be placed inside useState hook
    action: { fn: (F) => F },
    // it will be used as onChange hanger for search input element
    search: (Value, setFilterFn) =>
      setFilterFn({
        fn: (VL) => (Value === "" ? VL : onSearchText(Value, VL)),
      }),
  },
};

export default Expo;
