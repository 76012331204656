import React, { useEffect } from "react";
import Hoc from "../../component/Hoc";
import action from "../../action";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TransList from "./Hoc/TransList";

const SingleTranlation = () => {
  const H = useHistory();
  const act_term = action.term;
  const root = useSelector(({ user: T }) => T.login.data);

  const P = useParams();
  const dispatch = useDispatch();
  let term = useSelector(({ term: T }) => T.view.data);

  const ckRP = (rl) => Hoc.isArr(rl).some((rv) => rv?.UserId === root?.id);

  term = !!(
    ["Admin", "Manager"].includes(root?.role) || term?.UserId === root?.id
  )
    ? term
    : ((T) => ({
        ...T,
        word: !!(!!T?.enabled || T?.UserId === root?.id || ckRP(term?.Reports))
          ? T?.word
          : null,
        Translations: !T?.Translations
          ? []
          : Hoc.isArr(T?.Translations).filter(
              (vl) =>
                !!vl?.enabled || vl?.UserId === root?.id || ckRP(vl?.Reports)
            ),
      }))(term);

  useEffect(() => {
    dispatch(act_term.view(P.id));
  }, [dispatch, act_term, P]);

  const refresh = () => {
    dispatch(act_term.view(P.id));
    H.push(`/term-view/${P.id}`);
  };

  const actions = [{ name: "Go Back" }];

  if (!!root?.token)
    actions.push({
      name: "Create a Translation",
      to: `/add-translation/${P.id}`,
    });

  return (
    <React.Fragment>
      <Hoc.ActionButtons actions={actions} />
      {!term ? (
        <Hoc.Typo
          content="Empty active items found here."
          align="center"
          variant="h3"
          color="textSecondary"
        />
      ) : (
        <TransList term={term} refresh={refresh} />
      )}
    </React.Fragment>
  );
};

export default SingleTranlation;
