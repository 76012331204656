import React from "react";
import MuiPhoneNumber from "material-ui-phone-number";

const Input = (props) => {
  const { label, name, value, fullWidth } = props;
  const { onChange } = props;
  const toDefEventPara = (name, value) => ({ target: { name, value } });

  return (
    <MuiPhoneNumber
      name={name}
      variant="outlined"
      value={value}
      fullWidth={fullWidth || true}
      label={label}
      defaultCountry="gb"
      onChange={(v) => onChange(toDefEventPara(name, v || ""))}
    />
  );
};

export default Input;
