import React, { useState } from "react";
import Hoc from "../../component/Hoc";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Controls from "../../component/Hoc/Controls";
import action from "../../action";
import { Box, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import TermMedia from "./TermMedia";

import "react-simple-keyboard/build/css/index.css";
import Keyboard from "react-simple-keyboard";
import Layouts from "simple-keyboard-layouts";
import layouts, { RTLs } from "../../assets/layouts";

const CreateTrans = ({ id, TermId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { useForm, Form } = Hoc.useForm;
  const H = useHistory();
  const act_term = action.translation;
  const dispatch = useDispatch();
  const { vals, setVals, onChange, resetForm } = useForm(initData());

  const onSubmit = () => {
    let view = new FormData();
    Object.entries(vals).forEach((v) => !!v[1] && view.set(v[0], v[1]));
    !!TermId && view.set("TermId", TermId);

    if (!id) dispatch(act_term.view_add(view, "enabled=true"));
    else dispatch(act_term.view_edit(id, view, "enabled=true"));

    const msg = `Resource ${!id ? "Created" : "Updated"} Successfully`;

    dispatch(action.OOP(msg));

    H.goBack();
  };

  useEffect(() => {
    const setT = async () => {
      const dt = await dispatch(act_term.view(id));
      setVals(initData(dt));
    };
    id && setT();
  }, [dispatch, act_term, id, setVals]);

  const title = !id ? "Create New Resource" : "Updating Resource";

  const layoutData = new Layouts();
  const [layout, setLayout] = useState(null);

  const onLangChange = ({ target: T }) => {
    setVals({ ...vals, tongue: T.value });
    const ly = layouts.find((v) => v.langs.includes(T.value));
    setLayout(!ly?.key ? null : layoutData.get(ly?.key));
  };

  const [openKeyword, setOpenKeyword] = useState("");

  const CKK = (v) => openKeyword === v;

  const onKeyToggle = (name) =>
    setOpenKeyword(!!(!openKeyword || openKeyword !== name) ? name : "");
  const onKeyChange = (N, V) => setVals({ ...vals, [N]: V });
  // const onKeyChange = (N, V) => setVals({ ...vals, [N]: V });

  const FromKeyInput = (name) => (
    <KeyInput
      layout={layout}
      enabled={CKK(name)}
      onChange={(v) => onKeyChange(name, v)}
      onToggle={() => onKeyToggle(name)}
    />
  );

  const isRTL = RTLs.includes(vals?.tongue);

  return (
    <Hoc.FormContainer
      title={title}
      subtitle="Multi Language Keyboard is available only for limited number of languages"
    >
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{ disabled: !!CKF01(vals) }}
      >
        <Controls.AutoSelect {...setup.tongue(vals?.tongue, onLangChange)} />
        <Controls.AutoSelect {...setup.country(vals?.country, onChange)} />

        <Controls.Input dir_rtl={isRTL} {...setup.word(vals?.word, onChange)} />
        {FromKeyInput("word")}

        <Controls.Input dir_rtl={isRTL} {...setup.text(vals?.text, onChange)} />
        {FromKeyInput("text")}

        <Box style={{ width: "80%", margin: "0 auto" }}>
          <Box
            style={{ margin: "15px 0" }}
            {...(!isMobile && Hoc.flex("space-between"))}
          >
            <Controls.FileUpload {...setup.photo(vals?.photo, onChange)} />
            <Controls.FileUpload {...setup.audio(vals?.audio, onChange)} />
            <Controls.FileUpload {...setup.video(vals?.video, onChange)} />
          </Box>
        </Box>
        <TermMedia id={id} vals={vals} setVals={setVals} />
      </Form>
    </Hoc.FormContainer>
  );
};

const CKF01 = (v) => !v?.word || !v?.tongue || !v?.country;

const KeyBtn = ({ enabled, onClick }) => (
  <Box width="100%" style={{ marginBottom: 10 }} {...Hoc.flex("flex-end")}>
    <Button
      onClick={onClick}
      size="small"
      variant="outlined"
      color={enabled ? "primary" : "secondary"}
    >{`${enabled ? "Hide" : "Show"}  Keyboard`}</Button>
  </Box>
);

const KeyInput = ({ enabled, layout, onChange, onToggle }) => {
  const [layoutName, setLayoutName] = useState("default");

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}")
      setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  if (!layout) {
    return <span />;
  }

  return (
    <React.Fragment>
      {!!enabled && (
        <Keyboard
          layoutName={layoutName}
          onKeyPress={onKeyPress}
          onChange={onChange}
          {...layout}
        />
      )}
      <KeyBtn onClick={onToggle} enabled={enabled} />
    </React.Fragment>
  );
};

const initData = (v) => ({
  word: v?.word ? v?.word : null,
  text: v?.text ? v?.text : null,
  //
  photo: v?.photo ? v?.photo : null,
  audio: v?.audio ? v?.audio : null,
  video: v?.video ? v?.video : null,
  //
  tongue: v?.tongue ? v?.tongue : null,
  country: v?.country ? v?.country : null,
});

const setup = {
  word: (N, OC) => Hoc.setupText(N, OC, "word", "Word"),
  text: (N, OC) => Hoc.setupLongText(N, OC, "text", "Text"),
  //
  photo: (N, OC) => Hoc.setupFile(N, OC, "photo", "Photo", "image/*"),
  audio: (N, OC) => Hoc.setupFile(N, OC, "audio", "Audio", "audio/*"),
  video: (N, OC) => Hoc.setupFile(N, OC, "video", "Video", "video/*"),
  //
  tongue: (N, OC) => Hoc.setupAutoSelect(N, OC, "tongue", "Tongue", "L"),
  country: (N, OC) => Hoc.setupAutoSelect(N, OC, "country", "Country", "C"),
};

export const AddTrans = () => {
  const P = useParams();
  return <CreateTrans TermId={P.TermId} />;
};

export const EditTrans = () => {
  const P = useParams();

  return <CreateTrans id={P.id} />;
};
