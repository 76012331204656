import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Hoc from "../../component/Hoc";
import Controls from "../../component/Hoc/Controls";
import action from "../../action";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";

const PlanForm = (props) => {
  const [mm, setMM] = useState(true);
  const dispatch = useDispatch();
  const H = useHistory();
  const act_plan = action.plan;
  const { id } = props;
  const { Form, useForm } = Hoc.useForm;
  const { vals, setVals, onChange, resetForm } = useForm(initData());

  const root = useSelector(({ user: P }) => P.login.data);

  useEffect(() => {
    setVals((v) => ({ ...v, UserId: root?.id }));

    const SetVV = async () => {
      const view = await dispatch(act_plan.view(id));
      setVals(initData(view));
      setMM(!view?.month);
    };

    !!id && SetVV();
  }, [setVals, dispatch, act_plan, id, root]);

  const title = `${!id ? "Create" : "Update"} Subscription Plan`;

  const onSubmit = async (e) => {
    let status;
    const finaldata = {
      ...vals,
      days: !mm ? vals?.days : null,
      months: !!mm ? vals?.months : null,
    };

    if (!!id) {
      status = await dispatch(act_plan.view_edit(id, finaldata));
    } else {
      status = await dispatch(act_plan.view_add(finaldata));
    }

    if (!!status) {
      H.goBack();
      setVals(initData());
      dispatch(action.OOP(`Plan ${!id ? "Created" : "Updated"} Succesfully.`));
    }
  };

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{ disabled: ck(vals, mm) }}
      >
        <Controls.Input {...setup.name(vals?.name, onChange)} />
        <Controls.Input {...setup.price(vals?.price, onChange)} />
        <Controls.Input {...setup.text(vals?.text, onChange)} />
        <Box {...Hoc.flex("center")}>
          <Controls.Switch {...setup.mm(mm, () => setMM(!mm))} />
        </Box>
        {!!mm ? (
          <Controls.Input {...setup.months(vals?.months, onChange)} />
        ) : (
          <Controls.Input {...setup.days(vals?.days, onChange)} />
        )}
      </Form>
    </Hoc.FormContainer>
  );
};

const ck = (v, mm) =>
  !v?.name ||
  v?.price < 1 ||
  !(!!(!!mm && 0 < v?.months) || !!(!mm && 0 < v?.days));

export const AddPlan = () => {
  return <PlanForm />;
};

export const EditPlan = () => {
  const P = useParams();

  return <PlanForm id={P?.id} />;
};

const initData = (v) => ({
  name: v?.name ? v?.name : null,
  text: v?.text ? v?.text : null,
  days: v?.days ? v?.days : null,
  months: v?.months ? v?.months : null,
  price: v?.price ? v?.price : null,
  UserId: v?.UserId ? v?.UserId : null,
});

const setup = {
  mm: (v, OC) => Hoc.setupSwitch(v, OC, "mm", "Month wise (Days or Months)."),
  name: (v, OC) => Hoc.setupText(v, OC, "name", "Name"),
  text: (v, OC) =>
    Hoc.setupLongText(v, OC, "text", "Description with ,, (text 01,,text 02)"),
  days: (v, OC) => ({
    ...ToNum(v, OC, "days", "days"),
    inputProps: { min: 1 },
  }),
  months: (v, OC) => ({
    ...ToNum(v, OC, "months", "months"),
    inputProps: { min: 1 },
  }),
  price: (v, OC) => ({
    ...ToNum(v, OC, "price", "price"),
    inputProps: { min: 1 },
  }),
};

const ToNum = (v, ON, NM, LB) => ({
  ...Hoc.setupText(v, ON, NM, LB, "number"),
  inputProps: {
    min: 0,
  },
});
