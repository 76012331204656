import axios from "axios";
import * as opp from "./base01/opp";
import { Type } from "../redux";
const user_types = Type.user_types;

// Takes the auth token and assign in the axios headers which will be used in on the backend request.
const setJwt = (token) => {
  // Check if the token exists
  if (!!token) {
    // Set token Bearer token in Authorization header
    axios.defaults.headers.common["Authorization"] = `Bearer___${token}`;
  } else {
    // Deleting the Authorization token if token is not exists
    delete axios.defaults.headers.common["Authorization"];
  }
};

// Extracts the auth token from State and passing it to setJWT function
const setJwtFromState = (state) => setJwt(state?.user?.login?.data?.token);

// uses in the catch block from TryCatch to show error as message.
const errResponse = (error, dispatch, type) => {
  // extracting error from response
  const err01 = error?.response?.data?.error;
  // extracting error from other source when response does not have error
  const payload = err01 ? err01 : error?.error;
  // dispatching redux only when dispatch method exists
  dispatch && dispatch({ type, payload });

  // checking wether the error belongs to Authentication
  if (`${payload}`.includes("Authentication Error Occurred")) {
    // constrcting user details
    const usrs = [
      { role: "User", url: "" },
      { role: "Subscriber", url: "subscriber-" },
      { role: "Guest Editor", url: "guest-" },
      { role: "Admin", url: "admin-" },
      { role: "Manager", url: "supreme-" },
    ];

    // taking user details from session and placing in the root variable
    const root = JSON.parse(sessionStorage.getItem("root"));
    // extracting login link
    const url = usrs?.find((v) => v?.role === root?.role)?.url;
    // removing user datya from session
    sessionStorage.removeItem("root");
    // informing redux that user is logged out
    dispatch({ type: user_types.USER_LOGOUT_REQUEST });
    dispatch({ type: user_types.USER_LOGIN_REQUEST });
    dispatch({ type: user_types.USER_LOGIN_SUCCESS, payload: null });
    dispatch({ type: user_types.USER_LOGOUT_SUCCESS, payload: null });

    // !!payload && dispatch(opp.view_add(`You session is expired. Please login again.`, true));
    // setTimeout(() => {}, 5000);
    // redirecting to the login page
    window.location.replace(`/${url}login`);
  } else {
    // dispatching Error message
    !!payload && dispatch(opp.view_add(`${payload}`, true));
  }
};

// creating function for placing content type on FormData interfaces
const multiForm = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

// Takes the values and checks if the value is an array. If not an array then returns am empty array
const isArr = (v) => (Array.isArray(v) ? v : []);

// Putting all together
const Expo = {
  setJwt,
  setJwtFromState,
  URL: process.env.REACT_APP_API,
  errResponse,
  multiForm,
  isArr,
};

export default Expo;
