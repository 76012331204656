import axios from "axios";
import Helper from "../helper";
import { Type } from "../../redux";
const types = Type.plan_types;

const URL = `${Helper.URL}/plan`;

const load = async (dispatch, qri = "") => {
  try {
    dispatch({ type: types.PLAN_LIST_REQUEST });
    const { data } = await axios.get(`${URL}?${qri}`);
    dispatch({ type: types.PLAN_LIST_SUCCESS, payload: data?.result });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PLAN_LIST_FAIL);
  }
};

export const view_all = (qri) => async (dispatch) => await load(dispatch, qri);

export const view_add = (initData, qri) => async (dispatch) => {
  try {
    dispatch({ type: types.PLAN_ADD_REQUEST });
    const { data } = await axios.post(URL, initData);
    dispatch({ type: types.PLAN_ADD_SUCCESS, payload: data?.result });
    await load(dispatch, qri);
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PLAN_ADD_FAIL);
  }
};

export const view = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.PLAN_VIEW_REQUEST });
    const { data } = await axios.get(`${URL}/${id}`);
    dispatch({ type: types.PLAN_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PLAN_VIEW_FAIL);
  }
};

export const view_edit = (id, initData, qri) => async (dispatch) => {
  try {
    dispatch({ type: types.PLAN_EDIT_REQUEST });
    const { data } = await axios.put(`${URL}/${id}`, initData);
    dispatch({ type: types.PLAN_EDIT_SUCCESS, payload: data?.result });
    await load(dispatch, qri);
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PLAN_EDIT_FAIL);
  }
};

export const view_delete = (id, qri) => async (dispatch) => {
  try {
    dispatch({ type: types.PLAN_DELETE_REQUEST });
    const { data } = await axios.delete(`${URL}/${id}`);
    dispatch({ type: types.PLAN_DELETE_SUCCESS, payload: data?.result });
    await load(dispatch, qri);
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.PLAN_DELETE_FAIL);
  }
};
