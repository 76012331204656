import React from "react";
import Hoc from "../../Hoc";
import Controls from "../../Hoc/Controls";
import action from "../../../action";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReCAPTCHA, { useReCAPTCHA } from "../ReCAPTCHA";

const Login = ({ role, to }) => {
  const { reCaptcha, handleReCaptcha } = useReCAPTCHA();

  const { Form, useForm } = Hoc.useForm;
  const { vals, onChange, setVals, resetForm } = useForm(initData(role));
  const register = action.user.register;
  const dispatch = useDispatch();
  const H = useHistory();

  const onSubmit = async () => {
    const status = await dispatch(register(vals));

    if (!!status) {
      setVals(initData());
      dispatch(action.OOP("Registration Succesfull."));
      H.push(`/${to}login`);
    }
  };

  const endContent = (
    <Hoc.Typo align="right">
      Already have an account? <Link to={`/${to}login`}>Login.</Link>
    </Hoc.Typo>
  );

  const fmApply = {
    endContent,
    onSubmit,
    resetForm,
    submitButton: {
      disabled: sstatus(vals) || !reCaptcha,
    },
  };

  return (
    <Hoc.FormContainer title={`${role} Registration`}>
      <Form {...fmApply}>
        <Controls.Input {...setup.title(vals.title, onChange)} />
        <Controls.Input {...setup.first_name(vals.first_name, onChange)} />
        <Controls.Input {...setup.last_name(vals.last_name, onChange)} />
        <Controls.PhoneInput {...setup.contact(vals.contact, onChange)} />
        <Controls.Input {...setup.email(vals.email, onChange)} />
        <Controls.Input {...setup.password(vals.password, onChange)} />
        <ReCAPTCHA handleReCaptcha={handleReCaptcha} />
      </Form>
    </Hoc.FormContainer>
  );
};

const sstatus = (v) =>
  !v?.role ||
  !v?.email ||
  `${v?.password}`.length < 6 ||
  !v?.title ||
  !v?.first_name;

const initData = (role) => ({
  role,
  title: null,
  first_name: null,
  last_name: null,
  email: null,
  password: null,
  contact: null,
});

const setvv = (v, onChange, name, label, type = "text") => ({
  onChange,
  name,
  label,
  type,
  value: !!v ? v : "",
});

const setup = {
  title: (v, ON) => setvv(v, ON, "title", "Title (Mrs/Mr/..)"),
  first_name: (v, ON) => setvv(v, ON, "first_name", "First Name"),
  last_name: (v, ON) => setvv(v, ON, "last_name", "Last Name"),
  email: (v, ON) => setvv(v, ON, "email", "Email", "email"),
  password: (v, ON) => setvv(v, ON, "password", "Password", "password"),
  contact: (v, ON) => setvv(v, ON, "contact", "Contact Number", "phone"),
};

export default Login;
