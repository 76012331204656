const types = {
  //
  BANK_LIST_REQUEST: "BANK_LIST_REQUEST",
  BANK_LIST_SUCCESS: "BANK_LIST_SUCCESS",
  BANK_LIST_FAIL: "BANK_LIST_FAIL",
  //
  BANK_VIEW_REQUEST: "BANK_VIEW_REQUEST",
  BANK_VIEW_SUCCESS: "BANK_VIEW_SUCCESS",
  BANK_VIEW_FAIL: "BANK_VIEW_FAIL",
  //
  BANK_MTD_REQUEST: "BANK_MTD_REQUEST",
  BANK_MTD_SUCCESS: "BANK_MTD_SUCCESS",
  BANK_MTD_FAIL: "BANK_MTD_FAIL",
  //
  BANK_ADD_REQUEST: "BANK_ADD_REQUEST",
  BANK_ADD_SUCCESS: "BANK_ADD_SUCCESS",
  BANK_ADD_FAIL: "BANK_ADD_FAIL",
  //
  BANK_EDIT_REQUEST: "BANK_EDIT_REQUEST",
  BANK_EDIT_SUCCESS: "BANK_EDIT_SUCCESS",
  BANK_EDIT_FAIL: "BANK_EDIT_FAIL",
  //
  BANK_DELETE_REQUEST: "BANK_DELETE_REQUEST",
  BANK_DELETE_SUCCESS: "BANK_DELETE_SUCCESS",
  BANK_DELETE_FAIL: "BANK_DELETE_FAIL",
};

export default types;
