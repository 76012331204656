import axios from "axios";
import Helper from "../helper";
import { Type } from "../../redux";
const types = Type.review_types;

const URL = `${Helper.URL}/reviews`;

const _load = async (dispatch, qri = "") => {
  try {
    dispatch({ type: types.REVIEW_LIST_REQUEST });
    const { data } = await axios.get(`${URL}?${qri}`);
    dispatch({ type: types.REVIEW_LIST_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.REVIEW_LIST_FAIL);
  }
};

export const view_all = (qri) => async (dispatch) => await _load(dispatch, qri);

export const view_add = (initData, qri) => async (dispatch, state) => {
  try {
    dispatch({ type: types.REVIEW_ADD_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.post(URL, initData);
    dispatch({ type: types.REVIEW_ADD_SUCCESS, payload: data?.result });
    await _load(dispatch, qri);
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.REVIEW_ADD_FAIL);
  }
};

export const view = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.REVIEW_VIEW_REQUEST });
    const { data } = await axios.get(`${URL}/${id}`);
    dispatch({ type: types.REVIEW_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.REVIEW_VIEW_FAIL);
  }
};

export const view_edit = (id, initData, qri) => async (dispatch, state) => {
  try {
    dispatch({ type: types.REVIEW_EDIT_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.put(`${URL}/${id}`, initData);
    dispatch({ type: types.REVIEW_EDIT_SUCCESS, payload: data?.status });
    await _load(dispatch, qri);
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.REVIEW_EDIT_FAIL);
  }
};

export const view_delete = (id, qri) => async (dispatch, state) => {
  try {
    dispatch({ type: types.REVIEW_DELETE_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.delete(`${URL}/${id}`);
    dispatch({ type: types.REVIEW_DELETE_SUCCESS, payload: data?.result });
    await _load(dispatch, qri);
    return data?.status;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.REVIEW_DELETE_FAIL);
  }
};
