import React from "react";
import ReactDOM from "react-dom";
import "./theme/index.css";

import App from "./App";
import { Provider } from "react-redux";
import redux from "./redux";

import { StylesProvider } from "@material-ui/core/styles";
import ThemeConfig from "./theme/index";

// <ThemeConfig />
// - This component loads the configuaration settings from the Material UI.
//   We have also created some custom classnames for the app as well, For instance i have created
//   one custom classname `flex-center` which will make the component display as flex and alignments of the item in the center position.
// - Along with the custom classnames this omponent also modified the prebuilt material-ui styles like primary & secondary styles and so on.

// <StylesProvider />
// - This component is directly imported from material-ui which behaves like Provider for material-ui styles

// <Provider />
// - This components takes reducx component for managing global state

ReactDOM.render(
  <ThemeConfig>
    <StylesProvider injectFirst>
      <Provider store={redux}>
        <App />
      </Provider>
    </StylesProvider>
  </ThemeConfig>,
  document.getElementById("root")
);
