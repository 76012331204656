const types = {
  //
  PLAN_LIST_REQUEST: "PLAN_LIST_REQUEST",
  PLAN_LIST_SUCCESS: "PLAN_LIST_SUCCESS",
  PLAN_LIST_FAIL: "PLAN_LIST_FAIL",
  //
  PLAN_VIEW_REQUEST: "PLAN_VIEW_REQUEST",
  PLAN_VIEW_SUCCESS: "PLAN_VIEW_SUCCESS",
  PLAN_VIEW_FAIL: "PLAN_VIEW_FAIL",
  //
  PLAN_ADD_REQUEST: "PLAN_ADD_REQUEST",
  PLAN_ADD_SUCCESS: "PLAN_ADD_SUCCESS",
  PLAN_ADD_FAIL: "PLAN_ADD_FAIL",
  //
  PLAN_EDIT_REQUEST: "PLAN_EDIT_REQUEST",
  PLAN_EDIT_SUCCESS: "PLAN_EDIT_SUCCESS",
  PLAN_EDIT_FAIL: "PLAN_EDIT_FAIL",
  //
  PLAN_DELETE_REQUEST: "PLAN_DELETE_REQUEST",
  PLAN_DELETE_SUCCESS: "PLAN_DELETE_SUCCESS",
  PLAN_DELETE_FAIL: "PLAN_DELETE_FAIL",
};

export default types;
