// This function designed to create a redux reducer
// it will take some values and generates the redux reducer which helps to manage the redux state
// name - state name for the redux action
// state - initial data
// request - request type for the redux
// success - success type for the redux
// fail - failure type for the redux
export const toReducer = (v) =>
  v
    ? {
        [v.name]: (state = v.state, { type, payload }) => {
          switch (type) {
            case v.request:
              return { ...state, loading: true };
            case v.success:
              return { loading: false, data: payload };
            case v.fail:
              return { ...state, loading: false, error: payload };
            default:
              return state;
          }
        },
      }
    : null;

// This function designed to create a redux reducer only for the succeess & failure messages
// it will take some values and generates the redux reducer which helps to manage the redux state for the messages
// name - state name for the redux action
// state - initial data fior message
// create - create type for the redux
// delete - delete type for the redux
export const toOopMsg = (v) =>
  v
    ? {
        [v.name]: (state = v.state, { type, payload }) => {
          switch (type) {
            case v.create:
              return payload;
            case v.delete:
              return payload;
            default:
              return state;
          }
        },
      }
    : null;
