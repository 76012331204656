import React, { useEffect } from "react";
import { Box, TableRow, TableCell } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
// Icons
import Search from "@material-ui/icons/Search";
import Hoc from "../../component/Hoc";
import Controls from "../../component/Hoc/Controls";
import Custom from "../../component/Custom";
import action from "../../action";
import { useDispatch, useSelector } from "react-redux";

const Email = () => {
  const dispatch = useDispatch();
  const Act = action.template;
  const { error, loading, data } = useSelector(({ template: T }) => T.view_all);

  const [filterFn, setFilterFn] = React.useState({
    fn: (items) => {
      return items;
    },
  });

  const tbl = Custom.useTable(data, cells, filterFn);
  const { TblContainer, finalRecords } = tbl;

  useEffect(() => {
    dispatch(Act.view_all());
  }, [dispatch, Act]);

  const onTemplateDelete = (id) => {
    if (window.confirm("Are you sure, You want to delete the Item.") === true) {
      dispatch(Act.view_delete(id));
      dispatch(Act.view_all());
    }
  };

  const onSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target?.value === "") return items;
        else
          return items.filter((x) =>
            x?.name?.toLowerCase().includes(target.value)
          );
      },
    });
  };
  const t_acts = (v) => {
    let items = [];

    items = [
      { name: "Edit", to: `/template-edit/${v.id}` },
      { name: "Delete", onClick: () => onTemplateDelete(v.id) },
    ];

    return items;
  };

  return (
    <React.Fragment>
      <Hoc.PageBox
        title="Email Information"
        actions={[
          { name: "Create Template", to: "/template-add" },
          { name: "Back", goBack: true },
        ]}
      >
        <Hoc.UseData error={error} loading={loading}>
          <Box style={{ maxWidth: 200, marginBottom: 5 }}>
            <Controls.Input
              label="Search"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={onSearch}
            />
          </Box>
          {!finalRecords().length && <Hoc.EmptyResource />}
          {!!finalRecords().length && (
            <TblContainer paginate>
              {finalRecords().map((v) => (
                <TableRow key={v.id || "NA"}>
                  <TableCell>{v.id || "NA"}</TableCell>
                  <TableCell>{v.name || "NA"}</TableCell>
                  <TableCell>{v.from || "NA"}</TableCell>
                  <TableCell>{v.subject || "NA"}</TableCell>
                  <TableCell>
                    {`${v.html}`.substring(0, 10) + "..." || "NA"}
                  </TableCell>
                  <TableCell>{v.actions || "NA"}</TableCell>
                  <TableCell>
                    <Hoc.MenuButton actions={t_acts(v)} />
                  </TableCell>
                </TableRow>
              ))}
            </TblContainer>
          )}
        </Hoc.UseData>
      </Hoc.PageBox>
    </React.Fragment>
  );
};

const cells = [
  { id: "id", label: "id" },
  { id: "name", label: "name" },
  { id: "from", label: "from" },
  { id: "subject", label: "subject" },
  { id: "html", label: "html" },
  { id: "functions", label: "functions" },
  { id: "actions", label: "actions", disableSorting: true },
];

// actions: ""

export default Email;
