import React, { useEffect } from "react";
import Hoc from "../../../component/Hoc";
import Controls from "../../../component/Hoc/Controls";
import action from "../../../action";
import { useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const EditProfilePsw = () => {
  const act_user = action.user;
  const dispatch = useDispatch();
  const H = useHistory();
  const { Form, useForm } = Hoc.useForm;
  const fmData = useForm(initData());
  const { vals, setVals, onChange, resetForm } = fmData;

  useEffect(() => {
    const setVV = async () => {
      const dt = await dispatch(act_user.view_profile());
      setVals(initData(dt));
    };
    setVV();
  }, [dispatch, act_user, setVals]);

  const onSubmit = async () => {
    const status = await dispatch(act_user.edit_profile(vals));

    if (!!status) {
      setVals(initData);
      dispatch(action.OOP("Profile Updated."));
      const url = dispatch(act_user.logout());
      if (!!url) H.push(url);
    }
  };
  const fmApply = {
    onSubmit,
    resetForm,
    submitButton: { disabled: CKS(vals) },
  };

  const onLanChange = ({ target: T }) => {
    const SL = "source_language";
    const TL = "target_language";
    const msg = (A, B) =>
      `${A} should not match ${B}. Please choose different ${A}.`;

    if (T?.name === SL && T?.value === vals[TL])
      dispatch(action.OOP(msg("Source Language", "Target Language"), true));
    else if (T?.name === TL && T?.value === vals[SL])
      dispatch(action.OOP(msg("Target Language", "Source Language"), true));
    else setVals({ ...vals, [T?.name]: T?.value });
  };

  return (
    <Hoc.FormContainer title="Update Profile" subtitle="">
      <Form {...fmApply}>
        <Controls.Input {...setup.title(vals?.title, onChange)} />
        <Controls.Input {...setup.first_name(vals?.first_name, onChange)} />
        <Controls.Input {...setup.last_name(vals?.last_name, onChange)} />
        <Controls.PhoneInput {...setup.contact(vals?.contact, onChange)} />
        <SelectItem {...setup.country(vals?.country, onChange)} />
        <SelectItem
          {...setup.source_language(vals?.source_language, onLanChange)}
        />
        <SelectItem
          {...setup.target_language(vals?.target_language, onLanChange)}
        />
      </Form>
    </Hoc.FormContainer>
  );
};

const CKS = (v) =>
  !v?.first_name || !v?.last_name || v?.target_language === v?.source_language;

const initData = (v) => ({
  title: v?.title ? v?.title : null,
  first_name: v?.first_name ? v?.first_name : null,
  last_name: v?.last_name ? v?.last_name : null,
  contact: v?.contact ? v?.contact : null,
  country: v?.country ? v?.country : null,
  source_language: v?.source_language ? v?.source_language : null,
  target_language: v?.target_language ? v?.target_language : null,
});

const setvv = (v, onChange, name, label, type = "text") => ({
  onChange,
  name,
  label,
  type,
  value: !!v ? v : "",
});

const setup = {
  title: (v, ON) => setvv(v, ON, "title", "Title (Mrs/Mr/..)"),
  first_name: (v, ON) => setvv(v, ON, "first_name", "First Name"),
  last_name: (v, ON) => setvv(v, ON, "last_name", "Last Name"),
  contact: (v, ON) =>
    setvv(v, ON, "contact", "contact", "contact Number", "phone"),
  country: (v, ON) => Hoc.setupSelect(v, ON, "country", "Select Country", "C"),
  source_language: (v, ON) =>
    Hoc.setupSelect(v, ON, "source_language", "Select Source Language", "L"),
  target_language: (v, ON) =>
    Hoc.setupSelect(v, ON, "target_language", "Select Target Language", "L"),
};

const SelectItem = (rest) => (
  <Box style={{ margin: "20px 0" }} {...Hoc.flex()}>
    <Box style={{ width: "80%" }}>
      <Controls.AutoSelect {...rest} />
    </Box>
  </Box>
);

export default EditProfilePsw;
