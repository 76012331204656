// declaring the redux types for redux state for Message
const OOP_types = {
  CREATE_OOP: "CREATE_OOP",
  DELETE_OOP: "DELETE_OOP",
};

// preparing the data for redux reducer
const OOP = {
  name: "OOP",
  state: { text: null, status: false },
  create: OOP_types.CREATE_OOP,
  delete: OOP_types.DELETE_OOP,
};

const Expo = {
  // exporting the prepared redux reducer data
  OOP,
  // exporting redux types for the message
  OOP_types,
};

// exporting all items as a single component
export default Expo;
