const types = {
  //
  REPORT_LIST_REQUEST: "REPORT_LIST_REQUEST",
  REPORT_LIST_SUCCESS: "REPORT_LIST_SUCCESS",
  REPORT_LIST_FAIL: "REPORT_LIST_FAIL",
  //
  REPORT_VIEW_REQUEST: "REPORT_VIEW_REQUEST",
  REPORT_VIEW_SUCCESS: "REPORT_VIEW_SUCCESS",
  REPORT_VIEW_FAIL: "REPORT_VIEW_FAIL",
  //
  REPORT_ADD_REQUEST: "REPORT_ADD_REQUEST",
  REPORT_ADD_SUCCESS: "REPORT_ADD_SUCCESS",
  REPORT_ADD_FAIL: "REPORT_ADD_FAIL",
  //
  REPORT_EDIT_REQUEST: "REPORT_EDIT_REQUEST",
  REPORT_EDIT_SUCCESS: "REPORT_EDIT_SUCCESS",
  REPORT_EDIT_FAIL: "REPORT_EDIT_FAIL",
  //
  REPORT_DELETE_REQUEST: "REPORT_DELETE_REQUEST",
  REPORT_DELETE_SUCCESS: "REPORT_DELETE_SUCCESS",
  REPORT_DELETE_FAIL: "REPORT_DELETE_FAIL",
};

export default types;
