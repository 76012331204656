import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const Select = (props) => {
  const { name, label, value } = props;
  const { onChange, options, fullWidth = true } = props;

  const toDefPara = (name, value) => ({ target: { name, value } });

  const baseInit = {
    id: `combo-box-demo-${name}`,
    options,
    value: value ? value : null,
    getOptionLabel: (v) => v,
    onChange: (_, v) => onChange(toDefPara(name, v)),
    fullWidth,
  };

  return (
    <Autocomplete
      {...baseInit}
      renderInput={(v) => <TextField {...v} label={label} variant="outlined" />}
    />
  );
};

export default Select;
