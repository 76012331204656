const types = {
  //
  TRANSLATE_LIST_REQUEST: "TRANSLATE_LIST_REQUEST",
  TRANSLATE_LIST_SUCCESS: "TRANSLATE_LIST_SUCCESS",
  TRANSLATE_LIST_FAIL: "TRANSLATE_LIST_FAIL",
  //
  TRANSLATE_VIEW_REQUEST: "TRANSLATE_VIEW_REQUEST",
  TRANSLATE_VIEW_SUCCESS: "TRANSLATE_VIEW_SUCCESS",
  TRANSLATE_VIEW_FAIL: "TRANSLATE_VIEW_FAIL",
  //
  TRANSLATE_ADD_REQUEST: "TRANSLATE_ADD_REQUEST",
  TRANSLATE_ADD_SUCCESS: "TRANSLATE_ADD_SUCCESS",
  TRANSLATE_ADD_FAIL: "TRANSLATE_ADD_FAIL",
  //
  TRANSLATE_EDIT_REQUEST: "TRANSLATE_EDIT_REQUEST",
  TRANSLATE_EDIT_SUCCESS: "TRANSLATE_EDIT_SUCCESS",
  TRANSLATE_EDIT_FAIL: "TRANSLATE_EDIT_FAIL",
  //
  TRANSLATE_DELETE_REQUEST: "TRANSLATE_DELETE_REQUEST",
  TRANSLATE_DELETE_SUCCESS: "TRANSLATE_DELETE_SUCCESS",
  TRANSLATE_DELETE_FAIL: "TRANSLATE_DELETE_FAIL",
};

export default types;
