import React from "react";
import { Input, InputLabel } from "@material-ui/core";
import { FormControl, InputAdornment } from "@material-ui/core";

const AdornmentInput = (props) => {
  const { variant, fullWidth, show, type, label, name, value } = props;
  const { adornment, onChange } = props;

  return (
    <FormControl fullWidth={fullWidth || false}>
      <InputLabel>{label}</InputLabel>
      <Input
        variant={variant || "outlined"}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        type={
          type === "password" ? (show ? "text" : "password") : type || "text"
        }
        {...(adornment && {
          endAdornment: (
            <InputAdornment position={adornment?.position} className="pointer">
              {adornment.icon}
            </InputAdornment>
          ),
        })}
      />
    </FormControl>
  );
};

export default AdornmentInput;
