import React from "react";
import { Menu, MenuItem, Fade, Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

// This component mostly uses in the table for showing the options in the Table.
// Once you click on this this item then you will see list of actions which were taken for the action
export default function FadeMenu(props) {
  // creating H component for page redirections
  const H = useHistory();
  // extracting the default properties
  const { icon, text = "Action", actions, color = "primary" } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const stl = { style: { padding: "3px 6px" } };

  const menuProps = {
    id: "fade-menu",
    open,
    anchorEl,
    keepMounted: true,
    onClose: handleClose,
    TransitionComponent: Fade,
  };

  const onMenuItem = (v) => {
    if (!!v?.onClick) v.onClick();
    else if (!!v?.to) H.push(v.to);
    else if (!!v?.back) H.goBack();

    handleClose();
  };

  return (
    <div>
      <Box onClick={handleClick}>
        {!!icon && icon}
        {!!text && (
          <Button variant="contained" size="small" color={color}>
            {text}
          </Button>
        )}
      </Box>
      <Menu {...menuProps}>
        {Array.isArray(actions) && !!actions.length ? (
          actions.map((v, i) => (
            <MenuItem key={i} {...stl} onClick={() => onMenuItem(v)}>
              {v?.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem {...stl} onClick={handleClose}>
            No Actions
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
