import React, { useEffect } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "../../Hoc";
import action from "../../../action";

const PayPal = (props) => {
  const { PlanId } = props;
  const amount = parseFloat(props?.amount).toFixed(2);
  const act_bank = action.bank;
  const dispatch = useDispatch();
  const act_add_subscription = action.user.add_subscription;

  const data = useSelector(({ bank: B }) => B.method.data);
  useEffect(() => {
    dispatch(act_bank.method("PayPal"));
  }, [dispatch, act_bank]);

  const onSuccess = async (V, _) => {
    dispatch(act_add_subscription({ PlanId }));
    const msg =
      "Congrats, We have successfully extended your subscription date.";
    dispatch(action.OOP(msg));
  };

  const createOrder = (_, actions) => {
    let res = { currency: "GBP", value: amount };
    res = { amount: res };
    res = { purchase_units: [res] };
    return actions.order.create(res);
  };

  return (
    <Hoc.UseData loading={false} error={false}>
      {!!data?.client_id ? (
        <PayPalButton
          amount={amount}
          currency={"GBP"}
          createOrder={createOrder}
          options={{
            clientId: data?.client_id,
          }}
          onApprove={onSuccess}
          onError={(err) => console.log(`${err}`)}
        />
      ) : (
        <Hoc.Typo
          align="center"
          color="secondary"
          variant="h5"
          content="PayPal not configured yet!"
        />
      )}
    </Hoc.UseData>
  );
};

export default PayPal;
