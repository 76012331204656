import { Box } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import Hoc from "../../component/Hoc";
import moment from "moment";

const Dashboard = () => {
  const root = useSelector(({ user: P }) => P.login.data);

  const rl = root?.role === "User" ? "" : ` (${root?.role})`;

  const title = `${root?.first_name} ${root?.last_name}${rl}`;
  const endDate = !root?.subscription_date
    ? "Not Subscribed."
    : moment(root?.subscription_date).format("LLL");
  const notExpired = new Date(endDate).getTime() > new Date().getTime();

  const ForInactiveAdmin = (
    <React.Fragment>
      <Box width="100%">
        <Hoc.Typo
          align="center"
          color="textSecondary"
          variant="h4"
          content="Your account is not active please wait until got verified."
        />
      </Box>
    </React.Fragment>
  );

  const ForActiveAdmin = (
    <React.Fragment>
      <Hoc.ShowItem.Subscriptions />
      <Hoc.ShowItem.UnPublished />
      <Hoc.ShowItem.UnPublishedReports />
      <Hoc.ShowItem.UnPublishedReviews />
    </React.Fragment>
  );

  const ForManager = (
    <React.Fragment>
      {ForActiveAdmin}
      <Hoc.ShowItem.PayMethods />
      <Hoc.ShowItem.EmailTemplate />
      <Hoc.ShowItem.FooterLink />
    </React.Fragment>
  );

  const nav_01 = {
    title,
    actions: [{ name: "Back", goBack: true }],
  };
  const CKR = (v) => root?.role === v;

  const BaseItems = () => (
    <React.Fragment>
      <Hoc.ShowItem.People />
      <Hoc.ShowItem.CreatedReports />
    </React.Fragment>
  );

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      {CKR("Subscriber") && (
        <Box width="100%" {...Hoc.flex("flex-end")}>
          <Hoc.Typo02
            title={`Subscription ${
              !endDate
                ? "Detail"
                : notExpired
                ? "Expiry Date"
                : "Date (Expired)"
            }`}
            content={endDate}
          />
        </Box>
      )}
      {CKR("Admin") && !root?.admin_status && ForInactiveAdmin}

      {!!(!CKR("Admin") || !!root?.admin_status) ? <BaseItems /> : null}

      {CKR("Subscriber") && <Hoc.ShowItem.Subscriber />}

      {CKR("Admin") && !!root?.admin_status && ForActiveAdmin}

      {CKR("Manager") && ForManager}
    </Hoc.Dashboard.Container>
  );
};

// const P1 = ["Manager"];
// const P2 = [...P1, "Admin"];
// const P3 = [...P2, "Subscriber"];
// const P4 = [...P3, "Guest Editor"];
// const P5 = [...P4, "User"];

// const CKR = (NM, RL) =>
//   NM === "Manager"
//     ? P1.includes(RL)
//     : NM === "Admin"
//     ? P2.includes(RL)
//     : NM === "Subscriber"
//     ? P3.includes(RL)
//     : NM === "Guest Editor"
//     ? P4.includes(RL)
//     : NM === "User"
//     ? P5.includes(RL)
//     : false;

export default Dashboard;
