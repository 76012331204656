import SecureRoute from "../../SecureRoute";
import add from "./add";
import view from "./view";

const Expo = {
  add: (
    <SecureRoute grant={["Manager"]} path="/email-settings" component={add} />
  ),
  view: (
    <SecureRoute grant={["Manager"]} path="/email-setup" component={view} />
  ),
};

export default Expo;
