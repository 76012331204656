import React from "react";
import Hoc from "../../component/Hoc";

const NotFound = () => {
  const nav_01 = {
    title: "Page Not Found",
    actions: [
      { name: "Back", goBack: true },
      { name: "Home", to: "/glossary" },
    ],
  };
  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Hoc.Typo color="error" variant="h3" content="Page Not Found 404" />
    </Hoc.Dashboard.Container>
  );
};

export default NotFound;
