import { Box, Grid, Badge, Button } from "@material-ui/core";
import Hoc from "../../component/Hoc";
import React from "react";
import action from "../../action";
import { useDispatch } from "react-redux";

const TermMedia = ({ id = null, vals, setVals, term = false }) => {
  const dispatch = useDispatch();
  const act_term = action.term;

  const onMediaDelete = (file_type) => {
    const params = {
      id,
      model_type: !term ? "Trans" : "Term",
      file_type,
    };
    if (window.confirm("Are you sure, You want to delete.") === true) {
      if (!!id) dispatch(act_term.media_delete(params));
      setVals({ ...vals, [file_type]: null });
    }
  };

  return (
    <Grid container width="100%" justifyContent="center" spacing={2}>
      <FileContent
        text="Photo"
        file={vals?.photo}
        onDelete={() => onMediaDelete("photo")}
      >
        <Box {...TRIM} src={vals?.photo} />
      </FileContent>
      <CKMedia
        text="Video"
        file={vals?.video}
        title="Video File Name"
        onDelete={() => onMediaDelete("video")}
      >
        <video {...video_setup(vals?.video)} />
      </CKMedia>
      <CKMedia
        text="Audio"
        file={vals?.audio}
        title="Audio File Name"
        onDelete={() => onMediaDelete("audio")}
      >
        <audio controls src={GetUrl(vals?.audio, true)} width="100%" />
      </CKMedia>
    </Grid>
  );
};

const GetUrl = (v, ad) =>
  `${process.env.REACT_APP_API}/${!ad ? "video" : "audio"}/${v}`;

const CKMedia = ({ title, text = "", onDelete = (f) => f, file, children }) =>
  typeof file === "string" ? (
    <FileContent text={text} onDelete={onDelete} file={file}>
      {children}
    </FileContent>
  ) : file?.name ? (
    <GridTypo onDelete={onDelete} title={title} content={file?.name} />
  ) : null;

const DeleteBadge = ({ children, ...rest }) => (
  <Badge
    style={{ cursor: "pointer" }}
    badgeContent="Delete"
    color="error"
    {...rest}
  >
    {children}
  </Badge>
);

const GridTypo = ({ onDelete, ...rest }) => (
  <Grid item xs={12}>
    <DeleteBadge onClick={onDelete}>
      <Hoc.Typo02 {...rest} />
    </DeleteBadge>
  </Grid>
);

const FileContent = ({ children, text, file, onDelete }) =>
  !file ? null : (
    <Grid item xs={12}>
      <Box {...Hoc.flex()} flexDirection="column">
        <Box>{children}</Box>
        <Button onClick={onDelete} {...btnStl}>
          Delete {text}
        </Button>
      </Box>
    </Grid>
  );

const btnStl = {
  size: "small",
  variant: "contained",
  color: "secondary",
  style: { margin: "10px 0" },
};

const video_setup = (src) => ({
  controls: true,
  className: "rounded mx-auto d-block",
  style: { width: "100%", maxHeight: 200 },
  src: GetUrl(src),
});

const TRIM = {
  component: "img",
  className: "img-fluid rounded",
  alt: "doctor img",
};

export default TermMedia;
