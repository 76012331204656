import React, { forwardRef } from "react";
import MaterialTable from "@material-table/core";
import { Paper } from "@material-ui/core";
import Amina from "../../../component/Hoc/Amina";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AddBox from "@material-ui/icons/AddBox";

function StickyHeadTable({ data }) {
  const H = useHistory();
  const root = useSelector(({ user: T }) => T.login.data);

  const actions = [];
  const invalidAdmin = root?.role !== "Admin" || !!root?.admin_status;

  const onRowClick = (_, v) =>
    !!(!!root?.token && invalidAdmin)
      ? H.push(`/term-view/${v.id}`)
      : H.push(`/login`);

  const toUrl = (v) => H.push(v);

  if (!!root?.token && invalidAdmin)
    actions.push(acts.add(() => toUrl("/add-term")));

  return (
    <div className="mt-5 pt-5 mb-5 pb-5  p-3" style={{ boxShadow: "none" }}>
      <MaterialTable
        icons={{
          AddBox: forwardRef((rest, ref) => <AddBox {...rest} ref={ref} />),
        }}
        {...base_setup}
        data={data}
        actions={actions}
        onRowClick={onRowClick}
      />
    </div>
  );
}

const acts = {
  add: (OC) =>
    toAct(AddBox, "Add a Word or Terminology into the Glossary", OC, true),

  edit: (OC) => toAct("edit", "Update Word or Terminology", OC),
  delete: (OC) => toAct("delete", "Delete Word or Terminology", OC),
  save: (OC) => toAct("save", "save Word or Terminology", OC),
};

const toAct = (icon, tooltip, onClick, isFreeAction = false) => ({
  icon,
  tooltip,
  onClick,
  isFreeAction,
});

const toCol = (title, field, filterPlaceholder) => ({
  title,
  field,
  filterPlaceholder,
});

const columns = [
  {
    ...toCol("Word", "word", "Filter by translation"),
    defaultSort: "asc",
    headerStyle: { color: "#932AAD" },
  },
  {
    ...toCol("Explanation", "text", "Filter by explanation"),
    render: (v) => (
      <span>
        {v?.text?.slice(0, 40)}
        {v?.text?.length > 40 ? "..." : ""}
      </span>
    ),
    filterPlaceholder: "Filter by explanation",
  },
  toCol("Country", "country", "Filter by Country"),
  toCol("Category", "category", "Filter by Category"),
  {
    ...toCol("Mother Tongue", "tongue", "Filter by Mother Tongue"),
    validate: (rowData) =>
      rowData.tongue === undefined || rowData.tongue === "" ? "Required" : true,
  },
  {
    ...toCol("Note", "note", "Filter by Note"),
    render: (v) => (
      <span>
        {v.note?.slice(0, 40)}
        {v?.note?.length > 50 ? "..." : ""}
      </span>
    ),
    validate: (rowData) =>
      rowData.note === undefined || rowData.note === "" ? "Required" : true,
  },
];

const localization = {
  body: {
    emptyDataSourceMessage: <Amina.Term404 />,
  },
};

const components = {
  Container: (props) => (
    <Paper {...props} elevation={0} style={{ padding: 18 }} />
  ),
};

const base_setup = {
  title: "Choose a Word or Terminology from Glossary",
  components,
  columns,
  localization,
  options: {
    sorting: true,

    // searching start
    searchAutoFocus: true,
    //   searchFieldAlignment:"left",
    searchFieldVariant: "outlined",
    searchFieldStyle: {
      marginBottom: 15,
    },
    // searching end

    // filtering individual columns
    filtering: true,

    // pagination start
    pageSizeOptions: [5, 10, 20, 25, 50, 100],
    pageSize: 10,
    // paginationType: "stepped",
    // pagination end

    // grouping column
    grouping: true,

    // filter columns by column selection
    columnsButton: true,

    // add data start
    addRowPosition: "first",
    actionsColumnIndex: -1,
    // add data  end

    // table row style
    rowStyle: (data, index) =>
      index % 2 === 0 ? { backgroundColor: "#f5f5f5" } : null,
    headerStyle: { backgroundColor: "#d6bcfa", color: "#932AAD" },
  },
};

export default StickyHeadTable;
