import { Route } from "react-router-dom";
import SignIn from "./sign_in";
import SignUp from "./sign_up";

const Expo = {
  SignIn: <Route exact path="/login" component={SignIn} />,
  SignUp: <Route exact path="/register" component={SignUp} />,
};

export default Expo;
