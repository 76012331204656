import React, { useEffect } from "react";
import Controls from "../../component/Hoc/Controls";

import action from "../../action";
import Hoc from "../../component/Hoc";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router";

const ReviewEdit = () => {
  const { id } = useParams();
  const review_act = action.review;
  const dispatch = useDispatch();
  const H = useHistory();

  const { Form, useForm } = Hoc.useForm;
  const formData = useForm(initData());
  const { vals, onChange, setVals, resetForm } = formData;

  useEffect(() => {
    const SetVV = async () =>
      setVals(initData(await dispatch(review_act.view(id))));
    SetVV();
  }, [dispatch, setVals, review_act, id]);

  const onSubmit = async () => {
    const status = await dispatch(
      review_act.view_edit(id, vals, `TranslationId${id}`)
    );

    if (!!status) {
      H.goBack();
      dispatch(action.OOP("Review Updated"));
    }
  };

  return (
    <Hoc.FormContainer title="Edit Review">
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{ disabled: !!CKF01(vals) }}
      >
        <Controls.Input {...setup.text(vals.text, onChange)} />
        <Controls.Input {...setup.url(vals.url, onChange)} />
        <Controls.Input {...setup.rating(vals.rating, onChange)} />
      </Form>
    </Hoc.FormContainer>
  );
};

const CKF01 = (v) => !v?.text || !v?.rating || 1 > v?.rating || 5 < v?.rating;

const setup = {
  text: (v, OC) => Hoc.setupText(v, OC, "text", "Message"),
  url: (v, OC) => Hoc.setupText(v, OC, "url", "URL"),
  rating: (v, OC) => ({
    ...Hoc.setupText(v, OC, "rating", "Rating (1 to 5)", "number"),
    max: 5,
    min: 1,
  }),
};

const initData = (v) => ({
  text: v?.text ? v?.text : null,
  url: v?.url ? v?.url : null,
  rating: v?.rating ? v?.rating : 1,
});

export default ReviewEdit;
