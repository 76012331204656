import types from "./types";

const defState = {
  loading: false,
  data: null,
  error: null,
};

const defListState = {
  loading: false,
  data: [],
  error: null,
};

export const view_all = {
  name: "view_all",
  state: defListState,
  request: types.TRANSLATE_LIST_REQUEST,
  success: types.TRANSLATE_LIST_SUCCESS,
  fail: types.TRANSLATE_LIST_FAIL,
};

export const view = {
  name: "view",
  state: defState,
  request: types.TRANSLATE_VIEW_REQUEST,
  success: types.TRANSLATE_VIEW_SUCCESS,
  fail: types.TRANSLATE_VIEW_FAIL,
};

export const view_add = {
  name: "add",
  state: defState,
  request: types.TRANSLATE_ADD_REQUEST,
  success: types.TRANSLATE_ADD_SUCCESS,
  fail: types.TRANSLATE_ADD_FAIL,
};

export const view_edit = {
  name: "edit",
  state: defState,
  request: types.TRANSLATE_EDIT_REQUEST,
  success: types.TRANSLATE_EDIT_SUCCESS,
  fail: types.TRANSLATE_EDIT_FAIL,
};

export const view_delete = {
  name: "delete",
  state: defState,
  request: types.TRANSLATE_DELETE_REQUEST,
  success: types.TRANSLATE_DELETE_SUCCESS,
  fail: types.TRANSLATE_DELETE_FAIL,
};
