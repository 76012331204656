import React, { useEffect } from "react";
import AddReview from "./Forms/AddReview";
import action from "../../../action";
import Hoc from "../../Hoc";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@material-ui/core";

const Review = ({ TranslationId, root }) => {
  // Defined review file
  const review_act = action.review;

  const dispatch = useDispatch();
  let data = useSelector(({ review: T }) => T.view_all.data);

  const CKR = (v) => v === root?.role;
  const isAdmin = !!(
    CKR("Manager") || !!(CKR("Admin") && !!root?.admin_status)
  );
  const isValid = (id) => (isAdmin ? true : id === root?.id);

  data = Hoc.isArr(data).filter((v) =>
    !!v?.enabled ? true : isValid(v?.UserId)
  );

  const qri = `TranslationId=${TranslationId}`;
  // get all data
  useEffect(() => {
    TranslationId && dispatch(review_act.view_all(qri));
  }, [dispatch, qri, TranslationId, review_act]);

  const refresh = () => dispatch(review_act.view_all(qri));

  if (!TranslationId) {
    return (
      <Typography align="center" variant="h5" color="textSecondary">
        Reviews are not allowed for this Item.
      </Typography>
    );
  }

  const acts = (vl) => {
    const TOC = (name, onClick) => ({ name, onClick });
    const TOT = (name, to) => ({ name, to });
    const isOwner = root?.id === vl?.UserId;
    // on Publish
    const onEnableToggler = () => {
      dispatch(review_act.view_edit(vl?.id, { enabled: !vl?.enabled }, qri));
      refresh();
      const msg = `Report ${!vl?.enabled ? "Dis" : "En"}abled Successfully.`;
      dispatch(action.OOP(msg));
    };
    // on delete
    const deleteReview = async () => {
      const status = await dispatch(review_act.view_delete(vl?.id, qri));
      refresh();
      if (status) dispatch(action.OOP("Successfully deleted review"));
    };

    const repoIssue = TOT("Report an Issue", `/report-add?ReviewId=${vl?.id}`);
    const repoView = TOT("All Reports", `/report-list/${vl?.id}`);
    const publishItem = TOC(`${!vl?.enabled ? "P" : "Unp"}ublish`, () =>
      onEnableToggler()
    );
    const editItem = TOT("Edit", `/review-edit/${vl?.id}`);
    const deleteItem = TOC("Delete", () => deleteReview());

    const items = [repoIssue];

    if (isAdmin) {
      items.push(publishItem);
      items.push(repoView);
    }
    if (isOwner) items.push(editItem);
    if (isOwner || isAdmin) items.push(deleteItem);

    return items;
  };

  return (
    <div>
      {Hoc.isArr(data)
        .filter((vl) => vl?.TranslationId === TranslationId)
        .map((vl) => (
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={9}>
              <Box {...Hoc.flex()} flexDirection="column">
                <Box style={{ width: "100%" }}>
                  <Hoc.Typo02 content={vl.text} />
                </Box>
                <Box style={{ width: "100%" }}>
                  <Hoc.Typo
                    component="a"
                    content={vl?.url}
                    href={
                      `${vl?.url}`.includes("http")
                        ? vl?.url
                        : `https://${vl?.url}`
                    }
                    rel="noreferrer"
                    target="_blank"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box {...Hoc.flex()} flexDirection="column">
                <Hoc.MenuButton actions={acts(vl)} />
              </Box>
            </Grid>
          </Grid>
        ))}

      {/* add new review */}
      {!Hoc.isArr(data).some((vl) => vl?.UserId === root.id) && (
        <AddReview refresh={refresh} TranslationId={TranslationId} />
      )}
    </div>
  );
};

export default Review;
