import React, { useEffect } from "react";
import Hoc from "../../component/Hoc";
import action from "../../action";
import Custom from "../../component/Custom";
import ControlsInput from "../../component/Hoc/Controls/Input";

import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";

const PlanList = () => {
  const act_plan = action.plan;
  const dispatch = useDispatch();
  const data = useSelector(({ plan: P }) => P.view_all.data);

  const [filterFn, setFilterFn] = React.useState(Hoc.onSearch.action);
  const onSearch = ({ target: T }) =>
    Hoc.onSearch.search(T?.value, setFilterFn);

  const TBL = Custom.useTable(data, cells, filterFn);
  const { TblContainer, finalRecords, TableRow, TableCell } = TBL;

  useEffect(() => {
    dispatch(act_plan.view_all());
  }, [dispatch, act_plan]);

  const refresh = () => dispatch(act_plan.view_all());

  const nav_01 = {
    title: "Subscription Plans",
    actions: [
      { name: "Create New", to: "/plan-add" },
      { name: "Back", goBack: true },
    ],
  };

  const t_acts = (v) => {
    let items = [];

    const onDelete = () => {
      if (
        window.confirm("Are you sure, You want to delete the Item.") === true
      ) {
        dispatch(act_plan.view_delete(v?.id));
        refresh();
      }
    };

    items = [
      { name: "Edit", to: `/plan-edit/${v.id}` },
      { name: "Delete", onClick: onDelete },
    ];
    //

    return items;
  };

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Box style={{ width: "100%", margin: "20px 0" }} {...Hoc.flex()}>
        <Box style={{ width: 200 }}>
          <ControlsInput label="Search" size="small" onChange={onSearch} />
        </Box>
      </Box>

      {!finalRecords().length && <Hoc.EmptyResource />}
      {!!finalRecords().length && (
        <TblContainer paginate>
          {finalRecords().map((v) => (
            <TableRow key={v.id}>
              <TableCell content={v?.name} />
              <TableCell content={v?.days} />
              <TableCell content={v?.months} />
              <TableCell content={v?.price} />
              <TableCell content={v?.UserId} />
              <TableCell content={<Hoc.MenuButton actions={t_acts(v)} />} />
            </TableRow>
          ))}
        </TblContainer>
      )}
    </Hoc.Dashboard.Container>
  );
};

const TOC = (id, label, disableSorting = false) => ({
  id,
  label,
  disableSorting,
});

const cells = [
  TOC("name", "Name"),
  TOC("days", "Days"),
  TOC("months", "Months"),
  TOC("price", "Price"),
  TOC("UserId", "Owner Id"),
  TOC("07", "Actions", true),
];

export default PlanList;
