import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Hoc from "..";
import { useHistory } from "react-router-dom";

const RefreshWithBack = ({ actions }) => {
  const css = useCSS();
  const H = useHistory();

  const onAction = ({ name, to, action }) => {
    if (name === "Go Back") H.goBack();
    else if (!!to) H.push(to);
    else if (!!action) action();
  };

  return (
    <Box className={css.topActions}>
      {actions?.map((A, idx) => (
        <CustomBtn key={idx} mx content={A?.name} onClick={() => onAction(A)} />
      ))}
    </Box>
  );
};

const CustomBtn = (props) => {
  const { content, icon, secondary = false, ...rest } = props;
  const { variant = "contained", mx, ...rest02 } = rest;
  return (
    <Button
      {...(mx && { style: { margin: 10 } })}
      {...(icon && { startIcon: icon })}
      variant={variant}
      color={!secondary ? "primary" : "secondary"}
      size="small"
      {...rest02}
    >
      {content}
    </Button>
  );
};

const useCSS = makeStyles((theme) => ({
  topActions: {
    ...Hoc.flex(),
    flexWrap: "wrap",
    margin: theme.spacing(2, 0.5),
  },
}));
export default RefreshWithBack;
