import React from "react";
import Hoc from "../../../component/Hoc";
import Controls from "../../../component/Hoc/Controls";
import action from "../../../action";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const AddEmailSetting = ({ id }) => {
  const { useForm, Form } = Hoc.useForm;
  const H = useHistory();
  const act_email = action.footer;
  const dispatch = useDispatch();
  const { vals, setVals, onChange, resetForm } = useForm(initData());

  const onSubmit = () => {
    dispatch(act_email.view_add(vals));
    H.goBack();
  };

  useEffect(() => {
    const setT = async () =>
      setVals(initData(await dispatch(act_email.view_email())));
    setT();
  }, [dispatch, act_email, setVals]);

  const title = !id ? "Create New Term" : "Updating Term";

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{ disabled: !!CKF01(vals) }}
      >
        <Controls.SearchSelect {...setup.protocol(vals?.protocol, onChange)} />
        <Controls.Input {...setup.username(vals?.username, onChange)} />
        <Controls.Input {...setup.from(vals?.from, onChange)} />
        <Controls.Input {...setup.password(vals?.password, onChange)} />
        <Controls.Input {...setup.imap_port(vals?.imap_port, onChange)} />
        <Controls.Input {...setup.pop3_port(vals?.pop3_port, onChange)} />
        <Controls.Input {...setup.smtp_host(vals?.smtp_host, onChange)} />
      </Form>
    </Hoc.FormContainer>
  );
};

const CKF01 = (v) =>
  !v?.username ||
  !v?.from ||
  !v?.password ||
  !v?.imap_port ||
  !v?.pop3_port ||
  !v?.smtp_host;

const initData = (v) => ({
  protocol: v?.protocol ? v?.protocol : null,
  username: v?.username ? v?.username : null,
  from: v?.from ? v?.from : null,
  password: v?.password ? v?.password : null,
  imap_port: v?.imap_port ? v?.imap_port : null,
  pop3_port: v?.pop3_port ? v?.pop3_port : null,
  smtp_host: v?.smtp_host ? v?.smtp_host : null,
  category: "Email Settings",
});

const setup = {
  protocol: (N, OC) =>
    Hoc.setupSelect(N, OC, "protocol", "Protocol", [
      { id: "imap", title: "IMAP" },
      { id: "pop3", title: "POP3" },
    ]),
  username: (N, OC) => Hoc.setupText(N, OC, "username", "Username"),
  from: (N, OC) => Hoc.setupText(N, OC, "from", "From Text"),
  password: (N, OC) => Hoc.setupText(N, OC, "password", "Password"),
  imap_port: (N, OC) => Hoc.setupText(N, OC, "imap_port", "IMAP Port"),
  pop3_port: (N, OC) => Hoc.setupText(N, OC, "pop3_port", "POP3 Port"),
  smtp_host: (N, OC) => Hoc.setupText(N, OC, "smtp_host", "SMTP Host"),
};

export default AddEmailSetting;
