import React, { useEffect } from "react";
import Hoc from "../../component/Hoc";
import action from "../../action";
import Custom from "../../component/Custom";
import { useDispatch, useSelector } from "react-redux";

const ListView = () => {
  const act_footer = action.footer;

  const dispatch = useDispatch();
  const { data, error, loading } = useSelector(({ footer: P }) => P.list);

  useEffect(() => {
    dispatch(act_footer.view_all());
  }, [dispatch, act_footer]);

  const actions = [
    { name: "Create a Link", to: "/add-footer-link" },
    { name: "Back", goBack: true },
  ];
  const nav_01 = {
    title: "Footer Links",
    actions,
  };

  const TBL = Custom.useTable(data, cells);
  const { TblContainer, finalRecords, TableRow, TableCell } = TBL;

  const onDelete = (id) => {
    if (window.confirm("Are you sure, You want to delete the Item.") === true) {
      dispatch(act_footer.view_delete(id));
      dispatch(act_footer.view_all());
    }
  };

  const acts = (id) => [
    { name: "Edit", to: `/edit-footer-link/${id}` },
    { name: "Delete", onClick: () => onDelete(id) },
  ];

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Hoc.UseData error={error} loading={loading}>
        {!finalRecords().length && <Hoc.EmptyResource />}
        {!!finalRecords().length && (
          <TblContainer paginate>
            {finalRecords().map((vl) => (
              <TableRow key={vl.id}>
                <TableCell content={vl.category} />
                <TableCell content={vl.name} />
                <TableCell content={vl.url} />
                <TableCell
                  content={<Hoc.MenuButton actions={acts(vl?.id)} />}
                />
              </TableRow>
            ))}
          </TblContainer>
        )}
      </Hoc.UseData>
    </Hoc.Dashboard.Container>
  );
};

const TOC = (id, label, disableSorting = false) => ({
  id,
  label,
  disableSorting,
});

const cells = [
  TOC("category", "Category"),
  TOC("name", "Name"),
  TOC("url", "URL"),
  TOC("07", "Actions", true),
];

export default ListView;
