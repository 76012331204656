import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Hoc from "../../component/Hoc";
import action from "../../action";
import Controls from "../../component/Hoc/Controls";
import { useDispatch } from "react-redux";
import { Box } from "@material-ui/core";

const FooterForm = ({ id }) => {
  const H = useHistory();
  const act_footer = action.footer;

  const dispatch = useDispatch();
  const { Form, useForm } = Hoc.useForm;
  const formData = useForm(initData());
  const { vals, setVals, onChange, resetForm } = formData;
  const gnLink = vals?.category === "Normal Link";
  const title = `${!id ? "Update" : "Create"} Footer Link`;

  const onSubmit = async () => {
    const finalData = initData({
      ...vals,
      photo: !gnLink ? null : vals?.photo,
    });

    if (!!id) dispatch(act_footer.view_edit(id, finalData));
    else dispatch(act_footer.view_add(finalData));

    dispatch(
      action.OOP(`Details ${!id ? "Updated" : "Created"} Successfully.`)
    );
    H.goBack();
  };

  useEffect(() => {
    const SetVV = async () =>
      setVals(initData(await dispatch(act_footer.view(id))));
    !!id && SetVV();
  }, [setVals, dispatch, act_footer, id]);

  return (
    <Hoc.FormContainer title={title}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{
          disabled: setup_status(vals),
        }}
      >
        <Controls.SearchSelect {...setup.category(vals?.category, onChange)} />
        <Controls.Input {...setup.name(vals?.name, onChange)} />
        <Controls.Input {...setup.url(vals?.url, onChange)} />
        {gnLink && (
          <Box {...Hoc.flex()}>
            <Controls.FileUpload {...setup.photo(vals?.photo, onChange)} />
          </Box>
        )}
      </Form>
    </Hoc.FormContainer>
  );
};

const setup_status = (v) => !v?.category;

const sel01 = ["Normal Link", "Social Link"];

const toSel = (v) =>
  v.map((id) => ({
    id,
    title: id,
  }));

const setup = {
  category: (v, OC) =>
    Hoc.setupSelect(v, OC, "category", "category", toSel(sel01)),
  photo: (v, OC) => Hoc.setupFile(v, OC, "photo", "photo"),
  name: (v, OC) => Hoc.setupText(v, OC, "name", "name"),
  url: (v, OC) => Hoc.setupText(v, OC, "url", "url"),
};

const initData = (v) => ({
  category: v?.category ? v?.category : null,
  photo: v?.photo ? v?.photo : null,
  name: v?.name ? v?.name : null,
  url: v?.url ? v?.url : null,
});

export const AddLink = () => <FooterForm />;
export const EditLink = () => {
  const P = useParams();
  return <FooterForm id={P.id} />;
};

export default FooterForm;
