import React from "react";
import { TextField as MuiTextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Input = (p0) => {
  const { variant, fullWidth, type, label, ...p1 } = p0;

  const { name, value, onChange, dir_rtl, ...rest } = p1;

  const css = useCSS();
  return (
    <MuiTextField
      variant={variant || "outlined"}
      label={label}
      name={name}
      value={value}
      fullWidth={fullWidth || true}
      type={type}
      onChange={onChange}
      className={!dir_rtl ? "" : css.root}
      {...rest}
    />
  );
};

const useCSS = makeStyles((theme) => ({
  root: {
    direction: "rtl",
  },
}));

export default Input;
