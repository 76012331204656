import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import React, { useEffect } from "react";
import Hoc from "../../../component/Hoc";
import action from "../../../action";
import { useDispatch, useSelector } from "react-redux";

const View = () => {
  const css = useCSS();
  const dispatch = useDispatch();
  const act_view_email = action.footer.view_email;
  const emailData = useSelector(({ footer: F }) => F.email.data);

  useEffect(() => {
    dispatch(act_view_email());
  }, [dispatch, act_view_email]);

  const nav_01 = {
    title: "Email Settings",
    actions: [
      { name: "Update Settings", to: "/email-settings" },
      { name: "Back", goBack: true },
    ],
  };
  return (
    <Hoc.Dashboard.Container {...nav_01}>
      {!emailData ? (
        <Hoc.Typo
          content="Please add email settings"
          variant="h4"
          color="textSecondary"
          align="center"
        />
      ) : (
        <Card className={css.cardRoot}>
          <CardContent>
            <Hoc.Typo
              content="Email Settings"
              variant="h4"
              color="primary"
              align="center"
            />
            <Hoc.Typo02 title="Protocol" content={emailData?.protocol} />
            <Hoc.Typo02 title="Username" content={emailData?.username} />
            <Hoc.Typo02 title="From Text" content={emailData?.from} />
            <Hoc.Typo02 title="Password" content={emailData?.password} />
            <Hoc.Typo02 title="IMAP Port" content={emailData?.imap_port} />
            <Hoc.Typo02 title="POP3 Port" content={emailData?.pop3_port} />
            <Hoc.Typo02 title="SMTP Host" content={emailData?.smtp_host} />
          </CardContent>
        </Card>
      )}
    </Hoc.Dashboard.Container>
  );
};

const useCSS = makeStyles((theme) => ({
  cardRoot: {
    minWidth: 500,
    //
  },
}));

export default View;
