import React, { useState } from "react";
import GoogleReCAPTCHA from "react-google-recaptcha";

export const useReCAPTCHA = () => {
  const [reCaptcha, setReCaptcha] = useState(false);

  const handleReCaptcha = async () => {
    setReCaptcha(true);
  };

  return { reCaptcha, handleReCaptcha };
};

const ReCAPTCHA = ({ handleReCaptcha }) => {
  return (
    <GoogleReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={handleReCaptcha} />
  );
};

const { REACT_APP_RECAPTCHA_SITE_KEY: RECAPTCHA_SITE_KEY } = process.env;

export default ReCAPTCHA;
