import React from "react";
import { Paper, Container, Box } from "@material-ui/core";
import { Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "./Logo";
import { useHistory } from "react-router-dom";

const FormContainer = (props) => {
  const { children, maxWidth = "sm", title, subtitle } = props;
  const { titleColor = "primary", noGoBack, logo } = props;

  const CSS = useCSS();
  const h = useHistory();

  const paperData = { className: CSS.root, component: Container, maxWidth };

  const noGoBackItem = (
    <Typography
      variant="subtitle2"
      onClick={() => h.goBack()}
      style={{ cursor: "pointer" }}
    >
      Back
    </Typography>
  );

  return (
    <Paper {...paperData}>
      {!noGoBack && noGoBackItem}

      {title && (
        <React.Fragment>
          <Box className={"flex-center flex-col"} style={{ marginBottom: 15 }}>
            <span>{!!logo ? logo : <Logo size={80} />}</span>
            <Typography variant="h5" color={titleColor}>
              {title}
            </Typography>
            {!!subtitle && (
              <Typography variant="subtitle2" color="textSecondary">
                {subtitle}
              </Typography>
            )}
          </Box>
          <Divider />
        </React.Fragment>
      )}
      {children}
    </Paper>
  );
};

const useCSS = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));

export default FormContainer;
