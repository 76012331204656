import ViewAll from "./view_all";
import ViewAdd from "./add";
import ViewEdit from "./edit";
import SecureRoute from "../SecureRoute";
import email_setup from "./email-setup";

const Expo = {
  email_setup,
  list: (
    <SecureRoute grant={["Manager"]} path="/template" component={ViewAll} />
  ),
  add: (
    <SecureRoute grant={["Manager"]} path="/template-add" component={ViewAdd} />
  ),
  edit: (
    <SecureRoute
      grant={["Manager"]}
      path="/template-edit/:id"
      component={ViewEdit}
    />
  ),
};

export default Expo;
