import { Box } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import Hoc from "../../component/Hoc";
import action from "../../action";
import Controls from "../../component/Hoc/Controls";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const BankForm = ({ id, method }) => {
  const act = action.bank;
  const H = useHistory();
  const dispatch = useDispatch();
  const { Form, useForm } = Hoc.useForm;
  const { vals, setVals, onChange, resetForm } = useForm(initData({}, method));

  const onSubmit = () => {
    if (!id) {
      dispatch(act.view_add(vals));
    } else {
      dispatch(act.view_edit(id, vals));
    }
    H.goBack();
  };

  useEffect(() => {
    const setVV = async () => {
      const dt = await dispatch(act.view(id));
      setVals(initData(dt));
    };
    !!id && setVV();
  }, [id, dispatch, act, setVals]);

  const isPayPal = vals?.category === "PayPal";

  return (
    <Hoc.FormContainer title={`${!id ? "Add" : "Update"} Payment Details`}>
      <Form
        onSubmit={onSubmit}
        resetForm={resetForm}
        submitButton={{ disabled: !vals?.category }}
      >
        <Box {...Hoc.flex("center")}>
          <Controls.Switch {...setup.enabled(vals?.enabled, onChange)} />
        </Box>
        <Controls.SearchSelect {...setup.category(vals?.category, onChange)} />

        <Controls.Input {...setup.email(vals?.email, onChange)} />
        <Controls.Input {...setup.currency(vals?.currency, onChange)} />
        <Controls.Input {...setup.api(vals?.api, onChange)} />
        {!isPayPal ? (
          <>
            <Controls.Input
              {...setup.publish_key(vals?.publish_key, onChange)}
            />
            <Controls.Input {...setup.secret_key(vals?.secret_key, onChange)} />
          </>
        ) : (
          <>
            <Controls.Input {...setup.client_id(vals?.client_id, onChange)} />
            <Controls.Input {...setup.secret(vals?.secret, onChange)} />
          </>
        )}
      </Form>
    </Hoc.FormContainer>
  );
};

const initData = (v, cat) => ({
  category: v?.category ? v?.category : cat ? cat : null,
  enabled: v?.enabled ? v?.enabled : null,
  email: v?.email ? v?.email : null,
  currency: v?.currency ? v?.currency : null,
  secret: v?.secret ? v?.secret : null,
  api: v?.api ? v?.api : null,
  client_id: v?.client_id ? v?.client_id : null,
  publish_key: v?.publish_key ? v?.publish_key : null,
  secret_key: v?.secret_key ? v?.secret_key : null,
});
const setup = {
  email: (N, OC) => Hoc.setupText(N, OC, "email", "email", "email"),
  currency: (N, OC) => Hoc.setupText(N, OC, "currency", "Currency"),
  secret: (N, OC) => Hoc.setupText(N, OC, "secret", "Secret"),
  api: (N, OC) => Hoc.setupText(N, OC, "api", "API"),
  client_id: (N, OC) => Hoc.setupText(N, OC, "client_id", "Client ID"),
  publish_key: (N, OC) => Hoc.setupText(N, OC, "publish_key", "Publish Key"),
  secret_key: (N, OC) => Hoc.setupText(N, OC, "secret_key", "Secret Key"),
  //
  enabled: (N, OC) => Hoc.setupSwitch(N, OC, "enabled", "Enable the service"),
  category: (N, OC) =>
    Hoc.setupSelect(N, OC, "category", "Category", [
      { id: "PayPal", title: "PayPal" },
      { id: "Stripe", title: "Stripe" },
    ]),
};

export const AddBank = () => {
  const P = useParams();
  return <BankForm method={P.method} />;
};

export const EditBank = () => {
  const P = useParams();
  return <BankForm id={P?.id} />;
};
