import axios from "axios";
import Helper from "../helper";
import { Type } from "../../redux";
const types = Type.translation_types;

const URL = `${Helper.URL}/translate`;

const _list = async (dispatch, qri = "") => {
  try {
    dispatch({ type: types.TRANSLATE_LIST_REQUEST });
    const { data } = await axios.get(`${URL}?${qri}`);
    dispatch({ type: types.TRANSLATE_LIST_SUCCESS, payload: data?.result });
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TRANSLATE_LIST_FAIL);
  }
};

export const _load_trans = _list;

export const view_all = (qri) => async (dispatch) => await _list(dispatch, qri);

export const view_add = (initData, qri) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TRANSLATE_ADD_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.post(URL, initData);
    dispatch({ type: types.TRANSLATE_ADD_SUCCESS, payload: data?.result });
    await _list(dispatch, qri);
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TRANSLATE_ADD_FAIL);
  }
};

export const view = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.TRANSLATE_VIEW_REQUEST });
    const { data } = await axios.get(`${URL}/${id}`);
    dispatch({ type: types.TRANSLATE_VIEW_SUCCESS, payload: data?.result });
    return data?.result;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TRANSLATE_VIEW_FAIL);
  }
};

export const view_edit = (id, initData, qri) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TRANSLATE_EDIT_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.put(`${URL}/${id}`, initData);
    dispatch({ type: types.TRANSLATE_EDIT_SUCCESS, payload: data?.result });
    await _list(dispatch, qri);
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TRANSLATE_EDIT_FAIL);
  }
};

export const view_delete = (id, qri) => async (dispatch, state) => {
  try {
    dispatch({ type: types.TRANSLATE_DELETE_REQUEST });
    Helper.setJwtFromState(state());
    const { data } = await axios.delete(`${URL}/${id}`);
    dispatch({ type: types.TRANSLATE_DELETE_SUCCESS, payload: data?.result });
    await _list(dispatch, qri);
    return data;
  } catch (err) {
    Helper.errResponse(err, dispatch, types.TRANSLATE_DELETE_FAIL);
  }
};
