import React, { useEffect } from "react";
import Hoc from "../../Hoc";
import Controls from "../../Hoc/Controls";
import action from "../../../action";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA, { useReCAPTCHA } from "../ReCAPTCHA";
import { Box, Button } from "@material-ui/core";

const Login = ({ role, to }) => {
  const { reCaptcha, handleReCaptcha } = useReCAPTCHA();
  const login = action.user.login;
  const dispatch = useDispatch();
  const H = useHistory();
  const { Form, useForm } = Hoc.useForm;
  const fmData = useForm(initData(role));
  const { vals, setVals, onChange, resetForm } = fmData;

  const root = useSelector(({ user: P }) => P.login.data);

  useEffect(() => {
    if (role === root?.role) {
      H.push("/glossary");
    }
  }, [root, H, role]);

  const onSubmit = async () => {
    const status = await dispatch(login(vals));

    if (!!status) {
      dispatch(action.OOP("Login Success."));
      setVals(initData(role));
    }
  };

  const endContent = (
    <Hoc.Typo align="right">
      Don't have an account? <Link to={`/${to}register`}>Register.</Link>
    </Hoc.Typo>
  );

  const fmApply = {
    endContent,
    onSubmit,
    resetForm,
    submitButton: {
      disabled: sstatus(vals) || !reCaptcha,
    },
  };

  const UserExists = () => (
    <Box width="100%" {...Hoc.flex()} flexDirection="column">
      <Hoc.Typo
        align="center"
        variant="h6"
        color="textPrimary"
        content={`Hi ${root?.title || ""} ${root?.first_name || ""} ${
          root?.last_name || ""
        }, You have already logged in as ${
          root?.role
        } and to login as ${role} please logout and login as ${role} again.`}
      />
      <Button
        style={{ marginTop: 10 }}
        variant="contained"
        color="secondary"
        onClick={() => dispatch(action.user.logout())}
      >
        Logout
      </Button>
    </Box>
  );

  return (
    <Hoc.FormContainer title={`${role} Login.`}>
      {!root?.token ? (
        <Form {...fmApply}>
          <Controls.Input {...setup.email(vals?.email, onChange)} />
          <Controls.Input {...setup.password(vals?.password, onChange)} />
          <ReCAPTCHA handleReCaptcha={handleReCaptcha} />
          <Link to={`/forgot-password/${role}`}>
            <Hoc.Typo color="error">Forgot Password?</Hoc.Typo>
          </Link>
        </Form>
      ) : (
        <UserExists />
      )}
    </Hoc.FormContainer>
  );
};

const sstatus = (v) => !v?.role || !v?.email || `${v?.password}`.length < 6;

const setup = {
  email: (v, onChange) =>
    Hoc.setupText(v, onChange, "email", "Email Address", "email"),
  password: (v, onChange) => ({
    ...Hoc.setupText(v, onChange, "password", "Password", "password"),
    inputProps: {
      minLength: 6,
    },
  }),
};

const initData = (role) => ({
  role,
  email: "",
  password: "",
});

export default Login;
