import React from "react";
import Hoc from "../../../component/Hoc";
import Controls from "../../../component/Hoc/Controls";
import action from "../../../action";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Login = () => {
  const P = useParams();
  const act_psw = action.user.reset_password;
  const dispatch = useDispatch();
  const [token, role] = `${P?.resetToken}`.split("--");
  const H = useHistory();
  const { Form, useForm } = Hoc.useForm;
  const fmData = useForm({ password: "", password2: "" });
  const { vals, setVals, onChange, resetForm } = fmData;

  const onSubmit = async () => {
    const status = await dispatch(act_psw({ token, password: vals?.password }));

    if (!!status) {
      dispatch(action.OOP("Password Submitted."));
      setVals({ password: "", password2: "" });
      H.push(urls.find((v) => v.role === role)?.url);
    }
  };

  const fmApply = {
    onSubmit,
    resetForm,
    submitButton: {
      disabled:
        `${vals?.password}`.length < 6 || vals?.password !== vals?.password2,
    },
  };

  return (
    <Hoc.FormContainer title={"Reset Password"}>
      <Form {...fmApply}>
        <Controls.Input {...setup.password(vals?.password, onChange)} />
        <Controls.Input {...setup.password2(vals?.password2, onChange)} />
      </Form>
    </Hoc.FormContainer>
  );
};

const setup = {
  password: (v, OC) => Hoc.setupText(v, OC, "password", "Password", "password"),
  password2: (v, OC) =>
    Hoc.setupText(v, OC, "password2", "Confirm Password", "password"),
};

const urls = [
  { role: "User", url: "/login" },
  { role: "Subscriber", url: "/subscriber-login" },
  { role: "Guest Editor", url: "/guest-login" },
  { role: "Admin", url: "/admin-login" },
  { role: "Manager", url: "/supreme-login" },
];

export default Login;
