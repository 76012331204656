import { Box, Button } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import action from "../../../../action";
import Hoc from "../../../Hoc";
import Controls from "../../Controls";

const AddReview = ({ TranslationId, refresh }) => {
  const [rvToggle, setRvToggle] = useState(false);

  const dispatch = useDispatch();
  const act_review = action.review;
  const { Form, useForm } = Hoc.useForm;
  const { vals, setVals, onChange, resetForm } = useForm(initData());

  const onSubmit = async () => {
    const finalData = { ...vals, TranslationId };
    const status = await dispatch(act_review.view_add(finalData));

    if (status) {
      refresh();
      setVals(initData());
    }
  };

  return (
    <Box style={{ margin: "10px 0" }}>
      {TranslationId && (
        <Box {...Hoc.flex("flex-end")}>
          <Button
            variant="contained"
            onClick={() => setRvToggle(!rvToggle)}
            color={!rvToggle ? "primary" : "secondary"}
          >
            {!rvToggle ? "Add Review" : "cancel"}
          </Button>
        </Box>
      )}

      {TranslationId && rvToggle && (
        <Form
          onSubmit={onSubmit}
          resetForm={resetForm}
          submitButton={{ disabled: !!CKF01(vals) }}
        >
          <Controls.Input {...setup.text(vals?.text, onChange)} />
          <Controls.Input {...setup.url(vals?.url, onChange)} />
          <Controls.Input {...setup.rating(vals?.rating, onChange)} />
        </Form>
      )}
    </Box>
  );
};

const CKF01 = (v) => !v?.text || !v?.rating || 1 > v?.rating || 5 < v?.rating;

const setup = {
  text: (N, OC) => Hoc.setupLongText(N, OC, "text", "Text"),
  url: (N, OC) => Hoc.setupText(N, OC, "url", "Link"),
  rating: (v, ON) => ({
    ...Hoc.setupText(v, ON, "rating", "Rating (1 to 5)", "number"),
    inputProps: { min: 1, max: 5 },
  }),
};

const initData = (v) => ({
  text: v?.text ? v?.text : null,
  url: v?.url ? v?.url : null,
  rating: v?.rating ? v?.rating : null,
});

export default AddReview;
