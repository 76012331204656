import React, { useEffect, useState } from "react";
import Hoc from "../../component/Hoc";
import Controls from "../../component/Hoc/Controls";
import Custom from "../../component/Custom";
import { Box } from "@material-ui/core";
import action from "../../action";
import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  const [role, setRole] = useState("User");
  const dispatch = useDispatch();
  const act_user = action.user;

  const root = useSelector(({ user: P }) => P.login.data);
  const data = useSelector(({ user: P }) => P.list.data);

  const [filterFn, setFilterFn] = React.useState(Hoc.onSearch.action);
  const onSearch = ({ target: T }) =>
    Hoc.onSearch.search(T?.value, setFilterFn);

  const TBCells = role !== "Admin" ? cells : [...cells, actCell];
  const TBL = Custom.useTable(data, TBCells, filterFn);
  const { finalRecords, TblContainer, TableCell, TableRow } = TBL;

  useEffect(() => {
    dispatch(act_user.view_all(`role=${role}`));
  }, [dispatch, act_user, role]);

  const nav_01 = {
    title: `${role} Information`,
    actions: [{ name: "Back", goBack: true }],
  };

  const onStatusChange = (id, admin_status) => {
    dispatch(act_user.edit_user(id, { admin_status }, `role=${role}`));
    dispatch(act_user.view_all(`role=${role}`));
  };

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <Box
        style={{ width: "100%", margin: "20px 0" }}
        {...Hoc.flex("space-around")}
      >
        <Box style={{ width: 200 }}>
          <Controls.Input label="Search" size="small" onChange={onSearch} />
        </Box>
        <Box style={{ width: 150 }}>
          <Controls.SearchSelect {...setup.role(role, setRole, root?.role)} />
        </Box>
      </Box>
      {!finalRecords().length && <Hoc.EmptyResource />}
      {!!finalRecords().length && (
        <TblContainer paginate>
          {finalRecords().map((v) => (
            <TableRow key={v.id}>
              <TableCell content={v?.first_name} />
              <TableCell content={v?.last_name} />
              <TableCell content={v?.email} />
              <TableCell content={v?.country} />
              <TableCell content={v?.source_language} />
              <TableCell content={v?.target_language} />
              {role === "Admin" && (
                <TableCell
                  content={
                    <Controls.Switch
                      checked={!!v?.admin_status}
                      name="admin_status"
                      onChange={() => onStatusChange(v.id, !v?.admin_status)}
                    />
                  }
                />
              )}
            </TableRow>
          ))}
        </TblContainer>
      )}
    </Hoc.Dashboard.Container>
  );
};

const TOT = (id) => ({ id, title: id });

const setup = {
  role: (v, setVV, role) => ({
    name: "role",
    value: !!v ? v : "",
    onChange: ({ target: T }) => setVV(T.value),
    label: "Select Role",
    options: getList(role),
  }),
};

const TOC = (id, label) => ({ id, label });

const cells = [
  TOC("first_name", "First Name"),
  TOC("last_name", "Last Name"),
  TOC("email", "Email"),
  TOC("country", "Country"),
  TOC("source_language", "Source Language"),
  TOC("target_language", "Target Language"),
];
const actCell = { id: "07", label: "Admin Status", disableSorting: true };

const getList = (role) => {
  let items = [];

  const P1 = [TOT("User")];
  const P2 = [...P1, TOT("Subscriber"), TOT("Guest Editor")];
  const P3 = [...P2, TOT("Admin")];

  if (role === "Manager") {
    items = P3;
  } else if (role === "Admin") {
    items = P2;
  } else if (["Subscriber", "Guest Editor"].includes(role)) {
    items = P1;
  }

  return items;
};

export default Dashboard;
