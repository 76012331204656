import React from "react";
import Hoc from "../../../component/Hoc";
import Controls from "../../../component/Hoc/Controls";
import action from "../../../action";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const EditProfilePsw = () => {
  const act_user = action.user;
  const dispatch = useDispatch();
  const H = useHistory();
  const { useForm, Form } = Hoc.useForm;
  const fmData = useForm(initData);
  const { vals, setVals, onChange, resetForm } = fmData;

  const onSubmit = async () => {
    const finalDT = (({ password2, ...rest }) => rest)(vals);
    const status = await dispatch(act_user.edit_profile_psw(finalDT));

    if (!!status) {
      setVals(initData);
      dispatch(action.OOP("Password Changed."));
      const url = dispatch(act_user.logout());
      if (!!url) H.push(url);
    }
  };

  const fmApply = {
    onSubmit,
    resetForm,
    submitButton: { disabled: CKS(vals) },
  };

  return (
    <Hoc.FormContainer title="Update Password">
      <Form {...fmApply}>
        <Controls.Input {...setup.oldPassword(vals?.oldPassword, onChange)} />
        <Controls.Input {...setup.password(vals?.password, onChange)} />
        <Controls.Input {...setup.password2(vals?.password2, onChange)} />
      </Form>
    </Hoc.FormContainer>
  );
};

const CKS = (v) =>
  !v?.oldPassword ||
  `${v?.password}`.length < 6 ||
  v?.password !== v?.password2;

const SetPSW = (V, OC, Nm, Lb) => Hoc.setupText(V, OC, Nm, Lb, "password");
const setup = {
  oldPassword: (v, OC) => SetPSW(v, OC, "oldPassword", "Old Password"),
  password: (v, OC) => SetPSW(v, OC, "password", "New Password"),
  password2: (v, OC) => SetPSW(v, OC, "password2", "Confirm New Password"),
};

const initData = {
  oldPassword: "",
  password: "",
  password2: "",
};

export default EditProfilePsw;
