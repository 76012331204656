import { Button, Dialog, Divider } from "@material-ui/core";
import { DialogTitle, Typography } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { view_delete } from "../../../action/base01/opp";

const OopMessage = () => {
  const dispatch = useDispatch();

  const onClose = () => dispatch(view_delete());

  const oop_msg = useSelector(({ OOP }) => OOP);

  const color = !oop_msg?.status ? "primary" : "secondary";

  return (
    <Dialog
      open={!!oop_msg?.text}
      onClose={onClose}
      aria-labelledby="id01"
      aria-describedby="id02"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle
        id="id03"
        style={{ color: !oop_msg?.status ? "green" : "orangered" }}
      >
        {!oop_msg?.status ? "Success" : "Failed"}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="subtitle1">{oop_msg?.text}</Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color={color} onClick={onClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OopMessage;
