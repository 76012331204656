import { Route } from "react-router-dom";
import SignIn from "./sign_in";
import SignUp from "./sign_up";

const Expo = {
  SignIn: <Route exact path="/admin-login" component={SignIn} />,
  SignUp: <Route exact path="/admin-register" component={SignUp} />,
};

export default Expo;
