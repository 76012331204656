import React, { useEffect } from "react";
import Hoc from "../../component/Hoc";
import ControlsInput from "../../component/Hoc/Controls/Input";
import Custom from "../../component/Custom";
import action from "../../action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Button } from "@material-ui/core";

const UnPublished = () => {
  const dispatch = useDispatch();

  const reports = useSelector(({ report: P }) => P.view_all.data);

  useEffect(() => {
    dispatch(action.report.view_all(para));
    // eslint-disable-next-line
  }, [dispatch]);

  const nav_01 = {
    title: `Unsolved Reports`.toUpperCase(),
    actions: [{ name: "Back", goBack: true }],
  };

  return (
    <Hoc.Dashboard.Container {...nav_01}>
      <ReportTable data={reports} />
    </Hoc.Dashboard.Container>
  );
};
const para = "enabled=false";

const ReportTable = ({ data }) => {
  const H = useHistory();
  const dispatch = useDispatch();

  const [filterFn, setFilterFn] = React.useState(Hoc.onSearch.action);
  const onSearch = ({ target: T }) =>
    Hoc.onSearch.search(T?.value, setFilterFn);

  const TBL = Custom.useTable(data, reportCells, filterFn);
  const { TblContainer, finalRecords, TableRow, TableCell } = TBL;

  const onPublish = (id) => {
    if (window.confirm("Are you sure, You want to Update it.") === true) {
      dispatch(action.report.view_edit(id, { enabled: true }, para));
      dispatch(action.OOP("Report Solved Successfully."));
    }
  };

  const toUrl = (v) =>
    v?.ReviewId
      ? `/report-list/${v?.ReviewId}`
      : v?.TranslationId
      ? `/term-view/${v?.Translation?.TermId}?TranslationId=${v?.TranslationId}`
      : v?.TermId
      ? `/term-view/${v?.TermId}`
      : "";

  const withUrl = (v, text) => (
    <span onClick={() => H.push(toUrl(v))}>{text}</span>
  );

  return (
    <>
      <Box style={{ width: "100%", margin: "20px 0" }} {...Hoc.flex()}>
        <Box style={{ width: 200 }}>
          <ControlsInput label="Search" size="small" onChange={onSearch} />
        </Box>
      </Box>

      {!finalRecords().length && <Hoc.EmptyResource />}
      {!!finalRecords().length && (
        <TblContainer paginate>
          {finalRecords().map((v) => (
            <TableRow key={v.id}>
              <TableCell content={withUrl(v, v?.full_name)} />
              <TableCell content={withUrl(v, v?.email)} />
              <TableCell content={withUrl(v, v?.contact)} />
              <TableCell content={withUrl(v, v?.reason)} />
              <TableCell
                content={<PublishBtn onClick={() => onPublish(v?.id)} />}
              />
            </TableRow>
          ))}
        </TblContainer>
      )}
    </>
  );
};

const TOC = (id, label, disableSorting = false) => ({
  id,
  label,
  disableSorting,
});

const reportCells = [
  TOC("full_name", "Full Name"),
  TOC("email", "Email"),
  TOC("contact", "Contact Number"),
  TOC("reason", "Reason"),
  TOC("07", "Actions", true),
];

const PublishBtn = ({ ...rest }) => (
  <Button variant="contained" size="small" color="primary" {...rest}>
    Mark as Solved
  </Button>
);

export default UnPublished;
