import React from "react";
import { ThemeProvider, colors } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import { makeStyles, createTheme } from "@material-ui/core/styles";

// Compoenet to put all page inside styles
const Theme = ({ children }) => {
  useCSS();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

// Modifying prebuilt styles
const theme = createTheme({
  palette: {
    primary: {
      main: "#333996",
      light: "#3c44b126",
    },
    secondary: {
      main: "#f83245",
      light: "#f8324526",
    },
    background: {
      default: "#f4f5fd",
    },
  },
  overrides: {
    MuiCard: {
      root: {
        width: 300,
      },
    },
    MuiLink: {
      root: {
        "&:hover": {
          textDecoration: "none",
        },
      },
    },
  },
});

// Creating some custom global classnames
const useCSS = makeStyles((theme) =>
  createStyles({
    "@global": {
      ".MuiPaper-elevation1": {
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      },
      ".primary-gradient": {
        backgroundColor: "#4154B3;",
        // backgroundImage: "linear-gradient(#3c44b126, #333996)",
      },
      a: {
        textDecoration: "none",
      },
      "box-shadow-01": {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
      },
      "box-shadow-02": {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
      },
      ".flex-col": {
        flexDirection: "column",
      },
      ".flex-center": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      ".flex-start": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      ".flex-between": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      ".flex-around": {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      },
      ".white": {
        color: theme.palette.common.white,
      },
      ".teal": {
        color: colors.teal,
      },
      ".pointer": {
        cursor: "pointer",
      },
    },
  })
);

export default Theme;
