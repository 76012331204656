import React from "react";
import TemplateForm from "./TemplateForm";
import Action from "../../action";
import Hoc from "../../component/Hoc";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactRTE from "react-rte";

const Template = () => {
  const Act_template = Action.template;
  const formData = Hoc.useForm.useForm(initData);
  const { vals } = formData;
  const dispatch = useDispatch();
  const H = useHistory();

  const onSubmit = async (e) => {
    const finalData = (({ editorState, ...rest }) => rest)(vals);
    const res = await dispatch(Act_template.view_add(finalData));
    if (!!res?.status) {
      H.push("/template");
      dispatch(Action.OOP("Template has been created!!."));
    }
  };

  return (
    <TemplateForm
      title="Create Email Template"
      onSubmit={onSubmit}
      formData={formData}
    />
  );
};

const initData = {
  editorState: ReactRTE.createEmptyValue(),
  name: null,
  subject: null,
  html: null,
  action: null,
};

export default Template;
